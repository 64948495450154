/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

import {PartnerActions} from './partner-actions';
import PartnerStore from './partner-store';

import {GetAttr} from '~/src/common/utils/utils';

class PartnerSelect extends React.Component {

    static getStores() {
        return [PartnerStore];
    }

    static get propTypes() {
        return {
            availableOptions: PropTypes.oneOfType([
                PropTypes.instanceOf(Immutable.Set),
                PropTypes.instanceOf(Immutable.List)
            ]),
            disabled: PropTypes.bool,
            isClearable: PropTypes.bool,
            isMulti: PropTypes.bool,
            onChange: PropTypes.func.isRequired,
            partnerType: PropTypes.oneOf(['primaryPartner', 'webPartners']),
            selected: PropTypes.oneOfType([
                PropTypes.instanceOf(Immutable.OrderedSet),
                PropTypes.instanceOf(Immutable.List)
            ]).isRequired,
            styles: PropTypes.object
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            partners: PartnerStore.getState().get('partners')
        };
    }

    static get defaultProps() {
        return {
            availableOptions: Immutable.List(),
            defaultPartner: undefined,
            disabled: true,
            isClearable: true,
            isMulti: true,
            partnerType: undefined,
            styles: {}
        };
    }

    componentDidMount() {
        PartnerActions.get(0, 99999);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.selected !== this.props.selected) {
            return true;
        }

        if (nextState.partners !== this.state.partners) {
            return true;
        }

        return false;
    }

    render() {
        let options = this.state.partners.toJS();
        let selectedOptions = this.props.selected.toJS();

        switch (this.props.partnerType) {
        case 'primaryPartner':
            /* istanbul ignore next  */
            const defaultPartnerIndex = this.state.partners.findIndex(p => p.get('id') === selectedOptions.id);
            /* istanbul ignore next  */
            if (defaultPartnerIndex !== -1) {
                selectedOptions.name = this.state.partners.getIn([defaultPartnerIndex, 'name']);
            }
            /* istanbul ignore next */
            if (this.props.availableOptions) {
                options = this.props.availableOptions.toJS().map((option) => {
                    const partnerIndex = this.state.partners.findIndex(p => p.get('id') === option.id);
                    if (partnerIndex !== -1) {
                        option.name = this.state.partners.getIn([partnerIndex, 'name']);
                    }
                    return option;
                });
            } else {
                options = this.state.partners.toJS();
            }

            break;
        case 'webPartners':
            selectedOptions = selectedOptions.map((option) => {
                /* istanbul ignore next  */
                const partnerIndex = this.state.partners.findIndex(p => p.get('id') === option.id);
                /* istanbul ignore next  */
                if (partnerIndex !== -1) {
                    option.name = this.state.partners.getIn([partnerIndex, 'name']);
                }
                return option;
            });
            break;
        }

        return (
            <Select
                getOptionLabel={GetAttr('name')}
                getOptionValue={GetAttr('id')}
                isClearable={this.props.isClearable}
                isMulti={this.props.isMulti}
                isDisabled={this.props.disabled}
                name="partners"
                onChange={this.props.onChange}
                options={options}
                placeholder={this.context.intl.messages['common.partner-select.placeholder']}
                styles={this.props.styles}
                value={selectedOptions}
            />
        );
    }
}

export {PartnerSelect as PartnerSelect_BASE};
export default Container.create(PartnerSelect);
