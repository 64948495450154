/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import FilterOptions from './filter-options';
import GroupTable from './table';
import DocumentTitle from '../../common/document-title';
import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {Debounce} from '../../common/utils/utils';
import {LayoutActions} from '../../layout/layout-actions';
import LayoutStore from '../../layout/layout-store';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import {GroupActions, GroupConstants} from '../../security/group/group-actions';
import GroupStore from '../../security/group/group-store';
import SessionStore from '../../user/session/session-store';

class Browse extends Component {

    static get propTypes() {
        return {
            route: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            location: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            groups: GroupStore.getState().get(GroupConstants.USER.name)
        };
    }

    static getStores() {
        return [LayoutStore, PreloaderStore, GroupStore];
    }

    static getPermissions() {
        return {
            canReadGroups: SessionStore.canUser(SessionStore.PERMISSIONS.GROUPS.READ)
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.getGroupsCount = this.getGroupsCount.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 500);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
    }

    componentDidMount() {
        let status;

        if (!this.getRouteState().get('show-inactive')) {
            status = true;
        }
        GroupActions.get(this.props.route.type,
            true,
            {
                filters: this.getRouteState().remove('offset').remove('size'),
                includeUserCount: true,
                offset: this.getRouteState().get('offset', 0),
                size: this.getRouteState().get('size', 20),
                sortDirection: this.getRouteState().get('sort-direction', 'asc'),
                sortFieldName: this.getRouteState().get('sort-field-name', 'name'),
                active: status
            }
        );
        return;

    }

    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {
            let status;

            if (!this.getRouteState(nextProps).get('show-inactive')) {
                status = true;
            }
            GroupActions.get(this.props.route.type,
                true,
                {
                    active: status,
                    filters: this.getRouteState(nextProps).remove('offset').remove('size'),
                    includeUserCount: true,
                    offset: this.getRouteState(nextProps).get('offset', 0),
                    size: this.getRouteState(nextProps).get('size', 20),
                    sortDirection: this.getRouteState(nextProps).get('sort-direction', 'asc'),
                    sortFieldName: this.getRouteState(nextProps).get('sort-field-name', 'name'),
                }
            );
        }
        return;
    }

    getGroupsCount() {
        this.refs.groupsCount.innerHTML = `(${this.state.groups.get('total')} ${this.context.intl.messages['groups.user.title']})`;
        return;
    }

    handleSearchTerm(term) {
        // TODO: may need to clear previous filters when implementing the search options
        // panel.
        this.setRouteState('name', term)
            .setRouteState('global', true)
            .clearRouteState('offset')
            .apply();
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setRouteState('sort-field-name', sortFieldName)
            .setRouteState('sort-direction', sortDirection)
            .apply();
        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    render() {
        let showInactive = this.getRouteState().get('show-inactive');
        return (
            <DocumentTitle
                message="document-titles.groups-title"
            >
                <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                    <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                        <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                            <section className="content-header">
                                <h1>
                                    <i className="fas fa-users"></i>&nbsp;{this.context.intl.messages['groups.user.title']}
                                    &nbsp;<small ref="groupsCount"></small>
                                </h1>
                            </section>
                            <section className="content">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-6 show-grid">
                                                    <SearchBox value={this.getRouteState().get('name')} onChange={this.handleSearchTerm} disabled={this.state.preloaderVisible}/>
                                                </div>
                                                <div className="col-md-6 show-grid">
                                                    <Button onClick={this.handleToggleFiltersPanel} bsStyle="primary" bsSize="large" className="pull-right">
                                                        <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="box">
                                                        <div className="box-body padding-x-20 padding-y-20">
                                                            <GroupTable
                                                                displayLinks={this.props.permissions.canReadGroups}
                                                                groups={this.state.groups}
                                                                groupsCount={this.getGroupsCount}
                                                                onSortChange={this.handleSortChange}
                                                                pageSize={parseInt(this.getRouteState().get('size', 20))}
                                                                showInactive={showInactive}
                                                                sortDirection={this.getRouteState().get('sort-direction') || 'asc'}
                                                                sortFieldName={this.getRouteState().get('sort-field-name') || 'name'}
                                                                type={this.props.route.type}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <FilterOptions
                                location={this.props.location}
                            />
                        </Preloader>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(Container.create(Browse));
export {
    Browse as Browse_BASE
};
