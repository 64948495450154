/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import DocumentTitle from '../../../common/document-title';
import SearchBox from '../../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../../common/store-on-route';
import {Debounce} from '../../../common/utils/utils';
import {LayoutActions} from '../../../layout/layout-actions';
import LayoutStore from '../../../layout/layout-store';
import Preloader from '../../../preloader/';
import PreloaderStore from '../../../preloader/preloader-store';
import {GroupActions, GroupConstants} from '../../../security/group/group-actions';
import GroupStore from '../../../security/group/group-store';
import SessionStore from '../../../user/session/session-store';
import FilterOptions from '../../filter-options';
import CatalogsTable from '../../table';


class Browse extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object,

        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }


    static calculateState() {
        return {
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            catalogs: GroupStore.getState().get(GroupConstants.TITLE.name)
        };
    }

    static getStores() {
        return [LayoutStore, PreloaderStore, GroupStore];
    }

    static get defaultProps() {
        return {
            route: undefined,
            permissions: {
                canReadTitleCatalogs: false
            }
        };
    }


    static getPermissions() {
        return {
            canReadTitleCatalogs: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.TITLE.READ)
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
    }

    componentDidMount() {
        let active = true;
        if (this.getRouteState().get('active') === 'false') {
            active = false;
        }

        GroupActions.get(GroupConstants.TITLE,
            true,
            {
                filters: Immutable.Map(),
                offset: 0,
                size: 9999,
                active: active
            }
        );
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.location.search !== this.props.location.search ||
            nextState.showFiltersPanel !== this.state.showFiltersPanel ||
            nextState.preloaderVisible !== this.state.preloaderVisible) {
            return true;
        }

        return false;
    }


    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {
            let status = this.getRouteState(nextProps).get('active');
            GroupActions.get(GroupConstants.TITLE,
                true,
                {
                    filters: Immutable.Map(),
                    offset: 0,
                    size: 9999,
                    active: status
                }
            );
        }
        return;
    }

    handleSearchTerm(term) {
        this.setRouteState('term', term)
            .apply();
        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    render() {
        return (
            <DocumentTitle
                message="document-titles.catalogs-title"
            >
                <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                    <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                        <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                            <section className="content-header">
                                <h1>
                                    <i className="fas fa-book"></i>&nbsp;{this.context.intl.messages['catalogs.title.browse']}
                                </h1>
                            </section>
                            <section className="content">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-6 show-grid">
                                                    <SearchBox value={this.getRouteState().get('term')} onChange={this.handleSearchTerm}/>
                                                </div>
                                                <div className="col-md-6 show-grid">
                                                    <Button onClick={this.handleToggleFiltersPanel} bsStyle="primary" bsSize="large" className="pull-right">
                                                        <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="box">
                                                        <div className="box-body padding-x-20 padding-y-20">
                                                            <CatalogsTable
                                                                catalogs={this.state.catalogs}
                                                                displayLinks={this.props.permissions.canReadTitleCatalogs}
                                                                filters={this.getRouteState().get('term', '')}
                                                                location={this.props.location}
                                                                type={GroupConstants.TITLE.name}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <FilterOptions
                                location={this.props.location}
                                type={GroupConstants.TITLE}
                            />
                        </Preloader>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(Container.create(Browse));
export {
    Browse as Browse_BASE
};
