/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {NotificationActions} from '../../common/notification/notification-actions';
import {TimecodeToFrames} from '../../common/utils/timecode';
import {ArrayToAudioProfile, AudioProfileToArray} from '../../common/utils/utils';
import Dispatcher from '../../dispatcher/dispatcher';
import VideoPlayerThumbnails from '../../player/video-player-thumbnails';
import Request from '../../request';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import {ProcessesConstants} from '../processes/processes-actions';

const CONSTANTS = {
    CLEAR: 'hardac.timeline.clear',
    HOTKEYS_MODAL: {
        TOGGLE: 'hardac.timeline.hotkeys-modal.toggle'
    },
    TIMELINE: {
        AVID: {
            EXPORT: {
                ERROR: 'hardac.timeline.export.avid.error',
                START: 'hardac.timeline.export.avid.start',
                SUCCESS: 'hardac.timeline.export.avid.success'
            },
        },
        EXPORT: {
            ERROR: 'hardac.timeline.export.error',
            START: 'hardac.timeline.export.start',
            SUCCESS: 'hardac.timeline.export.success'
        },
        FIND_BY_ID: {
            ERROR: 'hardac.timeline.find-by-id.error',
            START: 'hardac.timeline.find-by-id.start',
            SUCCESS: 'hardac.timeline.find-by-id.success'
        },
        CLIPS: {
            GET: {
                ERROR: 'hardac.timeline.clips.get.error',
                START: 'hardac.timeline.clips.get.start',
                SUCCESS: 'hardac.timeline.clips.get.success',
            },
            PUBLISH: {
                ERROR: 'hardac.timeline.clips.publish.error',
                START: 'hardac.timeline.clips.publish.start',
                SUCCESS: 'hardac.timeline.clips.publish.success',
            },
            UPDATE: 'hardac.timeline.clips.add-update',
            UPDATE_ATTR: 'hardac.timeline.clips.update-attr-at-index',
        },
        CLIP: {
            CLEAR: 'hardac.timeline.clip.clear',
            DELETE: 'hardac.timeline.clip.delete',
            PUBLISH: {
                ERROR: 'hardac.timeline.clip.publish.error',
                SUCCESS: 'hardac.timeline.clip.publish.success',
            },
            PUBLISH_TYPE: {
                COMPLETE: {id: 'COMPLETE'},
                FAILED: {id: 'FAILED'},
                PROCESSING: {id: 'PROCESSING'},
                UNPUBLISHED: {ID: 'UNPUBLISHED'},
            },
            SELECT: 'hardac.timeline.clip.select',
            UPDATE_VALUE: 'hardac.timeline.clip.update'
        },
        PROCESSES: {
            GET: {
                ERROR: 'hardac.timeline.processes.get.error',
                START: 'hardac.timeline.processes.get.start',
                SUCCESS: 'hardac.timeline.processes.get.success'
            },
            REVIEW_PROGRESS: {
                START: 'hardac.timeline.processes.review_progress.start',
                SET: 'hardac.timeline.processes.review_progress.set',
            }
        },
        PROXY: {
            SRC: {
                GET: {
                    SUCCESS: 'hardac.timeline.proxy.src.get.success'
                }
            }
        },
        PUBLISHING_INFO: {
            GET: {
                SUCCESS: 'hardac.timeline.publishing_info.get.success',
            }
        },
        SAVE: {
            ERROR: 'hardac.timeline.save.error',
            START: 'hardac.timeline.save.start',
            SUCCESS: 'hardac.timeline.save.success',
        },
        SEND_TO_OAP: {
            ERROR: 'hardac.timeline.send_to_oap.error',
            START: 'hardac.timeline.send_to_oap.start',
            SUCCESS: 'hardac.timeline.send_to_oap.success',
        },
        STORAGE_STATUS_TYPE: {
            AVAILABLE: 'AVAILABLE',
            CREATING: 'CREATING',
            RESTORE: 'RESTORE'
        },
        THUMBNAILS: {
            GET: {
                SUCCESS: 'hardac.timeline.thumbnails.get.success',
            }
        },
        UPDATE: 'hardac.timeline.update',
    },
    TIMELINES: {
        AVID: {
            EXPORT: {
                ERROR: 'hardac.timelines.export.avid.error',
                START: 'hardac.timelines.export.avid.start',
                SUCCESS: 'hardac.timelines.export.avid.success'
            },
        },
        EXPORT: {
            ERROR: 'hardac.timelines.export.error',
            START: 'hardac.timelines.export.start',
            SUCCESS: 'hardac.timelines.export.success'
        },
    },
    UPDATE: 'hardac.update',
    toArray: /* istanbul ignore next */function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k])
            .sort((a, b) => a.name.localeCompare(b.name));
    }
};

class TimelineActions {
    /**
     * Add or update clip in clips list on store
     * @param {Immutable.Map} clip
     */
    addUpdateClipToList(clip, status) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.CLIPS.UPDATE,
            clip,
            status
        });

        return;
    }

    clearSelectedClip() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.CLIP.CLEAR
        });
    }

    bulkExportAvid(ids) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINES.AVID.EXPORT.START,
        });

        Promise.all(
            ids.map(
                id => Request.post(`asset/video-timeline/${id}/send-onprem-avid`).exec().catch(err => {
                    NotificationActions.showAlertDanger('hardac.timeline.export-bulk.error', id);
                    throw err;
                })
            )
        ).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINES.AVID.EXPORT.SUCCESS,
            });
            NotificationActions.showAlertSuccess('hardac.timeline.export-bulk.success');

            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINES.AVID.EXPORT.ERROR,
            });
            throw err;
        });
    }

    bulkExportTimelines(ids) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINES.EXPORT.START,
        });

        Promise.all(
            ids.map(
                id => Request.post(`asset/video-timeline/${id}/send-onprem`).exec().catch(err => {
                    NotificationActions.showAlertDanger('hardac.timeline.export-bulk.error', id);
                    throw err;
                })
            )
        ).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINES.EXPORT.SUCCESS,
            });
            NotificationActions.showAlertSuccess('hardac.timeline.export-bulk.success');

            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINES.EXPORT.ERROR,
            });
            throw err;
        });
    }

    buildThumbnails(vttFileURL, spriteFileURL, asset) {
        return VideoPlayerThumbnails.GetVTTFile(vttFileURL).then(vttData => {
            return VideoPlayerThumbnails.ParseThumbnailTrack(vttData, asset);
        }).then(cues => {
            const thumbnails = cues.map(cue => {
                let xywh = cue.text.split('=')[1].split(',');
                return {
                    src: spriteFileURL,
                    srcDrawRect: {
                        x: xywh[0],
                        y: xywh[1],
                        height: xywh[3],
                        width: xywh[2],
                    }
                };
            });
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.THUMBNAILS.GET.SUCCESS,
                thumbnails: Immutable.fromJS(thumbnails),
            });
            return;
        }).catch(e => {
            throw e;
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });

        return;
    }

    /**
     * Deletes clip in store at index displayOrder
     */
    deleteClip(displayOrder, status) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.CLIP.DELETE,
            displayOrder,
            status
        });
    }

    exportAvid(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.AVID.EXPORT.START,
            id
        });

        return Request.post(`asset/video-timeline/${id}/send-onprem-avid`).exec().then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.AVID.EXPORT.SUCCESS,
            });
            NotificationActions.showAlertSuccess('hardac.timeline.export.success');
            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.AVID.EXPORT.ERROR,
            });

            NotificationActions.showAlertDanger('hardac.timeline.export.error');
            throw err;
        });
    }

    exportTimeline(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.EXPORT.START,
            id
        });

        return Request.post(`asset/video-timeline/${id}/send-onprem`).exec().then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.EXPORT.SUCCESS,
            });
            NotificationActions.showAlertSuccess('hardac.timeline.export.success');
            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.EXPORT.ERROR,
            });

            NotificationActions.showAlertDanger('hardac.timeline.export.error');
            throw err;
        });
    }

    findById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.FIND_BY_ID.START,
            id
        });

        const timelinePromise = Request.get(`asset/video-timeline/${id}`).exec().then(r => r).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.FIND_BY_ID.ERROR,
            });
            NotificationActions.showAlertDanger('hardac.timeline.find-by-id.error');
            throw err;
        });

        const clipPromise = Request.get(`asset/video-timeline/${id}/clip`).exec().then(r => r).catch(err => {
            NotificationActions.showAlertDanger('hardac.timeline.find-by-id.clips.error');
            throw err;
        });

        const audioProfilePromise = Request.get(`asset/video-timeline/${id}/audio`).exec().then(r => r).catch(err => {
            NotificationActions.showAlertDanger('hardac.timeline.find-by-id.audio-profile.error');
            throw err;
        });

        const actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.VIDEOTIMELINE,
            'object-id': id,
            offset: 0,
            size: 4
        };
        const historyPromise = Request.get('system/action-history').query(actionHistoryQuery).exec().then(r => r).catch(err => {
            NotificationActions.showAlertDanger('hardac.timeline.find-by-id.history.error');
            throw err;
        });

        Promise.all([
            timelinePromise,
            clipPromise,
            audioProfilePromise,
            historyPromise
        ]).spread((timelineRes, clipRes, audioProfileRes, historyRes) => {
            let history = historyRes.body.results;
            history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));

            let audioProfile = AudioProfileToArray(audioProfileRes.body).map((c, i) => {
                c.isMuted = i > 0;

                return c;
            });

            const timeline = timelineRes.body;
            timeline.spriteThumbVTTUrl = timeline.spriteFile?.fileUri;
            timeline.spriteThumbJPEGUrl = timeline.spriteJpegFile?.fileUri;

            const frameRate = timeline.frameRate;
            const clips = this.getClipsOrdered(Immutable.fromJS(clipRes.body), frameRate);
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.FIND_BY_ID.SUCCESS,
                audioProfile: Immutable.fromJS(audioProfile),
                clips,
                history: Immutable.fromJS(history),
                timeline: Immutable.fromJS(timeline)
            });

            let proxySrcRequest;
            if (
                timeline.proxyFile &&
                timeline.proxyFile.storageStatusType === CONSTANTS.TIMELINE.STORAGE_STATUS_TYPE.AVAILABLE
            ) {
                const proxyFileId = timeline.proxyFile.id;
                proxySrcRequest = Request.get(`asset/video-timeline/${id}/asset-file/${proxyFileId}/streamURL`).then(r => r).catch(err => {
                    NotificationActions.showAlertDanger('hardac.timeline.proxy.src.get.error');
                    throw err;
                });
            }

            if (timeline.spriteThumbVTTUrl && timeline.spriteThumbJPEGUrl) {
                this.buildThumbnails(timeline.spriteThumbVTTUrl, timeline.spriteThumbJPEGUrl, timeline);
            }

            return proxySrcRequest;
        }).then(proxySrcResponse => {
            if (!proxySrcResponse) {return;}

            const src = Immutable.fromJS({
                dash: proxySrcResponse.body.dashLocatorUri,
                hls: proxySrcResponse.body.hlsLocatorUri,
                drmTokens: proxySrcResponse.body.videoDrmTokens
            });

            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.PROXY.SRC.GET.SUCCESS,
                src,
                timelineId: id
            });

            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.FIND_BY_ID.ERROR
            });

            throw err;
        });
        return;
    }

    /**
     * Get list of clips for a given timelineId
     * @param {string} timelineId
     */
    getClips(timelineId, frameRate) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.CLIPS.GET.START
        });
        return Request.get(`asset/video-timeline/${timelineId}/clip`).exec().then(response => {
            const clips = this.getClipsOrdered(Immutable.fromJS(response.body), frameRate);
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.CLIPS.GET.SUCCESS,
                clips
            });
            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.CLIPS.GET.ERROR,
            });

            NotificationActions.showAlertDanger('hardac.timeline.clips.get.error');
            throw err;
        });
    }

    getClipsOrdered(clips, frameRate) {
        const {completed, unpublished, failed, processing} = this.splitClips(clips);

        const sortClips = (a, b) => TimecodeToFrames(a.get('clipIn'), frameRate) - TimecodeToFrames(b.get('clipIn'), frameRate);
        const setDisplayOrder = (clip, index) => clip.set('displayOrder', index);
        const completedOrdered = completed.sort(sortClips).map(setDisplayOrder);
        const failedOrdered = failed.sort(sortClips).map(setDisplayOrder);
        const processingOrdered = processing.sort(sortClips).map(setDisplayOrder);
        const unpublishedOrdered = unpublished.sort(sortClips).map(setDisplayOrder);

        return Immutable.fromJS({
            completed: completedOrdered,
            failed: failedOrdered,
            processing: processingOrdered,
            unpublished: unpublishedOrdered
        });
    }

    getPublishingInfo(id) {
        const catalogsPromise = Request.get(`asset/${id}/catalog`).exec().then(r => r).catch(err => {
            throw err;
        });
        const talentPromise = Request.get(`asset/${id}/talent`).exec().then(r => r).catch(err => {
            throw err;
        });
        const titlesPromise = Request.get(`asset/${id}/title`).exec().then(r => r).catch(err => {
            throw err;
        });
        const usersPromise = Request.get(`asset/${id}/user`).exec().then(r => r).catch(err => {
            throw err;
        });

        Promise.all([
            catalogsPromise,
            talentPromise,
            titlesPromise,
            usersPromise
        ]).spread((catalogsRes, talentRes, titlesRes, usersRes) => {
            //Get only first 3 items from catalogs/talent/titles for displaying in tooltips
            let talentRequests = talentRes.body.slice(0, 3).map(t => Request.get(`talent/${t.talentId}`).exec());
            let titlesRequests = titlesRes.body.slice(0, 3).map(t => Request.get(`title/${t.titleId}`).exec());
            return [
                catalogsRes.body.slice(0, 3),
                Promise.all(talentRequests).then(responses => {
                    let results = responses.map(response => response.body);
                    return results.map(result => result);
                }).catch(err => {
                    throw err;
                }),
                Promise.all(titlesRequests).then(responses => {
                    let results = responses.map(response => response.body);
                    return results.map(result => result);
                }).catch(err => {
                    throw err;
                }),
                catalogsRes.body.length,
                talentRes.body.length,
                titlesRes.body.length,
                usersRes.body.totalCount
            ];
        }).spread((catalogs, talent, titles, totalCatalogs, totalTalent, totalTitles, totalUsers) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.PUBLISHING_INFO.GET.SUCCESS,
                publishingInfo: Immutable.fromJS({
                    catalogs: catalogs,
                    talent: talent,
                    titles: titles,
                    totalCatalogs: totalCatalogs,
                    totalTalent: totalTalent,
                    totalTitles: totalTitles,
                    totalUsers: totalUsers
                })
            });
            return;
        }).catch(e => {
            throw e;
        });
        return;
    }

    /**
     * Get list of processes for a given timelineId
     * @param {string} timelineId
     */
    getProcesses(timelineId, filterProcessTypes = Immutable.List(), dispatchNext = false) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.PROCESSES.GET.START
        });
        Request.get(`asset/video-timeline/${timelineId}/process`)
            .exec()
            .then(response => {
                const processes = Immutable.fromJS(response.body);

                let processesFiltered = processes;
                if (filterProcessTypes.size) {
                    processesFiltered = processes.filter(p => filterProcessTypes.contains(p.get('processType')));
                }
                Dispatcher.dispatch({
                    actionType: CONSTANTS.TIMELINE.PROCESSES.GET.SUCCESS,
                    processes: processesFiltered
                });

                if (dispatchNext) {
                    this.reviewProcessesProgress(processesFiltered);
                }
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.TIMELINE.PROCESSES.GET.ERROR
                });
                switch (err.status) {
                case 404:
                    console.error(err);
                    break;
                default:
                    NotificationActions.showAlertDanger('hardac.timeline.processes.get.error');
                    throw err;
                }
            });
    }

    reviewProcessesProgress(processes = Immutable.List()) {
        let status = {};
        let statusObj = {scheduled: false, started: false, percentComplete: 0, completed: false, failed: false, sentDate: null, completeDate: null, failureDate: null, messageId: 0};
        status[ProcessesConstants.PROCESS_TYPES.BLOB_COPY_LTS.id] = Object.assign({}, statusObj);
        status[ProcessesConstants.PROCESS_TYPES.BLOB_COPY_TIMELINE_STEREO.id] = Object.assign({}, statusObj);
        status[ProcessesConstants.PROCESS_TYPES.ENCODE_FOR_TIMELINE_STEREO.id] = Object.assign({}, statusObj);

        const messagesRequests = processes.map(process => {
            return Request.get(`integration/hardac/event/${process.get('id')}/message`)
                .exec()
                .then(res => {
                    let messages = Immutable.fromJS(res.body.results).reverse();

                    const processType = process.get('processType');

                    status[processType].failed = process.get('failureDate') !== null;
                    status[processType].sentDate = process.get('sentDate');
                    status[processType].completeDate = process.get('processCompleteDate');
                    status[processType].failureDate = process.get('failureDate');

                    messages.forEach(m => {
                        const eventMessages = Immutable.fromJS(JSON.parse(m.get('eventMessage')));
                        const emFirst = eventMessages.first();
                        const emEventType = emFirst.get('eventType');

                        switch (processType) {
                        case ProcessesConstants.PROCESS_TYPES.BLOB_COPY_LTS.id:
                            switch (emEventType) {
                            case ProcessesConstants.PROCESS_TYPES.BLOB_COPY_LTS.EVENT_TYPES.BLOB_COPY_SCHEDULED.id:
                                status[processType].scheduled = true;
                                break;
                            case ProcessesConstants.PROCESS_TYPES.BLOB_COPY_LTS.EVENT_TYPES.BLOB_CREATED_SUCCESS.id:
                                status[processType].started = true;
                                status[processType].completed = true;
                                status[processType].percentComplete = 100;
                                status[processType].messageId = m.get('id');
                                break;
                            }
                            break;
                        case ProcessesConstants.PROCESS_TYPES.ENCODE_FOR_TIMELINE_STEREO.id:
                            switch (emEventType) {
                            case ProcessesConstants.PROCESS_TYPES.ENCODE_FOR_TIMELINE_STEREO.EVENT_TYPES.ENCODE_CLOUDPORT_SCHEDULED.id:
                                status[processType].scheduled = true;
                                break;
                            case ProcessesConstants.PROCESS_TYPES.ENCODE_FOR_TIMELINE_STEREO.EVENT_TYPES.ENCODE_CLOUDPORT_DISPATCHED.id:
                                status[processType].started = true;
                                break;
                            case ProcessesConstants.PROCESS_TYPES.ENCODE_FOR_TIMELINE_STEREO.EVENT_TYPES.ENCODE_CLOUDPORT_PROCESSING.id:
                                const percentComplete = emFirst.get('data').get('percentComplete');
                                status[processType].percentComplete = percentComplete;
                                break;
                            case ProcessesConstants.PROCESS_TYPES.ENCODE_FOR_TIMELINE_STEREO.EVENT_TYPES.ENCODE_CLOUDPORT_SUCCESS.id:
                                status[processType].completed = true;
                                status[processType].percentComplete = 100;
                                status[processType].messageId = m.get('id');
                                break;
                            }
                            break;
                        case ProcessesConstants.PROCESS_TYPES.BLOB_COPY_TIMELINE_STEREO.id:
                            switch (emEventType) {
                            case ProcessesConstants.PROCESS_TYPES.BLOB_COPY_TIMELINE_STEREO.EVENT_TYPES.BLOB_COPY_SCHEDULED.id:
                                status[processType].scheduled = true;
                                break;
                            case ProcessesConstants.PROCESS_TYPES.BLOB_COPY_TIMELINE_STEREO.EVENT_TYPES.BLOB_CREATED_SUCCESS.id:
                                status[processType].started = true;
                                status[processType].completed = true;
                                status[processType].percentComplete = 100;
                                status[processType].messageId = m.get('id');
                                break;
                            }
                            break;
                        }
                    });

                    return process;
                })
                .catch(err => {
                    NotificationActions.showAlertDanger('hardac.processes.messages.error.does-not-load', process.get('id'));
                    throw err;
                });
        });

        return Promise.all(messagesRequests).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.PROCESSES.REVIEW_PROGRESS.SET,
                reviewProcessesProgress: Immutable.fromJS(status)
            });

            return status;
        }).catch(err => {
            throw err;
        });
    }

    publishAll(clips, timeline) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.CLIPS.PUBLISH.START,
        });

        const publishRequests = clips.map(clip => {
            return Request.post(`asset/video-timeline/${clip.get('videoTimelineId')}/clip/${clip.get('id')}/publish`)
                .exec().catch(err => {
                    NotificationActions.showAlertDanger('hardac.timeline.clip.publish.error', clip.get('name'));
                    throw err;
                });
        });

        Promise.all(publishRequests).then(() => {
            this.getClips(timeline.get('id'), timeline.get('frameRate'));
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.CLIPS.PUBLISH.SUCCESS,
            });
            NotificationActions.showAlertSuccess('hardac.timeline.clips.publish-all.success');
            return;
        }).catch(err => {
            this.getClips(timeline.get('id'), timeline.get('frameRate'));
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.CLIPS.PUBLISH.ERROR,
            });
            NotificationActions.showAlertDanger('hardac.timeline.clips.publish-all.error');
            throw err;
        });
    }

    publishClip(clip, timeline) {
        Request.post(`asset/video-timeline/${clip.get('videoTimelineId')}/clip/${clip.get('id')}/publish`)
            .exec()
            .then(() => {
                this.getClips(clip.get('videoTimelineId'), timeline.get('frameRate'));
                Dispatcher.dispatch({
                    actionType: CONSTANTS.TIMELINE.CLIP.PUBLISH.SUCCESS,
                });

                //close edit panel if it is open
                this.clearSelectedClip();
                this.update('showEditClipPanel', false);

                NotificationActions.showAlertSuccess('hardac.timeline.clip.publish.success', clip.get('name'));
                return;
            }).catch(err => {
                this.getClips(clip.get('videoTimelineId'), timeline.get('frameRate'));
                Dispatcher.dispatch({
                    actionType: CONSTANTS.TIMELINE.CLIP.PUBLISH.ERROR,
                });
                NotificationActions.showAlertDanger('hardac.timeline.clip.publish.error', clip.get('name'));
                throw err;
            });
        return;
    }

    /**
     * Save timeline data, clips, assignedCatalogs and talent
     *
     * @param {string} timelineId
     * @param {Immutable.List} audioProfile
     * @param {Immutable.List} clips
     * @param {Immutable.List} originalClips
     * @param {Immutable.List} assignedCatalogs
     * @param {Immutable.List} originalAssignedCatalogs
     * @param {Immutable.List} talent
     * @param {Immutable.List} originalTalent
     */
    save(id, timeline, audioProfile, clips, originalClips) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.SAVE.START,
        });

        // FIXME: temp fix, remove mediaInfo object so that Cloudflare
        // doesn't block our requests.
        const t = ['mediaInfo'].reduce((r, p) => {
            return r.delete(p);
        }, timeline).toJS();
        // Change boolean to number
        let activeValue = 0;
        if (t.active) {
            activeValue = 1;
        }
        t.active = activeValue;
        const saveTimelineRequest = Request.put(`asset/video-timeline/${id}`).send(t).exec().then(r => r).catch(err => {
            NotificationActions.showAlertDanger('hardac.timeline.save.summary.error');
            throw err;
        });

        clips = clips.toJS();
        const clipsToSend = [...clips.completed, ...clips.unpublished, ...clips.failed, ...clips.processing].map(clip => {
            // TODO remove displayOrder too
            ['clipInTimecode', 'clipOutTimecode'].forEach(p => delete clip[p]);
            return clip;
        });

        let saveClipsRequest;
        if (clips !== originalClips) {
            saveClipsRequest = Request.put(`asset/video-timeline/${id}/clip`).send(clipsToSend).exec().then(r => r).catch(err => {
                NotificationActions.showAlertDanger('hardac.timeline.save.clips.error');
                throw err;
            });
        }

        const ap = ArrayToAudioProfile(id, audioProfile);

        const saveAudioProfile = Request.put(`asset/video-timeline/${id}/audio`).send(ap).exec().then(r => r).catch(err => {
            NotificationActions.showAlertDanger('hardac.timeline.save.audio-profile.error');
            throw err;
        });

        Promise.all([
            saveTimelineRequest,
            saveClipsRequest,
            saveAudioProfile
        ]).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.SAVE.SUCCESS,
            });
            NotificationActions.showAlertSuccess('hardac.timeline.save.success');
            return this.getClips(id, timeline.get('frameRate'));
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TIMELINE.SAVE.ERROR
            });

            NotificationActions.showAlertDanger('hardac.timeline.save.error');
            throw err;
        });
        return;
    }

    sendToOAP(timelineId, showCode) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.SEND_TO_OAP.START
        });

        const requestBody = {
            showCode: showCode
        };

        Request.put(`asset/video-timeline/${timelineId}/send-to-oap`)
            .send(requestBody)
            .exec()
            .then((res) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.TIMELINE.SEND_TO_OAP.SUCCESS,
                    barcode: res.body.Barcode_Serial_No_t
                });
                NotificationActions.showAlertSuccess('hardac.timeline.send-to-oap.success');
                return;
            }).catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.TIMELINE.SEND_TO_OAP.ERROR
                });
                NotificationActions.showAlertDanger('hardac.timeline.send-to-oap.error');
                throw err;
            });
        return;
    }

    /**
     * Update selectedClip Immutable.Map with a new updatedClip Immutable.Map
     * @param {Immutable.Map} clip
     */
    setSelectedClip(clip) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.CLIP.SELECT,
            clip
        });

        return;
    }

    /**
     * Slipt clips in four groups: completed, failed, processing and unpublished
     * @param {Array} clips
     */
    splitClips(clips) {
        let completed = [], unpublished = [], failed = [], processing = [];
        clips.forEach(clip => {
            switch (clip.get('publishStatusType')) {
            case CONSTANTS.TIMELINE.CLIP.PUBLISH_TYPE.COMPLETE.id:
                completed.push(clip);
                break;
            case CONSTANTS.TIMELINE.CLIP.PUBLISH_TYPE.FAILED.id:
                failed.push(clip);
                break;
            case CONSTANTS.TIMELINE.CLIP.PUBLISH_TYPE.PROCESSING.id:
                processing.push(clip);
                break;
            default:
                unpublished.push(clip);
                break;
            }
        });
        return {
            completed,
            failed,
            processing,
            unpublished
        };
    }

    /**
     * Toggle visibility of hotkeys modal
     */
    toggleHotkeysModal() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.HOTKEYS_MODAL.TOGGLE
        });
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.UPDATE,
            attr,
            value
        });

        return;
    }

    updateClip(attr, value) {
        Dispatcher.dispatch({
            actionType:  CONSTANTS.TIMELINE.CLIPS.UPDATE_ATTR,
            attr,
            value
        });
    }

    /**
     * Update selected clip attribute with given value
     * @param {string} attr
     * @param {any} value
     */
    updateSelectedClipAttr(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.CLIP.UPDATE_VALUE,
            attr: attr,
            value: value
        });

        return;
    }

    updateTimeline(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.UPDATE,
            attr,
            value
        });

        return;
    }

    updateThumbnailsStartTime(duration, thumbanils) {
        const step = duration/thumbanils.length;
        let i = 0;
        const thumbanilsUpdated = thumbanils.map(thumbnail => {
            thumbnail.start = i;
            i += step;
            return thumbnail;
        });
        Dispatcher.dispatch({
            actionType: CONSTANTS.TIMELINE.THUMBNAILS.GET.SUCCESS,
            thumbnails: Immutable.fromJS(thumbanilsUpdated)
        });
    }

}

const actions = new TimelineActions();

export {
    actions as TimelineActions,
    CONSTANTS as TimelineConstants
};
