/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';

import {AssetTabActions} from './asset-tab-actions';
import AssetTabStore from './asset-tab-store';
import {AssetActions, AssetConstants} from '../assets/asset-actions';
import AssetStore from '../assets/asset-store';
import FilterOptions from '../assets/filter-options/filter-options';
import SearchBox from '../common/search-box/search-box';
import Pagination from '../common/table/pagination';
import {Debounce, GetThumbnail} from '../common/utils/utils';
import {LayoutActions} from '../layout/layout-actions';
import LayoutStore from '../layout/layout-store';
import Preloader from '../preloader';
import PreloaderStore from '../preloader/preloader-store';

import {SourceContentTypeById, AssetTypesById} from '~/src/assets/browse/list-assets';
import {ImageConstants} from '~/src/assets/image/image-actions';
import {VideoConstants} from '~/src/assets/video/video-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../styles/data-tables-brainiac.css');
// Load jQuery and register the datatables plugin.
require('datatables.net-responsive-bs');

const COLUMNS = {
    actions: {
        get: (asset, props, context, state) => {
            let addStyle = 'bg-wb-blue';
            let removeStyle = 'bg-red';
            if (props.isOutlineStyle) {
                addStyle = 'btn-primary-outline';
                removeStyle = 'btn-danger-outline';
            }

            const assetId = asset.get('id');

            // Prevent the user from selecting the same asset for both the old and new versions.
            let disabled;
            if (assetId === state.copyToAsset.get('assetId')) {
                disabled = 'disabled';
                addStyle = 'bg-gray';
            }

            let toggleAssetButton = `<button ${disabled} class="btn btn-small ${addStyle} toggle-asset-button" asset-id="${assetId}"><i class="fas fa-plus toggle-asset-button" asset-id="${assetId}"></i>&nbsp;Select</button>`;

            if (state.copyFromAsset.get('id') === asset.get('id')) {
                toggleAssetButton = `<button class="btn btn-small ${removeStyle} toggle-asset-button" asset-id="${assetId}"><i class="fas fa-trash-alt toggle-asset-button" asset-id="${assetId}"></i>&nbsp;New Version</button>`;
            }

            return toggleAssetButton;
        },
        header: () => <th>Select Asset</th>
    },
    active: {
        get: (asset, props, context) => {
            const active = asset.get('active');
            let icon = 'fas fa-check';
            let text = context.intl.messages['common.active'];
            if (!active) {
                icon = 'fas fa-times';
                text = context.intl.messages['common.inactive'];
            }
            return `<i class="${icon}"></i>&nbsp;<span class="hidden-xs hidden-sm">${text}`;
        },
        header: function() {
            return <th key="active">Status</th>;
        }
    },
    assetId: {
        get: (asset) => asset.get('id'),
        header: () => <th className="">Asset ID</th>,
    },
    assetThumbnail: {
        get: (asset) => {
            const assetName = asset.get('assetName');
            const sourceContentType = asset.get('sourceContentType') || '';
            let assetType =
                SourceContentTypeById[sourceContentType.toString()] ||
                AssetTypesById[asset.get('assetType').toString()] || {icon: 'fas fa-file-alt'};
            let icon = `<i class="${ClassNames(assetType.icon)}"></i>`;

            if (asset.get('thumbnails')) {
                let thumbnailUrl = asset.getIn(['thumbnails', 0, 'thumbnailUrl']);
                let thumbnail = GetThumbnail(asset.get('thumbnails'), 100);

                if (thumbnail) {
                    thumbnailUrl = thumbnail.get('thumbnailUrl');
                }

                if (thumbnailUrl) {
                    icon = `<img class="table-thumbnail" src="${thumbnailUrl}" alt="${assetName}" />`;
                }
            }

            return `<a href="#" class="edit-asset">${icon}</a>`;
        },
        header: () => <th><i className="far fa-image"/></th>
    },
    assetName: {
        get: (asset, props) => {
            let assetName;
            const assetId = asset.get('id');
            if (!props.displayLinks) {
                assetName = asset.get('assetName');
            } else {
                assetName = `<a href="#" class="show-asset-detail" asset-id="${assetId}">${asset.get('assetName')}</a>`;
            }
            return assetName;
        },
        header: () => <th className="sorting_desc">Name</th>
    },
    assetType: {
        get: (asset) => {
            // check sourceContentType first
            const sourceContentType = asset.get('sourceContentType');
            if (sourceContentType && sourceContentType === AssetConstants.SOURCE_CONTENT_TYPE.PROXY.id) {
                return AssetConstants.SOURCE_CONTENT_TYPE.PROXY.name;
            }
            return asset.get('displayAssetTypeName');
        },
        header: () => <th className="sorting_desc">Asset Type</th>
    },
    contentType: {
        get: (asset, props, context) => {
            const assetType = asset.get('assetType');
            let contentType = context.intl.messages['common.na'];
            switch (assetType) {
            case AssetConstants.ASSET_TYPES.IMAGE.id:
                if (ImageConstants.CONTENT_TYPE_MAP[asset.get('contentType')]) {
                    contentType = ImageConstants.CONTENT_TYPE_MAP[asset.get('contentType')].name;
                }
                break;
            case AssetConstants.ASSET_TYPES.VIDEO.id:
                if (VideoConstants.CONTENT_TYPE_BY_ID[asset.get('contentType')]) {
                    contentType = VideoConstants.CONTENT_TYPE_BY_ID[asset.get('contentType')].name;
                }
                break;
            }
            return contentType;
        },
        header: () => <th className="sorting_desc">Content Type</th>
    },
    dateEntered: {
        get: (asset, nextProps, context) => {
            let createdDate = Moment(asset.get('createdDate'));
            let createdDateFormtted = '-';

            if (createdDate.isValid()) {
                createdDateFormtted = createdDate.format(context.intl.messages['date-format']);
            }

            return createdDateFormtted;
        },
        header: () => <th>Date Entered</th>
    },
    mfa: {
        get: (asset) => {
            let icon = 'fas fa-times';
            if (asset.get('mfaRequired')) {
                icon = 'fas fa-check';
            }
            return `<i class="${ClassNames(icon)}" />`;
        },
        header: () => <th className="sorting_desc">MFA</th>
    },
};

class ListAssets extends React.Component {
    static get propTypes() {
        return {
            assetType: PropTypes.string.isRequired,
            columns: PropTypes.array,
            columnDefs: PropTypes.array,
            displayLinks: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
            onPageChange: PropTypes.func.isRequired,
            isOutlineStyle: PropTypes.bool // eslint-disable-line react/no-unused-prop-types
        };
    }

    static get defaultProps() {
        return {
            displayLinks: true,
            columns: ['assetThumbnail', 'assetId', 'assetName', 'dateEntered', 'actions'],
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                targets: 'no-sort',
                orderable: false
            }],
            isOutlineStyle: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        const assetStoreState = AssetStore.getState();
        return {
            activePage: Math.ceil(assetStoreState.get('offset')/assetStoreState.get('size')) || 0,
            assets: assetStoreState.get('assets'),
            copyFromAsset: AssetTabStore.getState().get('copyFromAsset'),
            copyToAsset: AssetTabStore.getState().get('copyToAsset'),
            totalPages: Math.ceil(assetStoreState.get('total')/assetStoreState.get('size')) || 0
        };
    }

    static getStores() {
        return [AssetStore, AssetTabStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.getAsset = this.getAsset.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);

        return;
    }

    componentDidMount() {
        this.$table = jQuery('#add-existing-list-assets-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: this.props.columnDefs,
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        // Register global listeners.
        window.addEventListener('resize', this.handleResize);
        // And trigger the resize handler once so that the datatable
        // knows its initial dimensions.
        this.handleResize();
        return;
    }

    componentDidUpdate() {
        this.$tableAPI.clear();

        // Add data to the jQuery datatable.
        this.state.assets.forEach(asset => {
            let row = this.props.columns.map(col => {
                let column = COLUMNS[col];
                if (col.name) {
                    column = COLUMNS[col.name];
                }
                if (col.get) {
                    column.get = col.get;
                }
                return column.get(asset, this.props, this.context, this.state);
            });

            // Add a last empty column for the datatable-responsive plugin.
            row.push('');

            this.$tableAPI.row.add(row);

            return;
        });

        this.$tableAPI.draw(false);
        // Now, since the data has changed the columns widths, trigger
        // the resize handler in order to update the responsive feature.
        this.handleResize();

        return;
    }

    componentWillUnmount() {
        // check if undefined only to enable render test
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    }

    getAsset(id) {
        let asset = this.state.assets.find(a => {
            return a.get('id') === id;
        });
        return asset;
    }

    /**
     * Update the datatable columns size.
     */
    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowClick(event) {
        let asset;
        let id;
        switch (true) {
        case !!~event.target.className.indexOf('show-asset-detail'):
            event.preventDefault();
            id = event.target.getAttribute('asset-id');
            asset = this.getAsset(parseInt(id, 10));
            asset = asset.set('assetType', this.props.assetType);
            asset = asset.set('name', asset.get('assetName'));
            AssetTabActions.show([], asset);
            break;
        case !!~event.target.className.indexOf('toggle-asset-button'):
            event.preventDefault();
            id = event.target.getAttribute('asset-id');
            asset = this.getAsset(parseInt(id, 10));

            AssetTabActions.selectCopyFromAsset(asset);

            break;
        }
        return;
    }

    render() {
        return (
            <div>
                <table id="add-existing-list-assets-table" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            {this.props.columns.map(c => {
                                let column = COLUMNS[c];
                                if (c.name) {
                                    column = COLUMNS[c.name];
                                }
                                return column.header(this);
                            })}
                            <th className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
                <div className="row">
                    <div className="col-sm-7 col-sm-offset-5">
                        <Pagination
                            activePage={this.state.activePage}
                            onChange={this.props.onPageChange}
                            totalPages={this.state.totalPages}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const ListAssetsContainer = Container.create(ListAssets);
export {
    ListAssetsContainer as ListAssets,
    COLUMNS
};


class ReplaceAssetMetadata extends React.Component {

    static get propTypes() {
        return {
            assetType: PropTypes.oneOf(['video']),
            columnDefs: PropTypes.array,
            columns: PropTypes.array,
            displayLinks: PropTypes.bool,
            isOutlineStyle: PropTypes.bool,
            showAssetType: PropTypes.bool,
            showContentType: PropTypes.bool,
        };
    }

    static get defaultProps() {
        return {
            assetType: 'video',
            columnDefs: undefined,
            columns: undefined,
            displayLinks: true,
            isOutlineStyle: false,
            showAssetType: false,
            showContentType: false,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            filters: AssetStore.getState().get('filters'),
            copyFromAsset: AssetTabStore.getState().get('copyFromAsset'),
            copyToAsset: AssetTabStore.getState().get('copyToAsset'),
            size: AssetStore.getState().get('size'),
            total: AssetStore.getState().get('total'),
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
        };
    }

    static getStores() {
        return [AssetStore, LayoutStore, AssetTabStore, PreloaderStore];
    }

    constructor(props) {
        super(props);
        this.state = this.constructor.calculateState();

        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);

        return;
    }

    componentDidMount() {
        AssetActions.clear();
        AssetActions.setFilter('asset-type', this.assetTypeToFilters(this.props.assetType));
        AssetActions.setFilter('operator', 'AND');
        if (this.props.showContentType) {
            AssetActions.setFilter('content-type', VideoConstants.CONTENT_TYPES.FULL_EPISODE.id);
        }
        AssetActions.get(AssetStore.getState().get('filters'), 0, 10);
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            nextState.filters !== this.state.filters ||
            nextState.total !== this.state.total ||
            nextState.showFiltersPanel !== this.state.showFiltersPanel ||
            nextState.copyFromAsset !== this.state.copyFromAsset ||
            nextState.copyToAsset !== this.state.copyToAsset
        ) {
            return true;
        }
        return false;
    }

    assetTypeToFilters(assetType) {
        // FIXME: please change this to use the same constant
        // instead of mapping around.
        let filtersMap = {
            [AssetConstants.ASSET_MAIN_TYPES.AUDIO]: AssetConstants.ASSET_TYPES.AUDIO.id,
            [AssetConstants.ASSET_MAIN_TYPES.VIDEO]: AssetConstants.ASSET_TYPES.VIDEO.id,
            [AssetConstants.ASSET_MAIN_TYPES.IMAGE]: AssetConstants.ASSET_TYPES.IMAGE.id,
            [AssetConstants.ASSET_MAIN_TYPES.DOCUMENT]: AssetConstants.ASSET_TYPES.DOCUMENT.id,
            [AssetConstants.ASSET_MAIN_TYPES.MERCHANDISE]: AssetConstants.ASSET_TYPES.MERCHANDISE.id,
            [AssetConstants.ASSET_MAIN_TYPES.SCRIPT]: AssetConstants.ASSET_TYPES.SCRIPT.id,
            [AssetConstants.ASSET_MAIN_TYPES.VIDEO_TIMELINE]: AssetConstants.ASSET_TYPES.VIDEO_TIMELINE.id
        };
        return filtersMap[assetType];
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */
    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        AssetActions.get(
            AssetStore.getState().get('filters'),
            pageNumber * this.state.size,
            this.state.size
        );
        return;
    }

    handleSearchTerm(term) {
        AssetActions.clearFilter();
        AssetActions.setFilter('asset-name', term);
        AssetActions.setFilter('asset-type', this.assetTypeToFilters(this.props.assetType));
        AssetActions.setFilter('operator', 'AND');
        AssetActions.get(AssetStore.getState().get('filters'), 0, 10);
        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    render() {
        let filterOptions;
        if (this.state.showFiltersPanel) {
            filterOptions = <FilterOptions
                filters={this.state.filters}
                showActive={true}
                showDeliveryType={false}
                showAssetType={this.props.showAssetType}
                showCatalog={false}
                showContentType={this.props.showContentType}
                showDateRange={false}
            />;
        }

        let filtersStyle = {
            bsStyle: 'primary',
            bsSize: 'large'
        };
        if (this.props.isOutlineStyle) {
            filtersStyle = {
                className: 'btn btn-navy-outline',
                bsSize: 'medium'
            };
        }
        const filtersBtn = (
            <Button onClick={this.handleToggleFiltersPanel} className="pull-right" {...filtersStyle}>
                <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
            </Button>
        );

        return (
            <div className="row">
                <div className="row">
                    <div className="col-md-12">
                        <div><strong>{this.context.intl.messages['titles.create.assets.replace-video.panel.note']}</strong></div><br />
                        <div><em>{this.context.intl.messages['titles.create.assets.replace-video.panel.instructions']}</em></div>
                    </div>
                </div>
                <br />
                <div className="col-md-6 show-grid">
                    <SearchBox onChange={this.handleSearchTerm} value=""/>
                </div>
                <div className="col-md-6 show-grid">
                    {filtersBtn}
                </div>
                <div className="container-fluid no-x-padding">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box">
                                <div className="box-body">
                                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                                        <ListAssetsContainer
                                            assetType={this.props.assetType}
                                            displayLinks={this.props.displayLinks}
                                            columns={this.props.columns}
                                            columnDefs={this.props.columnDefs}
                                            isOutlineStyle={this.props.isOutlineStyle}
                                            onPageChange={this.handleLoadPage}
                                        />
                                    </Preloader>
                                </div>
                            </div>
                        </div>
                    </div>
                    {filterOptions}
                </div>
            </div>
        );
    }
}

export {ReplaceAssetMetadata as ReplaceAssetMetadata_BASE};
export default Container.create(ReplaceAssetMetadata);
