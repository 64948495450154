/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import PresetsTable from './table';
import DocumentTitle from '../../common/document-title';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {LayoutActions} from '../../layout/layout-actions';
import LayoutStore from '../../layout/layout-store';
import Preloader from '../../preloader/';
import PreloaderStore from '../../preloader/preloader-store';
import {PermissionPackageActions} from '../../security/permission-package/permission-package-actions';
import PermissionPackageStore from '../../security/permission-package/permission-package-store';
import SessionStore from '../../user/session/session-store';
import FilterOptions from '../filter-options/filter-options';

class Browse extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            permissionPackages: PermissionPackageStore.getState().get('permissionPackages'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            total: PermissionPackageStore.getState().get('total')
        };
    }

    static getStores() {
        return [LayoutStore, PreloaderStore, PermissionPackageStore];
    }

    static getPermissions() {
        return {
            canReadPresets: SessionStore.canUser(SessionStore.PERMISSIONS.PRESETS.READ)
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
    }

    componentDidMount() {
        PermissionPackageActions.clear();
        if (this.props.location.query.active) {
            PermissionPackageActions.get(this.getRouteState(), undefined, undefined, false);
            return;
        }

        this.setRouteState('active', 'ACTIVE').apply();

        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.location.query !== this.props.location.query ||
            nextState.showFiltersPanel !== this.state.showFiltersPanel ||
            nextState.permissionPackages !== this.state.permissionPackages ||
            nextState.preloaderVisible !== this.state.preloaderVisible ||
            nextState.total !== this.state.total) {
            return true;
        }

        return false;
    }

    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {
            PermissionPackageActions.get(this.getRouteState(nextProps), undefined, undefined, false);
        }
        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    render() {
        return (
            <DocumentTitle
                message="document-titles.presets"
            >
                <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                    <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                        <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                            <section className="content-header">
                                <h1>
                                    <i className="fas fa-list-ul"></i>&nbsp;{this.context.intl.messages['presets.browse.title']}
                                    &nbsp;<small>{this.state.total}&nbsp;Packages</small>
                                </h1>
                            </section>
                            <section className="content">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-6 show-grid" />
                                                <div className="col-md-6 show-grid">
                                                    <Button onClick={this.handleToggleFiltersPanel} bsStyle="primary" bsSize="large" className="pull-right">
                                                        <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="box">
                                                        <div className="box-body">
                                                            <PresetsTable
                                                                activePage={Math.ceil((this.getRouteState().get('offset') || 0) / 20 ) || 0}
                                                                displayLinks={this.props.permissions.canReadPresets}
                                                                onPageChange={this.handleLoadPage}
                                                                permissionPackages={this.state.permissionPackages}
                                                                totalPages={Math.ceil(this.state.total/20) || 0}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <FilterOptions
                                location={this.props.location}
                                showActive={true}
                            />
                        </Preloader>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export {Browse as Browse_BASE};
export default WithStoreOnRoute(Container.create(Browse));
