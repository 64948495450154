/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AssetTalentActions} from '../asset-talent-actions';
import {AssetCatalogActions} from '../catalogs/asset-catalog-actions';

import {AssetTabActions} from '~/src/assets-tab/asset-tab-actions';
import {AlertTypes} from '~/src/common/notification/alert';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import {SlidingPanelActions} from '~/src/common/sliding-panel/sliding-panel-actions';
import {UploadFile} from '~/src/common/utils/utils';
import Dispatcher from '~/src/dispatcher/dispatcher';
import Request from '~/src/request';
import {RouterActions} from '~/src/router/router-actions';
import {ActionHistoryConstants} from '~/src/system/action-history/action-history-actions';

const CONSTANTS = {
    CONTENT_TYPES: {
        GENERAL: {id: 1, name: 'General'}
    },

    MERCHANDISE: {
        ADD: 'asset.merchandise.add',
        CLEAR: 'asset.merchandise.clear',
        GET: {
            ERROR: 'asset.merchandise.get.error',
            START: 'asset.merchandise.get.start',
            SUCCESS: 'asset.merchandise.get.success'
        },
        SAVE: {
            ERROR: 'asset.merchandise.save.error',
            START: 'asset.merchandise.save.start',
            SUCCESS: 'asset.merchandise.save.success'
        },
        UPDATE: 'asset.merchandise.update'
    },

    toArray: /*istanbul ignore next*/function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k])
            .sort((a, b) => a.name.localeCompare(b.name));
    }
};

class MerchandiseActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MERCHANDISE.CLEAR
        });
    }

    findById(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MERCHANDISE.GET.START,
            id: id,
        });

        let actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.MERCHANDISE,
            'object-id': id,
            offset: 0,
            size: 4
        };

        Promise.all([
            Request.get(`asset/merchandise/${id}`).exec(),
            Request.get('system/action-history').query(actionHistoryQuery).exec()
        ])
            .spread((assetRes, history) => {
                let merchandise = assetRes.body;
                merchandise.active = merchandise.active === 1;
                history = history.body.results;
                history.sort(/*istanbul ignore next*/(h1, h2) => h2.actionDate.localeCompare(h1.actionDate));
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MERCHANDISE.GET.SUCCESS,
                    asset: Immutable.fromJS(merchandise),
                    history: Immutable.fromJS(history)
                });
            })
            .catch((err) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MERCHANDISE.GET.ERROR,
                    error: err
                });

                switch (err.status) {
                case 404:
                    RouterActions.notFound();
                    break;
                default:
                    NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'asset.merchandise.load-error');
                    break;
                }
                throw err;
            });


    }

    /*istanbul ignore next*/
    _save(asset, file, originalAsset, originalFile) {
        let data = asset.toJS();
        ['contentType', 'file', 'files'].forEach(p => delete data[p]);
        if (file) {
            file = file[0];
        }
        data.type = 1;
        if (data.active) {
            data.active = 1;
        } else {
            data.active = 0;
        }
        let fileExists = asset.get('filePath');
        let method = Request.post;
        let uri = 'asset/merchandise';
        if (data.id !== undefined) {
            method = Request.put;
            uri = `asset/merchandise/${data.id}`;
        }

        if (file === originalFile) {
            file = null;
        }
        return method(uri)
            .send(data)
            .exec()
            .then(res => {
                let requests = [res];
                let uploadMethod = 'POST';
                if (fileExists) {
                    uploadMethod = 'PUT';
                }
                if (file) {
                    let upload = UploadFile(
                        uploadMethod,
                        `asset/merchandise/${res.body.id}/file`,
                        file,
                        new XMLHttpRequest()
                    ).catch(e => {
                        throw {
                            assetId: res.body.id,
                            e
                        };
                    });

                    requests.push(upload);
                } else {
                    requests.push(undefined);
                }

                if (originalAsset &&
                    originalAsset.get('filePath') &&
                    asset.get('filePath') === undefined) {
                    requests.push(
                        Request.delete(`asset/merchandise/${res.body.id}/file`).exec()
                    );
                }

                return requests;
            });
    }

    save(asset, originalAsset, file, assignedCatalogs, originalAssignedCatalogs, talent, originalTalent, options, originalFile) {
        let defaults = {
            messages: {
                error: 'asset.merchandise.create.error',
                success: 'asset.merchandise.create.success'
            }
        };
        Object.assign(defaults, options);

        let id;

        Dispatcher.dispatch({
            actionType: CONSTANTS.MERCHANDISE.SAVE.START
        });

        this._save(asset, file, originalAsset, originalFile)
            .spread((res, fileRes) => {
                id = res.body.id;
                return Promise.all([
                    fileRes,
                    ...AssetCatalogActions.assetCatalogSave(id, assignedCatalogs, originalAssignedCatalogs),
                    ...AssetTalentActions.assetTalentSave(id, talent, originalTalent)
                ]);
            })
            .spread(fileRes => {
                RouterActions.redirect(`/assets/merchandise/${id}`, true);
                /*istanbul ignore next*/
                if (fileRes) {
                    let fileResp = JSON.parse(fileRes);
                    this.update('filePath', fileResp.filePath);
                }
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MERCHANDISE.SAVE.SUCCESS,
                    id: id,
                    file: file[0]
                });
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, defaults.messages.success);

            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MERCHANDISE.SAVE.ERROR
                });

                /*istanbul ignore next*/
                if (err.assetId) {
                    RouterActions.redirect(`/assets/merchandise/${err.assetId}`, true);
                    NotificationActions.showAlertDanger('asset.error.incomplete-save');
                    throw err;
                }

                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, defaults.messages.error);
                throw err;
            });

    }

    saveAndAdd(asset, file, addConstants) {

        let defaults = {
            messages: {
                error: 'asset.merchandise.create.error',
                success: 'asset.merchandise.create.success'
            }
        };

        Dispatcher.dispatch({
            actionType: CONSTANTS.MERCHANDISE.SAVE.START
        });

        this._save(asset, file)
            .spread((res, fileRes) => {
                if (fileRes) {
                    let fileResp = JSON.parse(fileRes);
                    this.update('filePath', fileResp.filePath);
                }
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MERCHANDISE.SAVE.SUCCESS,
                    id: res.body.id
                });
                asset = asset.set('id', res.body.id);
                SlidingPanelActions.hide('addNew');
                AssetTabActions.add('merchandise', Immutable.List([asset]), addConstants);
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, defaults.messages.success);

            })
            .catch(/*istanbul ignore next*/err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.MERCHANDISE.SAVE.ERROR
                });
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, defaults.messages.error);
                throw err;
            });

    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.MERCHANDISE.UPDATE,
            attr: attr,
            value: value
        });


    }
}

let actions = new MerchandiseActions();

export {
    actions as MerchandiseActions,
    CONSTANTS as MerchandiseConstants
};
