/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import Immutable from 'immutable';

import {AssetTabActions} from '../../assets-tab/asset-tab-actions';
import {AlertTypes} from '../../common/notification/alert';
import {NotificationActions} from '../../common/notification/notification-actions';
import {SlidingPanelActions} from '../../common/sliding-panel/sliding-panel-actions';
import {UploadFile} from '../../common/utils/utils';
import Dispatcher from '../../dispatcher/dispatcher';
import Request from '../../request';
import {RouterActions} from '../../router/router-actions';
import {ActionHistoryConstants} from '../../system/action-history/action-history-actions';
import {AssetTalentActions} from '../asset-talent-actions';
import {AssetCatalogActions} from '../catalogs/asset-catalog-actions';

const CONSTANTS = {
    AUDIO: {
        ADD: 'asset.audio.add',
        CLEAR: 'asset.audio.clear',
        GET: {
            ERROR: 'asset.audio.get.error',
            START: 'asset.audio.get.start',
            SUCCESS: 'asset.audio.get.success'
        },
        SAVE: {
            ERROR: 'asset.audio.save.error',
            START: 'asset.audio.save.start',
            SUCCESS: 'asset.audio.save.success',
        },
        UPDATE: 'asset.audio.update'
    },
    CONTENT_TYPES: {
        AUDIO_STEM: {id: 8, name: 'Audio Stem'},
        CLIP: {id: 7, name: 'Clip'},
        EPISODIC_RADIO_SPOT: {id: 1, name: 'Episodic Radio Spot'},
        GENERIC_RADIO_SPOT: {id: 2, name: 'Generic Radio Spot'},
        INTERVIEW: {id: 6, name: 'Interview'},
        SONG: {id: 5, name: 'Song'},
        SPOT_REEL: {id: 3, name: 'Spot Reel'},
        THEME_SONG: {id: 4, name: 'Theme Song'}
    },

    toArray:/* istanbul ignore next */ function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k])
            .sort((a, b) => a.name.localeCompare(b.name));
    }
};

class AudioActions {
    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.AUDIO.CLEAR
        });
    }

    findById(audioId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.AUDIO.GET.START,
            audioId: audioId,
        });

        let actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.AUDIO,
            'object-id': audioId,
            offset: 0,
            size: 4
        };

        Promise.all([
            Request.get(`asset/audio/${audioId}`).exec(),
            Request.get('system/action-history').query(actionHistoryQuery).exec()
        ]).spread((audioRes, history) => {
            let audio = audioRes.body;
            history = history.body.results;
            history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));
            audio.active = audio.active === 1;

            Dispatcher.dispatch({
                actionType: CONSTANTS.AUDIO.GET.SUCCESS,
                asset: Immutable.fromJS(audio),
                history: Immutable.fromJS(history)
            });
            return;
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.AUDIO.GET.ERROR,
                error: err
            });

            switch (err.status) {
            case 404:
                RouterActions.notFound();
                break;
            default:
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, 'asset.audio.load-error');
                break;
            }
            throw err;
        });

        return;
    }

    save(asset, audioFile, assignedCatalogs, originalAssignedCatalogs, talent, originalTalent, options, originalAudio) {
        let defaults = {
            messages: {
                error: 'asset.audio.create.error',
                success: 'asset.audio.create.success'
            }
        };
        Object.assign(defaults, options);

        let id;

        Dispatcher.dispatch({
            actionType: CONSTANTS.AUDIO.SAVE.START
        });

        this._save(asset, audioFile, originalAudio)
            .spread((res, audioFileRes) => {
                id = res.body.id;
                return Promise.all([
                    audioFileRes,
                    ...AssetCatalogActions.assetCatalogSave(id, assignedCatalogs, originalAssignedCatalogs),
                    ...AssetTalentActions.assetTalentSave(id, talent, originalTalent)
                ]);
            })
            .spread(audioFileRes => {
                RouterActions.redirect(`/assets/audio/${id}`, true);
                if (audioFileRes) {
                    let fileResp = JSON.parse(audioFileRes);
                    this.update('uploadedFile', fileResp.uploadedFile);
                }

                Dispatcher.dispatch({
                    actionType: CONSTANTS.AUDIO.SAVE.SUCCESS,
                    id,
                    audioFile: audioFile[0]
                });
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, defaults.messages.success);
                return;
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.AUDIO.SAVE.ERROR
                });

                // If asset has been created, then redirect to Edit Asset page
                // so that successive calls to save don't generate any new assets.
                if (err.assetId) {
                    RouterActions.redirect(`/assets/audio/${err.assetId}`, true);
                    NotificationActions.showAlertDanger('asset.error.incomplete-save');
                    throw err;
                }

                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, defaults.messages.error);
                throw err;
            });
        return;
    }

    saveAndAdd(asset, audioFile, addConstants) {
        let defaults = {
            messages: {
                error: 'asset.audio.create.error',
                success: 'asset.audio.create.success'
            }
        };

        Dispatcher.dispatch({
            actionType: CONSTANTS.AUDIO.SAVE.START
        });

        this._save(asset, audioFile)
            .spread((res, audioFileRes) => {
                if (audioFileRes) {
                    let fileResp = JSON.parse(audioFileRes);
                    this.update('uploadedFile', fileResp.uploadedFile);
                }
                Dispatcher.dispatch({
                    actionType: CONSTANTS.AUDIO.SAVE.SUCCESS,
                    id: res.body.id
                });
                asset = asset.set('id', res.body.id);
                SlidingPanelActions.hide('addNew');
                AssetTabActions.add('audio', Immutable.List([asset]), addConstants);
                NotificationActions.showAlert(AlertTypes.ALERT_SUCCESS.name, defaults.messages.success);
                return;
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.AUDIO.SAVE.ERROR
                });
                NotificationActions.showAlert(AlertTypes.ALERT_DANGER.name, defaults.messages.error);
                throw err;
            });
        return;
    }

    _save(asset, audioFile, originalAudio) {
        let audioData = asset.toJS();
        ['audioURL', 'files'].forEach(p => delete audioData[p]);
        audioFile = audioFile[0];
        let id = asset.get('id');
        const fileExists = asset.get('audioURL');
        let method = Request.post;
        let uri = 'asset/audio';

        if (audioData.active) {
            audioData.active = 1;
        } else {
            audioData.active = 0;
        }

        if (id !== undefined) {
            method = Request.put;
            uri = `asset/audio/${id}`;
        }
        if (audioFile === originalAudio) {
            audioFile = null;
        }
        let uploadMethod = 'POST';
        if (fileExists) {
            uploadMethod = 'PUT';
        }
        return method(uri).send(audioData).exec().then(res => {
            if (audioFile) {
                let upload = UploadFile(
                    uploadMethod,
                    `asset/audio/${res.body.id}/file`,
                    audioFile,
                    new XMLHttpRequest()
                ).catch(e => {
                    throw {
                        assetId: res.body.id,
                        e
                    };
                });

                return [res, upload];
            } else {
                return [res];
            }
        });
    }

    update(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.AUDIO.UPDATE,
            attr: attr,
            value: value
        });

        return;
    }

}

let actions = new AudioActions();

export {
    actions as AudioActions,
    CONSTANTS as AudioConstants
};
