/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, ControlLabel} from 'react-bootstrap';

import Summary from './summary/summary';
import {AssetConstants, AssetActions} from '../../asset-actions';
import {AssetTalentActions} from '../../asset-talent-actions';
import AssetTalentStore from '../../asset-talent-store';
import {AssetTitleActions} from '../../asset-title-actions';
import AssetTitleStore from '../../asset-title-store';
import AssetUserStore from '../../asset-user-store';
import {AssetCatalogActions} from '../../catalogs/asset-catalog-actions';
import AssetCatalogStore from '../../catalogs/asset-catalog-store';
import CatalogsTab from '../../catalogs/catalogs';
import TalentTab from '../../talent/talent';
import TitleTab from '../../title/title';
import UserTab from '../../user/user';
import {ImageActions} from '../image-actions';
import ImageStore from '../image-store';

import AddTabModal from '~/src/common/add-tab-modal';
import RouteValidator from '~/src/common/custom-validator/route/route';
import DocumentTitle from '~/src/common/document-title';
import FileInput from '~/src/common/file-input';
import Panel from '~/src/common/panel/panel';
import {Tab, Tabs} from '~/src/common/routing-tab/routing-tab';
import {History, SideNotes} from '~/src/common/side-notes/side-notes';
import {GetAvailableTabs, GetTabDataByValue} from '~/src/common/utils/tab-utils';
import {AlertsWarnings} from '~/src/common/validations/alerts-warnings';
import ActionsMenu from '~/src/layout/actions-menu/actions-menu';
import StickyHeader from '~/src/layout/sticky-header/sticky-header';
import Preloader from '~/src/preloader';
import {RouterActions} from '~/src/router/router-actions';
import {ActionHistoryActions, ActionHistoryConstants} from '~/src/system/action-history/action-history-actions';
import SessionStore from '~/src/user/session/session-store';

class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            route: RouteValidator
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            route: undefined
        };
    }

    static getPermissions() {
        return {
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.ASSET_IMAGE.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.ASSET_IMAGE.EDIT),
            canReadCatalogs: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.ASSET.READ),
            canReadTalent: SessionStore.canUser(SessionStore.PERMISSIONS.TALENT.READ),
            canReadTitles: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.READ),
            canReadUsers: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.READ)
        };
    }

    static calculateState() {
        const assetCatalogState = AssetCatalogStore.getState();
        const imageState = ImageStore.getState();
        const talentState = AssetTalentStore.getState();
        const titlesState = AssetTitleStore.getState();
        return {
            asset: imageState.get('asset'),
            assignedCatalogs: assetCatalogState.get('assignedCatalogs'),
            history: imageState.get('history'),
            multipleFileSources: false,
            originalAsset: imageState.get('originalAsset'),
            originalAssignedCatalogs: assetCatalogState.get('originalAssignedCatalogs'),
            originalTalent: talentState.get('originalTalent'),
            originalTitles: titlesState.get('originalTitles'),
            showPreloader: imageState.get('showPreloader'),
            showUserPreloader: AssetUserStore.getState().get('showPreloader'),
            talent: talentState.get('talent'),
            titles: titlesState.get('titles')
        };
    }

    static getStores() {
        return [AssetCatalogStore, AssetTalentStore, AssetTitleStore, AssetUserStore, ImageStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            activeTab: 0,
            showAddTabModal: false
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.handleAddTab = this.handleAddTab.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleElasticSearchSync = this.handleElasticSearchSync.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleToggleShowAddTabModal = this.handleToggleShowAddTabModal.bind(this);
        this.handleSourceMigratedToCloudFront = this.handleSourceMigratedToCloudFront.bind(this);
        this.handleSourceFilesChange = this.handleSourceFilesChange.bind(this);
        this.isActive = this.isActive.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.toTab = this.toTab.bind(this);
    }

    componentDidMount() {
        this.init(this.props);
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.IMAGE);
    }

    handleAddTab(tab) {
        if (!tab) {
            return;
        }
        RouterActions.redirect(`/assets/image/${this.props.params.id}/${tab.route}`);
    }

    handleCancel() {
        RouterActions.redirect('/assets', true);
    }

    handleElasticSearchSync() {
        AssetActions.syncElasticSearch(this.props.params.id);
    }

    handleRenditionChange(e) {
        ImageActions.update('rendition', e.target.files[0] || null);
    }

    handleSave() {
        let options = {
            messages: {
                error: `asset.image.${this.props.route.mode}.error`,
                success: `asset.image.${this.props.route.mode}.success`
            }
        };

        const renditionFile = this.state.asset.get('rendition') || null;
        const imageFiles = this.state.asset.get('files') || [];

        ImageActions.save(this.state.asset,
            renditionFile,
            imageFiles,
            AssetCatalogStore.getState().get('assignedCatalogs'),
            AssetCatalogStore.getState().get('originalAssignedCatalogs'),
            AssetTalentStore.getState().get('talent'),
            AssetTalentStore.getState().get('originalTalent'),
            AssetTitleStore.getState().get('titles'),
            AssetTitleStore.getState().get('originalTitles'),
            options,
            this.props.route.mode);


    }

    handleSourceMigratedToCloudFront() {
        AssetActions.migrateToCloudFront(this.props.params.id);
    }

    handleSourceFilesChange(e) {
        ImageActions.update('files', [...e.target.files]);
        if (e.target.files.length > 1) {
            this.setState({
                multipleFileSources: true
            });
        }
    }

    handleToggleShowAddTabModal() {
        this.setState(prevState => ({
            showAddTabModal: !prevState.showAddTabModal
        }));
    }

    init(props) {
        if (props.route.mode === 'edit') {
            ImageActions.findById(props.params.id);
            AssetCatalogActions.findByAssetId(props.params.id);
            AssetTalentActions.get(props.params.id);
            AssetTitleActions.get(props.params.id);
        } else {
            ImageActions.clear();
            AssetTalentActions.clear();
            AssetCatalogActions.clear();
        }
        RouterActions.registerRedirectCheck(/*istanbul ignore next*/() => {
            return this.isDirty();
        });
    }

    isActive(tabName) {
        let active = false;
        const split = this.props.location.pathname.split('/');
        if (split[split.length-1] === tabName) {
            active = 'active';
        }
        return active;
    }

    /*istanbul ignore next*/
    isDirty() {
        if (this.props.route.mode === 'create') {
            return false;
        }

        if (location) {
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && /* asset */
                goingTo[2] === current[2] && /* image */
                goingTo[3] === current[3]) /* asset id */ {
                return false; // just changing tabs no confirmation needed.
            }
        }

        return !this.state.asset.equals(this.state.originalAsset) ||
        !AssetCatalogStore.getState().get('assignedCatalogs').equals(AssetCatalogStore.getState().get('originalAssignedCatalogs')) ||
        !AssetTalentStore.getState().get('talent').equals(AssetTalentStore.getState().get('originalTalent'));
    }

    toTab(tab, routePrefix, component) {
        if (!tab) {
            return;
        }
        return <Tab route={`${routePrefix}/${tab.route}`} title={this.context.intl.messages[tab.message]}>
            {component}
        </Tab>;
    }

    render() {
        let actionsMenu;
        let disabled = true;
        let eventsAndNotes = null;
        let imagePreview;
        let mode = this.props.route.mode;
        let renditionPreview;
        let sourceDownloadAndReplace;
        let sourceUpload;
        let validations = ImageStore.getValidations();
        let fileValidations = ImageStore.getImageTypeValidations();

        let renditionFile = [];
        /*istanbul ignore next*/
        const assetRendition = this.state.asset.get('rendition') || {name: ''};
        /*istanbul ignore next*/
        if (assetRendition.name) {
            renditionFile = [this.state.asset.get('rendition').name];
        }

        let sourceFilesList = [];
        /*istanbul ignore next*/
        const assetSourceFiles = this.state.asset.get('files') || [];
        /*istanbul ignore next*/
        if (assetSourceFiles.length) {
            sourceFilesList = this.state.asset.get('files', Immutable.List()).map(/*istanbul ignore next*/f => f.name);
        }

        // catalogs tab for create mode
        let catalogsTab;

        let baseRoute = `/assets/image/${this.props.params.id}`;
        if (mode === 'create') {
            baseRoute = '/assets/image/create';
            disabled = false;

            catalogsTab = <Tab route="/assets/image/create/catalogs" title={this.context.intl.messages['asset.edit.catalogs-tab-title']}>
                <CatalogsTab
                    displayLinks={this.props.permissions.canReadCatalogs}
                />
            </Tab>;

            actionsMenu = <ActionsMenu
                canSave={!validations.length}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
                saveText={this.context.intl.messages['common.create-asset']}
            />;

            renditionPreview = <div>
                <Panel collapsible defaultExpanded iconClass="far fa-image" title={this.context.intl.messages['asset.image.create.upload.rendition.title']} additionalStyle="padding-x-20">
                    <ControlLabel>{this.context.intl.messages['asset.image.create.upload.rendition']}</ControlLabel>
                    <FileInput
                        accept="image/*"
                        disabled={this.state.multipleFileSources}
                        files={renditionFile}
                        id="image-file"
                        onChange={this.handleRenditionChange}
                    />
                </Panel>
            </div>;
            sourceUpload = <div>
                <Panel collapsible defaultExpanded iconClass="fas fa-upload" title={this.context.intl.messages['asset.image.create.upload.source.title']} additionalStyle="padding-x-20">
                    <ControlLabel>{this.context.intl.messages['common.sourceFiles']}</ControlLabel>
                    <FileInput
                        accept="application/*, image/*, video/*"
                        files={sourceFilesList}
                        id="source-file"
                        multiple
                        onChange={this.handleSourceFilesChange}
                    />
                    <hr/>
                    <small>
                        <p>
                            <strong>{`${this.context.intl.messages['common.note']}-`}</strong>&nbsp;
                            {this.context.intl.messages['titles.create.assets.add-new.images.note.when-condition']}
                        </p>
                        <ul>
                            <li>{this.context.intl.messages['titles.create.assets.add-new.images.note.serialized-names']}</li>
                            <li>{this.context.intl.messages['titles.create.assets.add-new.images.note.example-name']}</li>
                            <li>{this.context.intl.messages['titles.create.assets.add-new.images.note.metadata-fields']}</li>
                        </ul>
                    </small>
                </Panel>
            </div>;
        }

        // Tabs
        let addTabLabel;
        let addTabModal;
        let historyTab = null;
        let talentTab;
        let titlesTab;
        let userTab;

        if (mode === 'edit') {
            if (this.props.permissions.canEdit) {
                disabled = false;
            }
            /*istanbul ignore next*/
            const tabsAndConditions = [
                {
                    canShow: (tabName) => (!!this.state.assignedCatalogs.filter(c => c.get('name') !== 'All Images').size || this.isActive(tabName)),
                    hasPermission: this.props.permissions.canReadCatalogs,
                    tabs: [{
                        message: 'asset.edit.catalogs-tab-title',
                        route: 'catalogs',
                        value: 'catalogs'
                    }]
                }, {
                    canShow: (tabName) => (!!this.state.talent.size || this.isActive(tabName)),
                    hasPermission: this.props.permissions.canReadTalent,
                    tabs: [{
                        message: 'asset.talent.tab.title',
                        route: 'talent',
                        value: 'talent'
                    }]
                }, {
                    canShow: (tabName) => (!!this.state.titles.size || this.isActive(tabName)),
                    hasPermission: this.props.permissions.canReadTitles,
                    tabs: [{
                        message: 'common.tab-title.titles',
                        route: 'titles',
                        value: 'titles'
                    }]
                }
            ];
            const availableTabs = GetAvailableTabs(tabsAndConditions);

            addTabModal = <AddTabModal
                availableTabs={availableTabs}
                onAdd={this.handleAddTab}
                onHide={this.handleToggleShowAddTabModal}
                show={this.state.showAddTabModal}
                tabsAndConditions={tabsAndConditions}
            />;

            actionsMenu = (
                <ActionsMenu
                    canEdit={this.props.permissions.canEdit}
                    canSave={!validations.length && this.props.permissions.canEdit}
                    hideSaveAndSync={!this.props.permissions.canEdit}
                    onCancel={this.handleCancel}
                    onElasticSearchSync={this.handleElasticSearchSync}
                    onSave={this.handleSave}
                >
                    {this.props.permissions.canEdit && <Button
                        bsSize="large"
                        className="btn btn-primary-outline Mr(3px) Mb(3px)"
                        disabled={!this.props.permissions.canEdit}
                        onClick={this.handleSourceMigratedToCloudFront}
                    >
                        <i className="fas fa-sync-alt"/>&nbsp;{this.context.intl.messages['asset.image.source-migrated']}
                    </Button>}
                </ActionsMenu>
            );

            eventsAndNotes = (
                <SideNotes
                    basePath={baseRoute}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={ !this.props.permissions.canEdit }
                    notes={this.state.history.toJS().slice(0, 4)}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['common.side-notes.title']}
                />
            );

            if (availableTabs.get('catalogs')) {
                catalogsTab = this.toTab(GetTabDataByValue(tabsAndConditions, 'catalogs'), baseRoute,
                    <CatalogsTab
                        assetId={parseInt(this.props.params.id, 10)}
                        displayLinks={this.props.permissions.canReadCatalogs}
                    />
                );
            }

            historyTab = <Tab route={`${baseRoute}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
                <History
                    actionObject={ActionHistoryConstants.ACTION_OBJECTS.IMAGE}
                    id={this.props.params.id}
                    pagination
                    pathname={this.props.location.pathname}
                    query={this.props.location.query}/>
            </Tab>;

            if (availableTabs.get('talent')) {
                talentTab = this.toTab(GetTabDataByValue(tabsAndConditions, 'talent'), baseRoute,
                    <TalentTab
                        assetId={parseInt(this.props.params.id, 10)}
                        displayLinks={this.props.permissions.canReadTalent}
                    />
                );
            }

            if (availableTabs.get('titles')) {
                titlesTab = this.toTab(GetTabDataByValue(tabsAndConditions, 'titles'), baseRoute,
                    <TitleTab
                        asset={this.state.asset}
                        assetId={parseInt(this.props.params.id, 10)}
                        assetType={AssetConstants.ASSET_MAIN_TYPES.IMAGE}
                        displayLinks={this.props.permissions.canReadTitles}
                        location={this.props.location}
                    />
                );
            }

            userTab = <Tab route={`${baseRoute}/users`} title={this.context.intl.messages['asset.user.tab.title']}>
                <UserTab
                    assetId={parseInt(this.props.params.id, 10)}
                    displayLinks={this.props.permissions.canReadUsers}
                    location={this.props.location}
                />
            </Tab>;

            /*istanbul ignore next*/
            if (Object.keys(availableTabs.toJS()).some(t => !availableTabs.get(t))) {
                addTabLabel = <Tab route={`${this.props.location.pathname}/#`} onClick={/*istanbul ignore next*/(event) => {
                    this.handleToggleShowAddTabModal();
                    event.preventDefault();
                }} title={this.context.intl.messages['common.create.add-tab']}/>;
            }

            let imageDimensions = this.context.intl.messages['asset.image.image-preview.dimensions.not-available'];
            let imageHeight = this.state.asset.get('previewHeight');
            let imageWidth = this.state.asset.get('previewWidth');

            /*istanbul ignore next*/
            if (imageHeight || imageWidth) {
                imageDimensions = `${imageWidth || '-' } x ${imageHeight || '-' } pixels`;
            }

            imagePreview = <Panel
                collapsible
                defaultExpanded
                iconClass="fas fa-camera"
                title={this.context.intl.messages['asset.image.image-preview']}>
                <img src={this.state.asset.get('previewUrl')} className="img-thumbnail" />
                <p className="image-dimensions">({imageDimensions})</p>
            </Panel>;
            renditionPreview = <Panel
                collapsible
                defaultExpanded
                iconClass="far fa-image"
                title={this.context.intl.messages['asset.image.edit.upload.rendition.title']}>
                <img src={this.state.asset.get('fullResolutionUrl')} className="img-thumbnail" />
                <ControlLabel>{this.context.intl.messages['asset.image.edit.upload.rendition']}</ControlLabel>
                <FileInput
                    accept="image/*"
                    disabled={this.state.multipleFileSources}
                    files={renditionFile}
                    id="image-file"
                    onChange={this.handleRenditionChange}
                />
            </Panel>;

            /*istanbul ignore next*/
            if (this.state.asset.get('sourceUrl')) {
                let sourceURL = this.state.asset.get('sourceUrl');
                let filename = sourceURL.split('/').pop();
                sourceDownloadAndReplace = <div>
                    <Panel iconClass="fas fa-download" title={this.context.intl.messages['asset.image.edit.download.source.title']} additionalStyle="padding-x-20">
                        <a href={sourceURL} target="_blank" style={{wordBreak: 'break-word'}}>{filename}</a>
                    </Panel>
                    <Panel collapsible defaultExpanded iconClass="fas fa-upload" title={this.context.intl.messages['asset.image.edit.upload.source.title']} additionalStyle="padding-x-20">
                        <ControlLabel>{this.context.intl.messages['asset.image.create.upload.source']}</ControlLabel>
                        <FileInput
                            accept="application/*, image/*, video/*"
                            files={sourceFilesList}
                            id="source-file"
                            multiple
                            onChange={this.handleSourceFilesChange}
                        />
                    </Panel>
                </div>;
            } else {
                sourceDownloadAndReplace = <Panel collapsible iconClass="fas fa-upload" title={this.context.intl.messages['asset.image.edit.upload.source.title']} additionalStyle="padding-x-20">
                    <ControlLabel>{this.context.intl.messages['asset.image.create.upload.source']}</ControlLabel>
                    <FileInput
                        accept="application/*, image/*, video/*"
                        files={sourceFilesList}
                        id="source-file"
                        multiple
                        onChange={this.handleSourceFilesChange}
                    />
                </Panel>;
            }
        }

        let summaryTab = (
            <Tab route={baseRoute} title={this.context.intl.messages['asset.image.summary.title']}>
                <Summary
                    asset={this.state.asset}
                    disabled={disabled}
                    mode={mode}
                />
            </Tab>
        );

        let entityName;

        if (this.state.asset.get('assetName')) {
            entityName = this.state.asset.get('assetName');
        }

        return (
            <DocumentTitle
                message={`document-titles.${mode}-image`} entityName={entityName}
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1><i className="fas fa-camera"/>&nbsp;{this.context.intl.messages[`asset.image.${mode}.title`]}&nbsp;
                                <small>{this.state.asset.get('assetName')}</small>
                            </h1>
                            <p className="padding-top-10">
                                <em>{this.context.intl.messages[`asset.image.${mode}.info`]}</em>
                            </p>
                        </div>
                        {actionsMenu}
                        {addTabModal}
                    </StickyHeader>
                    <Preloader show={this.state.showPreloader||this.state.showUserPreloader} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs location={this.props.location}>
                                        {summaryTab}
                                        {titlesTab}
                                        {talentTab}
                                        {userTab}
                                        {catalogsTab}
                                        {addTabLabel}
                                        {historyTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <AlertsWarnings
                                        title={this.context.intl.messages['common.alerts-warnings-title']}
                                        validations={[...validations, ...fileValidations]}
                                    />
                                    {sourceUpload}
                                    {imagePreview}
                                    {renditionPreview}
                                    {sourceDownloadAndReplace}
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(Create);
