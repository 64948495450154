/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import {Alert} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import ReactTable, {Column} from 'react-table'; // eslint-disable-line import/named

import {CellExtraProps, NOT_APPLIED_MODELS_COLUMNS} from './columns';
import {ChangeNotAppliedSearchTerm, FilteredNotAppliedModels$, NotAppliedSearchTerm$, HasContentAiModelActivated$, HasUnassignedViModel$} from '../bl/stores';

import SearchBox from '~/src/common/search-box/search-box';
import pick from '~/src/common/utils/pick';
import WithRxSubscriptions from '~/src/decorators/with-rx-subscriptions';

type Props = {
    hasViModel: boolean,
    hasContentAiModelActivated: boolean,
    models: ReadonlyArray<VideoMachineLearningProject>,
    searchTerm: string,
    showDelimiter: boolean;
}

class NotAppliedModels extends React.PureComponent<Props> {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    private cellExtraProps: CellExtraProps = {
        messages: pick(this.context.intl.messages, ['asset.edit.ai-models-tab.messages.run.tooltip', 'date-format-title-release-0']),
    };

    private getCellExtraProps = () => this.cellExtraProps;
    private getTheadThProps = (state: unknown, rowInfo: unknown, column: Column, instance: any) => ({
        onClick: () => {
            if (column) {
                instance.sortColumn(column);
            }
        }
    });

    private renderViAlertIfNeeded() {
        if (!this.props.hasViModel) {
            return null;
        }

        return (
            <Alert
                bsClass="alert alert-danger Mt(20px)"
            >
                <i className="icon fa fas fa-ban" />
                <FormattedMessage id="asset.edit.ai-models-tab.messages.no-vi-model" />
            </Alert>
        );
    }

    private renderContentAiAlertIfNeeded() {
        if (this.props.hasContentAiModelActivated) {
            return null;
        }

        return (
            <Alert
                bsClass="alert alert-danger Mt(20px)"
            >
                <i className="icon fa fas fa-ban" />
                <FormattedMessage id="asset.edit.ai-models-tab.messages.no-content-ai-model" />
            </Alert>
        );
    }

    render() {
        if (this.props.models.length === 0 && this.props.searchTerm === '') {
            return null;
        }

        return (
            <div>
                {this.props.showDelimiter && <hr />}

                {this.renderViAlertIfNeeded()}

                {this.renderContentAiAlertIfNeeded()}

                <h4>
                    <FormattedMessage id="asset.edit.ai-models-tab.table.unassigned-models" />
                </h4>

                <div className="form-section">
                    <div className="container-fluid no-x-padding">
                        <div className="row">
                            <div className="col-md-3 show-grid" />
                            <div className="col-md-6 show-grid">
                                <SearchBox value={this.props.searchTerm} onChange={ChangeNotAppliedSearchTerm} />
                            </div>
                            <div className="col-md-3 show-grid" />
                        </div>
                    </div>
                    <div className="container-fluid no-x-padding">
                        <div className="row">
                            <div className="col-md-12">
                                <ReactTable
                                    className="-striped table-bordered table-striped responsive ai-models"
                                    columns={NOT_APPLIED_MODELS_COLUMNS}
                                    // react-table defines data as mutable array =(
                                    data={this.props.models as VideoMachineLearningProject[]}
                                    getTheadThProps={this.getTheadThProps}
                                    getTdProps={this.getCellExtraProps}
                                    loading={false}
                                    pageSize={this.props.models.length}
                                    showPagination={false}
                                    sortable={true}
                                    resizable={false}
                                />

                                <br />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default WithRxSubscriptions(NotAppliedModels, {
    hasContentAiModelActivated: HasContentAiModelActivated$,
    hasViModel: HasUnassignedViModel$,
    models: FilteredNotAppliedModels$,
    searchTerm: NotAppliedSearchTerm$,
});
export {
    NotAppliedModels as NotAppliedModels_BASE
};
