/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import {ForensicActions, ForensicConstants} from './forensic-actions';
import ForensicStore from './forensic-store';
import Table from './table';

import {DownloadActions} from '~/src/common/download/download-actions';
import Pagination from '~/src/common/table/pagination';
import SessionStore from '~/src/user/session/session-store';

class Forensic extends Component {
    static get propTypes() {
        return {
            assetId: PropTypes.number.isRequired,
            disabled: PropTypes.bool.isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            forensicUrls: ForensicStore.getState().get('forensicUrls'),
            total: ForensicStore.getState().get('total'),
        };
    }

    static getStores() {
        return [ForensicStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            activePage: 0
        }, this.constructor.calculateState());

        this.handleExportData = this.handleExportData.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentDidMount() {
        ForensicActions.get(this.props.assetId, Immutable.Map({
            offset: 0,
            size: ForensicConstants.PAGE_SIZE
        }));
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (nextProps.disabled !== this.props.disabled ||
            nextState.forensicUrls !== this.state.forensicUrls ||
            nextState.activePage !== this.state.activePage ||
            nextState.total !== this.state.total);
    }

    handleExportData() {
        DownloadActions.startDownloadExecution(`asset/video/${this.props.assetId}/forensic-url-used/export`);
    }

    handlePageChange(pageNumber) {
        let offset = pageNumber * ForensicConstants.PAGE_SIZE;
        this.setState(() => ({
            activePage: pageNumber
        }));
        ForensicActions.get(this.props.assetId, Immutable.Map({
            offset: offset,
            size: ForensicConstants.PAGE_SIZE
        }));
    }

    render() {
        let exportDataButton;

        if (SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.VIDEO.DOWNLOAD_FORENSIC_DATA)) {
            exportDataButton = <span className="pull-right">
                <Button
                    bsStyle="primary"
                    className="bg-wb-blue"
                    onClick={this.handleExportData}
                    disabled={false}
                >
                    <i className="far fa-cloud-download"></i>&nbsp;{this.context.intl.messages['common.export-data']}
                </Button>
            </span>;
        }

        return (
            <div>
                <h3>
                    <i className="fas fa-qrcode"></i>&nbsp;{this.context.intl.messages['asset.video.forensic-url.title']}
                    {exportDataButton}
                </h3>
                <div className="tab-pane">
                    <hr/>
                    <Table forensicUrls={this.state.forensicUrls} />
                    <div className="row">
                        <div className="text-center bottom">
                            <Pagination
                                activePage={this.state.activePage}
                                onChange={this.handlePageChange}
                                totalPages={Math.ceil(this.state.total/ForensicConstants.PAGE_SIZE) || 0}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Container.create(Forensic);
export {
    Forensic as Forensic_BASE
};
