/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Promise from 'bluebird';
import {WebVTT} from 'vtt.js';

class VideoPlayerThumbnails {
    static GetTimeSeconds(e, seekBar, r, thumbnailsSprite) {
        let seekLeft = seekBar.getBoundingClientRect().left;
        let seekWidth = seekBar.offsetWidth;
        let mouseOffset = (e.pageX - seekLeft) / seekWidth;
        let timeSeconds = r.player.master.duration * mouseOffset;
        this.SetImageLeft(seekWidth, mouseOffset, thumbnailsSprite);
        return timeSeconds;
    }

    static GetVTTFile(vttFileURL) {
        return new Promise((onSuccess, onError) => {
            let request = new XMLHttpRequest();
            request.onload = () => {
                onSuccess(request.responseText);
            };
            request.onerror = () => {
                onError();
            };
            request.open('GET', vttFileURL, true);
            request.send();
        });
    }

    // Difficult to test due to jest environment not mocking document even though jsdom is configured
    /* istanbul ignore next */
    static InitializeSpritePlayer(r, spriteFileURL, cues) {
        let seekBar = document.querySelector('apc-controls').shadowRoot
            .querySelector('apc-control-footer').shadowRoot
            .querySelector('apc-timeline').shadowRoot
            .querySelector('apc-time-bar').shadowRoot
            .querySelector('.slider-container');
        let thumbnailsSprite = document.createElement('img');
        thumbnailsSprite.id = 'thumbnails-sprite';
        thumbnailsSprite.style.position = 'absolute';

        seekBar.appendChild(thumbnailsSprite);

        seekBar.addEventListener('mousemove', e => {
            let timeSeconds = this.GetTimeSeconds(e, seekBar, r, thumbnailsSprite);
            cues.some(cue => {
                if (cue.startTime <= timeSeconds && cue.endTime >= timeSeconds) {
                    let xywh = cue.text.split('=')[1].split(',');
                    thumbnailsSprite.style.background = `url(${spriteFileURL}) ${-xywh[0]}px ${-xywh[1]}px`;
                    thumbnailsSprite.style.width = `${xywh[2]}px`;
                    thumbnailsSprite.style.height = `${xywh[3]}px`;
                    // Move thumbnail slightly above the time tooltip.
                    thumbnailsSprite.style.top = `${-xywh[3] - 40}px`;
                    thumbnailsSprite.style.visibility = 'visible';

                    return true;
                }

                return false;
            });

            return;
        });

        seekBar.addEventListener('mouseout', () => {
            thumbnailsSprite.style.visibility = 'hidden';
            return;
        });

        return;
    }

    /* istanbul ignore next */
    static InitializeSpriteTimeline(r, spriteFileURL, cues) {
        let seekBar = document.querySelector('ap-timeline-basic').shadowRoot
            .querySelector('ap-timeline')
            .querySelector('ap-timeline-row[class="alternate-background timestamps overflow-hack-right"]')
            .shadowRoot.querySelector('div[class="row-content"]');
        let thumbnailsSprite = document.createElement('img');
        thumbnailsSprite.id = 'thumbnails-sprite';
        thumbnailsSprite.style.position = 'absolute';

        seekBar.appendChild(thumbnailsSprite);
        seekBar.addEventListener('mousemove', e => {
            let timeSeconds = this.GetTimeSeconds(e, seekBar, r, thumbnailsSprite);
            cues.some(cue => {
                if (cue.startTime <= timeSeconds && cue.endTime >= timeSeconds) {
                    let xywh = cue.text.split('=')[1].split(',');
                    thumbnailsSprite.style.background = `url(${spriteFileURL}) ${-xywh[0]}px ${-xywh[1]}px`;
                    thumbnailsSprite.style.width = `${xywh[2]}px`;
                    thumbnailsSprite.style.height = `${xywh[3]}px`;
                    // Move thumbnail slightly above the time tooltip.
                    thumbnailsSprite.style.top = `${-xywh[3] - 18}px`;
                    thumbnailsSprite.style.visibility = 'visible';

                    return true;
                }

                return false;
            });
        });
        seekBar.addEventListener('mouseout', () => {
            thumbnailsSprite.style.visibility = 'hidden';
        });
    }

    // Unable to test properly due to promise
    /* istanbul ignore next */
    static ParseThumbnailTrack(vttData, asset) {
        return new Promise((resolve, reject) => {
            const vttParser = new WebVTT.Parser(window, WebVTT.StringDecoder());
            const cues = [];
            vttParser.oncue = cue => {
                if (/#xywh=/.test(cue.text)) {
                    cues.push(cue);
                }
            };

            vttParser.onflush = () => resolve(cues);
            vttParser.onparsingerror = () => reject(Error(`Failed to parse spriteThumbVTTUrl [${asset.spriteThumbVTTUrl}] for video [${asset.id}].`));

            vttParser.parse(vttData.toString());
            vttParser.flush();
            return;
        });
    }

    static SetImageLeft(seekWidth, mouseOffset, thumbnailsSprite) {
        let imgLeft = (seekWidth * mouseOffset) - (thumbnailsSprite.width / 2);
        if (imgLeft < 0) {
            imgLeft = 0;
        } else {
            if (imgLeft + thumbnailsSprite.width > seekWidth) {
                imgLeft = seekWidth - thumbnailsSprite.width;
            }
        }
        thumbnailsSprite.style.left = `${imgLeft}px`;
        return;
    }

    static LoadTimelinePreviewThumbs(r, asset, isTimeline) {
        if (!asset.spriteThumbVTTUrl) {
            return;
        }

        this.GetVTTFile(asset.spriteThumbVTTUrl).then(vttData => {
            return this.ParseThumbnailTrack(vttData.toString(), asset);
        }).then(cues => {
            if (isTimeline) {
                this.InitializeSpriteTimeline(r, asset.spriteThumbJPEGUrl, cues);
            } else {
                this.InitializeSpritePlayer(r, asset.spriteThumbJPEGUrl, cues);
            }
        }).catch(e => {
            throw e;
        });
    }
}

export default VideoPlayerThumbnails;
