/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */


import Promise from 'bluebird';
import Immutable from 'immutable';
import Moment from 'moment';

import {AccountWizardConstants} from '../accounts/account-wizard/account-wizard-actions';
import {AssetConstants} from '../assets/asset-actions';
import config from '../config/config.js';
import Dispatcher from '../dispatcher/dispatcher';
import Request from '../request';
import {RouterActions} from '../router/router-actions';
import {ActionHistoryActions, ActionHistoryConstants} from '../system/action-history/action-history-actions';
import {TitleActions, TitleConstants} from '../titles/title-actions';

import {DownloadActions} from '~/src/common/download/download-actions';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import {UploadFile, GetEncodedHTML} from '~/src/common/utils/utils';

// Require for Proper Timezone Display
require('moment-timezone');
const configtz = Moment().tz(config.DefaultTimezone).format('ZZ');

const CONSTANTS = {
    ACCOUNT_TYPES: {
        TOKENIZED_ANONYMOUS: {
            id: 'TOKENIZED_ANONYMOUS',
            message: 'events.summary.user-type.option.tokenized-anonymous'
        },
        TOKENIZED_CHILD: {
            id: 'TOKENIZED_CHILD',
            message: 'events.summary.user-type.option.tokenized-child'
        },
        TRADITIONAL_LOGIN: {
            id: 'TRADITIONAL_LOGIN',
            message: 'events.summary.user-type.option.traditional-login'
        }
    },
    ARTWORK_ORIENTATION_TYPES: {
        PORTRAIT: {id: 'PORTRAIT', name: 'Portrait'},
        LANDSCAPE: {id: 'LANDSCAPE', name: 'Landscape'},
        RESPONSIVE: {id: 'RESPONSIVE', name: 'Responsive'},
    },
    ASSETS: {
        PAGE_SIZE: 20,
        GET_BATCH: {
            START: 'event_actions.assets.get_page_of_assets.start',
            ERROR: 'event_actions.assets.get_page_of_assets.error',
            SUCCESS: 'event_actions.assets.get_page_of_assets.success'
        },
        ADD_CATALOG_DATA: {
            START: 'event_actions.assets.add_assets_and_titles.start',
            ERROR: 'event_actions.assets.add_assets_and_titles.error',
            SUCCESS: 'event_actions.assets.add_assets_and_titles.success'
        },
    },
    CLEAR: 'event_actions.clear',
    CREATED_BY_OPTIONS: {
        GET: {
            START: 'event_actions.created_by_options.get.start',
            ERROR: 'event_actions.created_by_options.get.error',
            SUCCESS: 'event_actions.created_by_options.get.success'
        }
    },
    EVENT: {
        ADD_ITEMS: 'event_actions.event.add_items',
        DUPLICATE: {
            ERROR: 'event_actions.event.duplicate.error',
            START: 'event_actions.event.duplicate.start',
            SUCCESS: 'event_actions.event.duplicate.success'
        },
        EXPIRE_NOW: {
            ERROR: 'event_actions.event.expire_now.error',
            START: 'event_actions.event.expire_now.start',
            SUCCESS: 'event_actions.event.expire_now.success'
        },
        FIND_BY_ID: {
            ERROR: 'event_actions.event.find_by_id.error',
            START: 'event_actions.event.find_by_id.start',
            SUCCESS: 'event_actions.event.find_by_id.success'
        },
        ITEM_TYPES: {
            ASSET: {
                idProp: 'assetId',
                name: 'assets',
            },
            TITLE: {
                idProp: 'titleId',
                name: 'titles',
            },
            USER: {
                idProp: 'userId',
                name: 'users',
            },
        },
        NOTIFICATION: {
            IMAGE: {
                ADD_ERROR: 'event_actions.event.notification.image.add.error',
                DELETE_ERROR: 'event_actions.event.notification.image.delete.error',
                START: 'event_actions.event.notification.image.start',
                SUCCESS: 'event_actions.event.notification.image.success',
            },
            EMAIL: {
                ERROR: 'event_actions.event.notification.email.error',
                START: 'event_actions.event.notification.email.start',
                SUCCESS: 'event_actions.event.notification.email.success',
                LAST_EVENT_EMAIL_SENT: 'event_actions.event.notification.last_event_email_sent',
            }
        },
        REMOVE_ITEMS: 'event_actions.event.remove_items',
        RESTART_NOW: {
            ERROR: 'event_actions.event.restart_now.error',
            START: 'event_actions.event.restart_now.start',
            SUCCESS: 'event_actions.event.restart_now.success'
        },
        SAVE: {
            ERROR: 'event_actions.event.save.error',
            START: 'event_actions.event.save.start',
            SUCCESS: 'event_actions.event.save.success'
        },
        SEND_PREVIEW_EMAILS: {
            ERROR: 'event_actions.event.send_preview_emails.error',
            START: 'event_actions.event.send_preview_emails.start',
            SUCCESS: 'event_actions.event.send_preview_emails.success'
        },
        SET: {
            PARTNERS: 'event_actions.event.set.partners',
            PERMISSION_PACKAGES: 'event_actions.event.set.permission_packages'
        },
        START_NOW: {
            ERROR: 'event_actions.event.start_now.error',
            START: 'event_actions.event.start_now.start',
            SUCCESS: 'event_actions.event.start_now.success'
        },
        TITLES: {
            ADD: 'event_actions.event.titles.add',
            ADD_TO_DISPLAY_TITLES: 'event_actions.event.titles.add_to_display_titles',
            CLEAR: 'event_actions.event.titles.clear',
            GET_BATCH: {
                ERROR: 'event_actions.event.titles.get_batch.error',
                START: 'event_actions.event.titles.get_batch.start',
                SUCCESS: 'event_actions.event.titles.get_batch.success'
            },
            GET_PARENT_INFO: {
                ERROR: 'event_actions.event.titles.get_parent_info.error',
                START: 'event_actions.event.titles.get_parent_info.start',
                SUCCESS: 'event_actions.event.titles.get_parent_info.success'
            },
            GET_PRESENTATION: {
                ERROR: 'event_actions.event.titles.get_presentation.error',
                START: 'event_actions.event.titles.get_presentation.start',
                SUCCESS: 'event_actions.event.titles.get_presentation.success'
            },
            MERGE_LIST: 'event_actions.event.titles.merge_list',
            PAGE_SIZE: 20,
            REMOVE: 'event_actions.event.titles.remove',
            REORDER: 'event_actions.event.titles.reorder',
            SET_SYNOPSIS_TYPE: 'event_actions.event.titles.set_synopsis_type'
        },
        UPDATE: 'event_actions.event.update',
        UPDATE_NOTIFICATION: 'event_actions.event.update_notification',
        USERS: {
            ADD: 'event_actions.event.users.add',
            BULK_ADD: {
                START: 'event_actions.event.users.bulk_add.start',
                ERROR: 'event_actions.event.users.bulk_add.error',
                SUCCESS: 'event_actions.event.users.bulk_add.success',
            },
            CLEAR: 'event_actions.event.users.clear',
            GET: {
                START: 'event_actions.event.users.get.start',
                ERROR: 'event_actions.event.users.get.error',
                SUCCESS: 'event_actions.event.users.get.success'
            },
            GET_ALL_USERS: 'event_actions.event.users.get_all_users',
            IMPORT_TOKENS: {
                START: 'event_actions.event.users.import_tokens.start',
                ERROR: 'event_actions.event.users.import_tokens.error',
                SUCCESS: 'event_actions.event.users.import_tokens.success',
            },
            ACCOUNT_WIZARD: {
                START: 'event_actions.event.users.mass_add.start',
                ERROR: 'event_actions.event.users.mass_add.error',
                SUCCESS: 'event_actions.event.users.mass_add.success',
            },
            MASS_UPDATE: {
                START: 'event_actions.event.users.mass_update.start',
                ERROR: 'event_actions.event.users.mass_update.error',
                SUCCESS: 'event_actions.event.users.mass_update.success',
            },
            MERGE_LIST: 'event_actions.event.users.merge_list',
            PAGE_SIZE: 20,
            REMOVE_FROM_LIST: 'event_actions.event.users.remove',
            REMOVE: {
                START: 'event_actions.event.users.remove.start',
                ERROR: 'event_actions.event.users.remove.error',
                SUCCESS: 'event_actions.event.users.remove.success',
            },
            SAVE: {
                START: 'event_actions.event.users.save.start',
                ERROR: 'event_actions.event.users.save.error',
                SUCCESS: 'event_actions.event.users.save.success',
            },
            SET_FROM_SPREADSHEET: {
                ERROR: 'event_actions.events.users.set_from_spreadsheet.error',
                START: 'event_actions.events.users.set_from_spreadsheet.start',
                SUCCESS: 'event_actions.events.users.set_from_spreadsheet.success',
            }
        },
    },
    EVENT_TYPES: {
        BAFTA_EVENT: {
            id: 'BAFTA_EVENT',
            message: 'events.summary.event-type.option.bafta-event'
        },
        FYC_SCREENING: {
            id: 'FYC_SCREENING',
            message: 'events.summary.event-type.option.fyc-screening'
        },
        PRESS_SCREENING: {
            id: 'PRESS_SITE_SCREENING',
            message: 'events.summary.event-type.option.press-site-screening'
        },
        SCREENING_PRESENTATION: {
            id: 'SCREENING_PRESENTATION', // FIXME: this is temporary and not yet defined in EventType.java
            message: 'events.summary.event-type.option.screening-presentation'
        },
        SINGLE_SCREENER: {
            id: 'SINGLE_SCREENER', // FIXME: this is temporary and not yet defined in EventType.java
            message: 'events.summary.event-type.option.single-screener'
        },
        FYC_ADMIN_EVENT: {
            id: 'FYC_ADMIN_EVENT',
            message: 'events.summary.event-type.option.fyc-admin-event'
        }
    },
    EVENT_USER_NOTIFICATION_TYPES: {
        NO_NOTIFICATION: {
            id: 'NO_NOTIFICATION',
            message: 'events.notifications.options.do-not-send',
        },
        STANDARD_NOTIFICATION: {
            id: 'STANDARD_NOTIFICATION',
            message: 'events.notifications.options.send-standard',
        },
        CUSTOM_NOTIFICATION: {
            id: 'CUSTOM_NOTIFICATION',
            message: 'events.notifications.options.send-custom',
        }
    },
    FILTER: {
        SET: 'event_actions.filter.set',
    },
    GET: {
        ERROR: 'event_actions.get.error',
        START: 'event_actions.get.start',
        SUCCESS: 'event_actions.get.success'
    },
    GET_FYC_ADMIN_EVENTS: {
        ERROR: 'event_actions.get_fyc_admin_events.error',
        START: 'event_actions.get_fyc_admin_events.start',
        SUCCESS: 'event_actions.get_fyc_admin_events.success'
    },
    GET_FYC_EVENTS: {
        ERROR: 'event_actions.get_fyc_events.error',
        START: 'event_actions.get_fyc_events.start',
        SUCCESS: 'event_actions.get_fyc_events.success'
    },
    STATUS_TYPES: {
        BEFORE_EVENT: {
            description: 'events.browse.status-type.description.pending',
            icon: 'fas fa-ellipsis-h text-gray',
            id: 'BEFORE_EVENT'
        },
        EVENT_ENDED: {
            description: 'events.browse.status-type.description.ended',
            icon: 'fas fa-ellipsis-h text-red',
            id: 'EVENT_ENDED'
        },
        EVENT_RUNNING: {
            description: 'events.browse.status-type.description.active',
            icon: 'fas fa-play text-green',
            id: 'EVENT_RUNNING'
        },
        EVENT_STARTING: {
            description: 'events.browse.status-type.description.starting',
            icon: 'fas fa-ellipsis-h text-gray',
            id: 'EVENT_STARTING'
        },
        EVENT_STOPPING: {
            description: 'events.browse.status-type.description.stopping',
            icon: 'fas fa-ellipsis text-gray',
            id: 'EVENT_STOPPING'
        },
        FAILED_TO_START: {
            description: 'events.browse.status-type.description.failed-to-start',
            icon: 'fa-solid fa-circle-exclamation text-red',
            id: 'FAILED_TO_START'
        },
        MANUALLY_EXPIRING: {
            description: 'events.browse.status-type.description.manually-expiring',
            icon: 'fa-solid fa-xmark text-red',
            id: 'MANUALLY_EXPIRING'
        }
    },
    TITLE_PRESENTATION: {
        CLEAR: 'event_actions.title_presentation.clear',
        GET: {
            ERROR: 'event_actions.title_presentation.get.error',
            START: 'event_actions.title_presentation.get.start',
            SUCCESS: 'event_actions.title_presentation.get.success'
        },
        SAVE: {
            ERROR: 'event_actions.title_presentation.save.error',
            START: 'event_actions.title_presentation.save.start',
            SUCCESS: 'event_actions.title_presentation.save.success'
        },
        MODIFY_OPTIONS: {
            INHERIT: 'inherit',
            CUSTOM: 'custom'
        },
        NOMINATION_INFO: {
            ADD: 'event_actions.title_presentation.nomination_info.add',
            CLEAR_EDITING: 'event_actions.title_presentation.nomination_info.clear_editing',
            GET: {
                SUCCESS: 'event_actions.title_presentation.nomination_info.get.success'
            },
            REMOVE: 'event_actions.title_presentation.nomination_info.remove',
            REORDER: 'event_actions.title_presentation.nomination_info.reorder',
            UPDATE: 'event_actions.title_presentation.nomination_info.update',
        },
        SET_MODIFY_OPTION: 'event_actions.title_presentation.set_modify_option',
        TITLE_STYLE: {
            GET: {
                SUCCESS: 'event_actions.title_presentation.title_style.get.success',
            },
            UPDATE: 'event_actions.title_presentation.title_style.update',
            UPDATE_FILES: 'event_actions.title_presentation.title_style.update_files',
        },
    },
    NOMINATION_TYPES: {
        HEADER: {id: 'Header', label: 'Header', name: 'Header'},
        SPACER: {id: 'Spacer', label: 'Spacer', name: 'Spacer'},
        SUBHEADER: {id: 'Sub-Header', label: 'Sub-header', name: 'Sub-header'},
        UNSTYLED: {id: 'Unstyled', label: 'Unstyled', name: 'Unstyled'},
    },
    toArray: function(constant) {
        return Object.keys(this[constant])
            .map(k => this[constant][k])
            .sort((a, b) =>
                a.name.localeCompare(b.name)
            );
    }
};

class EventActions {
    addItems(eventId, type, items) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.ADD_ITEMS,
            eventId,
            type,
            items
        });
    }

    addNewNomination(columnNumber) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE_PRESENTATION.NOMINATION_INFO.ADD,
            columnNumber
        });
    }

    addTitleToList(title) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.TITLES.ADD,
            title
        });
    }

    addTitlesToList() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.TITLES.MERGE_LIST
        });
    }

    addToDisplayTitles(titleId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.TITLES.ADD_TO_DISPLAY_TITLES,
            titleId
        });
    }

    addUserToList(user) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.USERS.ADD,
            user
        });
    }

    /**
     * Add and save users to event
     * @param {*} eventId
     * @param {*} usersList
     */
    addUsersToEvent(eventId, usersToAdd, allUsers) {
        usersToAdd = usersToAdd.toJS();
        const filteredUsers = usersToAdd
            .filter(user => allUsers.findIndex(eventUser => eventUser.get('userId') === user.id) === -1);

        if (!filteredUsers.length) {return;}

        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.USERS.SAVE.START,
        });

        const addUsers = filteredUsers.map(user => {
            let url = `event/${eventId}/user`;

            return Request.post(url)
                .send({...user, userId: user.id}).exec()
                .then(() => true)
                .catch((err) => {
                    console.error(err);
                    return false;
                });
        });

        Promise.all(addUsers).then((results) => {
            const numUsersAdded = results.filter(r => r).length;

            if (numUsersAdded === filteredUsers.length) {
                NotificationActions.showAlertSuccess('events.users.adding-success', numUsersAdded, filteredUsers.length);
            } else if (numUsersAdded > 0) {
                NotificationActions.showAlertWarning('events.users.adding-success', numUsersAdded, filteredUsers.length);
            } else {
                NotificationActions.showAlertDanger('events.users.adding-error');
                Dispatcher.dispatch({
                    actionType: CONSTANTS.EVENT.USERS.SAVE.ERROR
                });
            }

            if (numUsersAdded > 0) {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.EVENT.USERS.SAVE.SUCCESS,
                    hitsAdded: 0,
                    totalHitsMisses: 0
                });

                this.getFilteredEventUsers(eventId, Immutable.fromJS({}));
            }
        });
    }

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    clearAddedTitles() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.TITLES.CLEAR
        });
    }

    clearEditingNomination() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE_PRESENTATION.NOMINATION_INFO.CLEAR_EDITING
        });
    }

    clearTitlePresentationOptions() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE_PRESENTATION.CLEAR
        });
    }

    createUpdateUsersBatch(eventId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.USERS.MASS_UPDATE.START
        });
        Request.post(`event/${eventId}/mass-update-removed-user`).exec().then((res) => {
            const {id: batchId} = res.body;
            window.open(`/accounts/mass-update/${batchId}`, '_blank');
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.USERS.MASS_UPDATE.SUCCESS
            });
        }).catch(err => {
            NotificationActions.showAlertDanger('events.mass-update-users.error');
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.USERS.MASS_UPDATE.ERROR
            });
            throw err;
        });
    }

    downloadAllUsers(eventId, newTab = true, redirectRoute = 'groups/user-groups') {
        Request.get('event/users/export').query({
            'event-id': eventId
        }).exec().then(res => {
            DownloadActions.resolveDownloadExecutionFor(res, `/${redirectRoute}/download-users/${res.body.downloadExecutionId}`, newTab);
        }).catch(err => {
            console.error(err);
            NotificationActions.showAlertDanger('events.users.export.error');
            throw err;
        });
    }

    downloadOTLRequests(eventId, newTab = true, redirectRoute = 'groups/user-groups') {
        Request.get(`event/${eventId}/otl-download`).exec().then(res => {
            DownloadActions.resolveDownloadExecutionFor(res, `/${redirectRoute}/download-users/${res.body.downloadExecutionId}`, newTab);
        }).catch(err => {
            console.error(err);
            NotificationActions.showAlertDanger('events.otl.export.error');
            throw err;
        });
    }

    downloadUserViews(eventId, includeCosts = false, newTab = true, redirectRoute = 'groups/user-groups') {
        Request.get('event/views/export').query({
            'event-id': eventId,
            'include-costs': includeCosts
        }).exec().then(res => {
            DownloadActions.resolveDownloadExecutionFor(res, `/${redirectRoute}/download-users/${res.body.downloadExecutionId}`, newTab);
        }).catch(err => {
            console.error(err);
            NotificationActions.showAlertDanger('events.views.export.error');
            throw err;
        });
    }

    duplicateEventPresentationTitlesData(originalEvent, targetEvent) {
        const originalTitles = originalEvent.titles;
        const targetTitles = targetEvent.titles;
        if (targetTitles.length === 0) {return;}
        const copyPresentationTitlesData = targetTitles.reduce((requests, targetTitle) => {
            const originalTitle = originalTitles.find(t => t.titleId === targetTitle.titleId);
            // copy title style data for each title
            requests.push(
                Request.get(`event/title/${originalTitle.eventTitleId}/style`).exec().then(response => {
                    const originalTitleStyle = response.body;
                    return Request.put(`event/title/${targetTitle.eventTitleId}/style`).send({
                        appBackgroundColor : originalTitleStyle.appBackgroundColor,
                        appTextColor : originalTitleStyle.appTextColor,
                        synopsis : originalTitleStyle.synopsis,
                    }).catch((err) => {
                        NotificationActions.showAlertDanger('events.presentation.save-presentation-title.error');
                        throw err;
                    });
                }).catch((err) => {
                    // if title style is not found then nothing to copy
                    if (err.status === 404) {
                        return;
                    }
                    NotificationActions.showAlertDanger('events.presentation.get-presentation-title.error');
                    throw err;
                })
            );

            // copy nomination data for each title
            requests.push(
                Request.get(`event/title/${originalTitle.eventTitleId}/nomination`).exec().then(response => {
                    const originalNominationInfo = response.body;
                    const nominationInfoToSave = originalNominationInfo.map(nominationItem => ({
                        columnNumber: nominationItem.columnNumber,
                        displayOrder: nominationItem.displayOrder,
                        displayType: nominationItem.displayType,
                        displayValue: nominationItem.displayValue
                    }));
                    return Request.put(`event/title/${targetTitle.eventTitleId}/nomination`).send(nominationInfoToSave).catch((err) => {
                        NotificationActions.showAlertDanger('events.presentation.nomination-info.save.error');
                        throw err;
                    });
                }).catch((err) => {
                    NotificationActions.showAlertDanger('events.presentation.nomination-info.save.error');
                    throw err;
                })
            );
            return requests;
        }, []);
        return Promise.all(copyPresentationTitlesData);
    }

    duplicateEventUsers(originalEventId, targetEventId) {
        const queryParams = {
            offset: 0,
            size: 99999
        };

        Request.get(`event/${originalEventId}/user`).query(queryParams).exec().then(response => {
            const {results} = response.body;

            const addUsers = results.map(user =>
                Request.post(`event/${targetEventId}/user`).send({userId: user.userId}).exec(),
            );

            return Promise.all(addUsers).then(() => {
                this.getFilteredEventUsers(targetEventId, Immutable.fromJS({size: 20}));
            });
        }).catch(err => {
            NotificationActions.showAlertDanger('events.users.get-error');
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.USERS.GET.ERROR
            });
            throw err;
        });
    }

    bulkAddUsers(event, count, asAdmin) {
        const eventId = event.get('eventId');

        let data = {
            isAdmin: asAdmin,
            numberOfAccounts: count,
        };

        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.USERS.BULK_ADD.START,
        });

        Request.post(`event/${eventId}/create-anonymous-account`).send(data).exec()
            .then(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.EVENT.USERS.BULK_ADD.SUCCESS
                });
                this.getFilteredEventUsers(eventId, Immutable.fromJS({}));
                NotificationActions.showAlertSuccess('events.users.bulk_add.success');
            }).catch((error) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.EVENT.USERS.BULK_ADD.ERROR
                });
                NotificationActions.showAlertDanger('events.users.bulk_add.error');
                throw error;
            });
    }

    clearAddedUsers() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.USERS.CLEAR
        });
    }

    copyAssetsAndTitlesFromAssetCatalog(eventId, catalogs) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.ASSETS.ADD_CATALOG_DATA.START,
        });
        let catalogsRequests = catalogs.map(catalog => (
            Request.get('asset').query({
                'catalog-id': catalog.get('id'),
                'active-type': 'BOTH',
                'operator': 'AND',
                'asset-type': AssetConstants.ASSET_TYPES.VIDEO.id // for now ask just videos
            }).exec()
        ));

        Promise.all(catalogsRequests)
            .then(catalogsResponse => {
                let allAssets = {};
                catalogsResponse.forEach(response => {
                    // Just filter video assets for now
                    const videoAssets = response.body.results.filter(
                        asset => asset.assetType === AssetConstants.ASSET_TYPES.VIDEO.id
                    );

                    videoAssets.forEach(asset => (allAssets[asset.id] = asset)); // add to all assets list

                    return;
                });

                allAssets = Object.keys(allAssets).map(key => allAssets[key]);
                this.addItems(eventId, CONSTANTS.EVENT.ITEM_TYPES.ASSET, Immutable.fromJS(allAssets));
                this.copyTitlesFromAssets(eventId, allAssets);

                Dispatcher.dispatch({
                    actionType: CONSTANTS.ASSETS.ADD_CATALOG_DATA.SUCCESS,
                });
            })
            .catch(err => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.ASSETS.ADD_CATALOG_DATA.ERROR,
                });
                NotificationActions.showAlertDanger('events.assets.copy-catalog.error');
                throw err;
            });
    }

    copyTitlesFromAssets(eventId, assets = []) {
        let allTitles = {};
        // Get titles associated to assets and add to all titles list
        assets.forEach(video => {
            video.browseTitleList?.forEach(title => {
                title.titleHierarchyPermissionList?.forEach(titleHierarchy => {
                    ['rootTitleId', 'childOneTitleId', 'childTwoTitleId', 'childThreeTitleId'].forEach(child => {
                        if (titleHierarchy[child]) {
                            allTitles[titleHierarchy[child]] = titleHierarchy[child];
                        }
                    });
                });
            });
        });
        allTitles = Object.keys(allTitles).map(key => ({id: allTitles[key]}));
        this.addItems(eventId, CONSTANTS.EVENT.ITEM_TYPES.TITLE, Immutable.fromJS(allTitles));
    }

    copyUsersFromGroups(groups, eventId, allUsers, offset=0, size=999999, sortFieldName='name', sortDirection='asc') {
        groups.forEach(g => {
            Request.get(`security/group/${g.get('id')}/user`).query({
                offset,
                size,
                'sort-field': sortFieldName,
                'sort-direction': sortDirection
            }).exec().then(res => {
                const usersToAdd = Immutable.fromJS(res.body.results);
                this.addUsersToEvent(eventId, usersToAdd, allUsers);
                return;
            }).catch(err => {
                throw err;
            });
        });
    }

    expireEvent(eventId, expireNowNote) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.EXPIRE_NOW.START,
        });

        Request.del(`event/${eventId}/running`).exec().then((res) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.EXPIRE_NOW.SUCCESS,
                event: Immutable.fromJS(res.body),
            });
            NotificationActions.showAlertSuccess('events.expire.success');
        }).then(() => {
            if (expireNowNote) {
                ActionHistoryActions.addNote(eventId, expireNowNote, ActionHistoryConstants.ACTION_OBJECTS.EVENT);
            }
        }).catch((error) => {
            switch (error.status) {
            case 409:
                // Notify the user that the event is locked
                Dispatcher.dispatch({
                    actionType: CONSTANTS.EVENT.EXPIRE_NOW.ERROR,
                });
                NotificationActions.showAlertDanger('events.event-locked-error');
                break;
            default:
                Dispatcher.dispatch({
                    actionType: CONSTANTS.EVENT.EXPIRE_NOW.ERROR,
                });
                NotificationActions.showAlertDanger('events.expire.error');
                break;
            }

            throw error;
        });
    }

    findById(id, isDuplicateMode) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.FIND_BY_ID.START
        });

        let actionHistoryQuery = {
            'action-object': ActionHistoryConstants.ACTION_OBJECTS.EVENT,
            'object-id': id,
            offset: 0,
            size: 4
        };

        Promise.all([
            Request.get(`event/${id}`).exec(),
            Request.get('system/action-history').query(actionHistoryQuery).exec(),
            Request.get('security/role').query({offset: 0, size: 999999, 'count-users': false}).exec(),
        ]).spread((eventRes, history, rolesRes) => {
            const roles = Immutable.fromJS(rolesRes.body.results);

            let event = eventRes.body;
            if (roles.find(r => r.get('id') === eventRes.body.roleId)) {
                event.selectedRole = roles.find(r => r.get('id') === eventRes.body.roleId);
            }

            // Allow the 'Link to FYC Admin Event' checkbox on summary tab to show the proper state
            event.linkToFYCAdminEvent = !!event.rootEventId;

            if (isDuplicateMode) {
                // Duplication options
                if (event.accountType !== CONSTANTS.ACCOUNT_TYPES.TOKENIZED_ANONYMOUS.id) {
                    event.copyUsers = true;
                } else {
                    // BRAIN-3929: we don't want to duplicate anonymous users due to their temporary nature and expiration dates
                    event.copyUsers = false;
                }
                event.copyAssetsTitles = true;
                event.copyNotification = true;
                event.copyNotificationSettings = true;

                // Event field reinitialization
                event.eventStatusType = CONSTANTS.STATUS_TYPES.BEFORE_EVENT.id;
                event.parentEventId = event.eventId;
                event.parentEventName = event.name;
                event.name = `${event.name} (Copy)`;
                event.eventId = undefined;
                event.startScheduleDate = undefined;
                event.endScheduleDate = undefined;

                // Clear any fyc admin event linking. This prevents duplicating an invalid/old rootEventId.
                event.linkToFYCAdminEvent = false;
                event.rootEventId = null;
            }

            history = history.body.results;
            history.sort((h1, h2) => h2.actionDate.localeCompare(h1.actionDate));
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.FIND_BY_ID.SUCCESS,
                event: Immutable.fromJS(event),
                history: Immutable.fromJS(history)
            });
        }).catch((error) => {
            console.error(error);
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.FIND_BY_ID.ERROR,
                error
            });

            RouterActions.redirect('/404');
            throw error;
        });
    }

    get(queryParams) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET.START,
        });

        queryParams = queryParams.toJS();
        queryParams.offset = queryParams.offset || 0;
        queryParams.size = queryParams.size || 20;

        ['begin-from-date', 'end-from-date'].forEach( attr => {
            let d = queryParams[attr];
            if (d) {
                d = Moment(d);
                if (d.isValid()) {
                    d = d.format('YYYY-MM-DDT00:00:00.000'+configtz);
                } else {
                    d = '';
                }
                queryParams[attr] = d;
            }
        });

        ['begin-to-date', 'end-to-date'].forEach( attr => {
            let d = queryParams[attr];
            if (d) {
                d = Moment(d);
                if (d.isValid()) {
                    d = d.format('YYYY-MM-DDT23:59:59.999'+configtz);
                } else {
                    d = '';
                }
                queryParams[attr] = d;
            }
        });

        Request.get('event').query(queryParams).exec()
            .then((eventsRes) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.SUCCESS,
                    events: Immutable.fromJS(eventsRes.body.results),
                    offset: eventsRes.body.offset,
                    size: eventsRes.body.size,
                    total: eventsRes.body.totalCount,
                });
            }).catch((error) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.ERROR,
                    error
                });

                NotificationActions.showAlertDanger('events.get.error');
                throw error;
            });
    }

    getAllUsersInEvent(eventId) {
        setTimeout(() => {//double dispatch
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.USERS.GET.START
            });
        }, 10);

        Request.get(`event/${eventId}/user`).query({size: 99999, offset: 0}).exec().then(response => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.USERS.GET_ALL_USERS,
                allUsers: Immutable.fromJS(response.body.results),
            });
        }).catch(err => {
            NotificationActions.showAlertDanger('events.users.get-error');
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.USERS.GET.ERROR
            });
            throw err;
        });

        return;
    }

    getAssetsBatch(pageNumber, assets) {
        const pageSize = CONSTANTS.ASSETS.PAGE_SIZE;

        //Slicing array to show the required assets
        const videoIds = assets
            .slice(pageNumber * pageSize, (pageNumber * pageSize) + pageSize)
            .map(asset => asset.get('assetId')).toJS();

        if (videoIds.length) {
            setTimeout(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.ASSETS.GET_BATCH.START,
                });
                Promise.all([
                    Request.get('asset/video').query({'video-id': videoIds}).exec(),
                    Request.get('asset/video/thumbnailURL').query({'video-id': videoIds}).exec()
                ]).spread((videosResponse, thumbnailsResponse) => {
                    const thumbnails = thumbnailsResponse.body.reduce((map, thumbnail) => {
                        map[thumbnail.videoId] = thumbnail.thumbnailList;
                        return map;
                    }, {});
                    const assetsToDisplay = videosResponse.body.map(video => {
                        video.thumbnails = thumbnails[video.id];
                        video.assetType = AssetConstants.ASSET_TYPES.VIDEO.id;
                        return video;
                    });
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.ASSETS.GET_BATCH.SUCCESS,
                        assetsToDisplay: Immutable.fromJS(assetsToDisplay)
                    });
                }).catch(err => {
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.ASSETS.GET_BATCH.ERROR
                    });
                    NotificationActions.showAlertDanger('events.assets.data.get.error');
                    throw err;
                });
            }, 10);
        } else {
            Dispatcher.dispatch({
                actionType: CONSTANTS.ASSETS.GET_BATCH.SUCCESS,
                assetsToDisplay: Immutable.fromJS([])
            });
        }
    }

    getChangeDateDayUtc(attr, day, displayTimeZone) {
        let notIsoDate = Moment.tz(day, displayTimeZone).set({hour: 0, minute: 0});

        if (attr === 'endScheduleDate') {
            notIsoDate = Moment.tz(day, displayTimeZone).set({hour: 23, minute: 30});
        }

        return notIsoDate.utc().format();
    }

    getChangeDateTimeUtc(value, hour, displayTimeZone) {
        const notIsoDate = Moment.tz(value, displayTimeZone);
        const hours = Moment(hour, 'hh:mm a');

        notIsoDate.set({hour: hours.hour(), minute: hours.minute()});

        return notIsoDate.utc().format();
    }

    getCreatedByOptions() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CREATED_BY_OPTIONS.GET.START,
        });

        Request.get('event/created-by?size=99999&offset=0')
            .exec()
            .then(res => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.CREATED_BY_OPTIONS.GET.SUCCESS,
                    options: Immutable.fromJS(res.body.results.sort((a, b) => a.localeCompare(b)).map(c => ({id: c, name: c})))
                });
            }).catch((error) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.CREATED_BY_OPTIONS.GET.ERROR,
                    error
                });

                NotificationActions.showAlertDanger('events.created-by.get.error');
                throw error;
            });
    }

    getFYCAdminEvents() {
        // Load all active fyc admin events
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET_FYC_ADMIN_EVENTS.START,
        });

        const queryParams = {
            offset: 0,
            size: 9999,
            'event-type':CONSTANTS.EVENT_TYPES.FYC_ADMIN_EVENT.id,
            status: [
                CONSTANTS.STATUS_TYPES.BEFORE_EVENT.id,
                CONSTANTS.STATUS_TYPES.EVENT_STARTING.id,
                CONSTANTS.STATUS_TYPES.EVENT_RUNNING.id,
                CONSTANTS.STATUS_TYPES.EVENT_STOPPING.id
            ]
        };

        Request.get('event').query(queryParams).exec()
            .then((eventsRes) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET_FYC_ADMIN_EVENTS.SUCCESS,
                    events: Immutable.fromJS(eventsRes.body.results),
                });
            }).catch((error) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET_FYC_ADMIN_EVENTS.ERROR,
                    error
                });

                NotificationActions.showAlertDanger('events.get-fyc-admin-events.error');
                throw error;
            });
    }

    getFYCEventsByRootEventId(id, queryParams) {
        // Load all child fyc screening events for an fyc admin event
        Dispatcher.dispatch({
            actionType: CONSTANTS.GET_FYC_EVENTS.START,
        });

        queryParams = queryParams.toJS();
        queryParams.offset = queryParams.offset || 0;
        queryParams.size = queryParams.size || 20;
        queryParams['root-event-id'] = id;
        queryParams['event-type'] = CONSTANTS.EVENT_TYPES.FYC_SCREENING.id;

        Request.get('event').query(queryParams).exec()
            .then((eventsRes) => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET_FYC_EVENTS.SUCCESS,
                    events: Immutable.fromJS(eventsRes.body.results),
                    offset: eventsRes.body.offset,
                    size: eventsRes.body.size,
                    total: eventsRes.body.totalCount,
                });
            }).catch((error) => {
                console.error(error);
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET_FYC_EVENTS.ERROR,
                    error
                });

                NotificationActions.showAlertDanger('events.get-fyc-events.error');
                throw error;
            });
    }

    getTitleStyleModifyOption(eventTitleStyle) {
        let modifyOption = CONSTANTS.TITLE_PRESENTATION.MODIFY_OPTIONS.INHERIT;
        if (
            eventTitleStyle.appBackgroundColor || eventTitleStyle.appTextColor ||
            eventTitleStyle.appBackgroundImageS3Path || eventTitleStyle.appBackgroundImageUrl ||
            eventTitleStyle.appBackgroundTitleTreatmentS3Path || eventTitleStyle.appBackgroundTitleTreatmentUrl ||
            eventTitleStyle.appBackgroundVideoS3Path || eventTitleStyle.appBackgroundVideoUrl
        ) {
            modifyOption = CONSTANTS.TITLE_PRESENTATION.MODIFY_OPTIONS.CUSTOM;
        }
        return modifyOption;
    }

    getTitleThumbnailModifyOption(titleStyle) {
        let modifyOption = CONSTANTS.TITLE_PRESENTATION.MODIFY_OPTIONS.INHERIT;
        if (titleStyle.thumbnailImageS3Path || titleStyle.thumbnailImageUrl) {
            modifyOption = CONSTANTS.TITLE_PRESENTATION.MODIFY_OPTIONS.CUSTOM;
        }
        return modifyOption;
    }

    getSynopsisStyleModifyOption(titleStyle) {
        let modifyOption = CONSTANTS.TITLE_PRESENTATION.MODIFY_OPTIONS.INHERIT;
        if (titleStyle.synopsis) {
            modifyOption = CONSTANTS.TITLE_PRESENTATION.MODIFY_OPTIONS.CUSTOM;
        }
        return modifyOption;
    }

    getTitlePresentationOptions(eventTitleId) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE_PRESENTATION.GET.START,
        });

        const getTitleStyle = this.getTitleStyle(eventTitleId);
        const getNominationData = this.getNominationData(eventTitleId);

        Promise.all([getTitleStyle, getNominationData]).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE_PRESENTATION.GET.SUCCESS,
            });
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE_PRESENTATION.GET.ERROR,
            });
            throw err;
        });
    }

    getTitleStyle(eventTitleId) {
        return Request.get(`event/title/${eventTitleId}/style`).exec().then(response => {
            const titleStyle = response.body;
            this.setModifyOption('selectedTitleStyleOption', this.getTitleStyleModifyOption(titleStyle));
            this.setModifyOption('selectedTitleThumbnailOption', this.getTitleThumbnailModifyOption(titleStyle));
            this.setModifyOption('selectedSynopsisOption', this.getSynopsisStyleModifyOption(titleStyle));
            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE_PRESENTATION.TITLE_STYLE.GET.SUCCESS,
                titleStyle: Immutable.fromJS(titleStyle)
            });
            return;
        }).catch((err) => {
            // if title style is not found do not show error, when fill information it will be created
            if (err.status === 404) {
                this.setModifyOption('selectedTitleStyleOption', CONSTANTS.TITLE_PRESENTATION.MODIFY_OPTIONS.INHERIT);
                this.setModifyOption('selectedTitleThumbnailOption', CONSTANTS.TITLE_PRESENTATION.MODIFY_OPTIONS.INHERIT);
                this.setModifyOption('selectedSynopsisOption', CONSTANTS.TITLE_PRESENTATION.MODIFY_OPTIONS.INHERIT);
                return;
            }
            NotificationActions.showAlertDanger('events.presentation.get-presentation-title.error');
            throw err;
        });
    }

    getNominationData(eventTitleId) {
        return Request.get(`event/title/${eventTitleId}/nomination`).exec().then(response => {
            const nominationInfo = response.body;
            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE_PRESENTATION.NOMINATION_INFO.GET.SUCCESS,
                nominationInfo: Immutable.fromJS({
                    '1': nominationInfo.filter(n => n.columnNumber === 1),
                    '2': nominationInfo.filter(n => n.columnNumber === 2),
                })
            });
            return;
        }).catch((err) => {
            NotificationActions.showAlertDanger('events.presentation.nomination-info.get.error');
            throw err;
        });
    }

    getScheduleDateUtc(prevDate, timeZone) {
        return Moment.tz(prevDate, timeZone).utc().format();
    }

    getParentTitle(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.TITLES.GET_PARENT_INFO.START,
        });

        Request.get(`title/${id}`).exec().then(titlesRes => {
            this.addTitleToList(titlesRes.body);
            if (!titlesRes.body.parentTitleId) {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.EVENT.TITLES.GET_PARENT_INFO.SUCCESS,
                    titles: Immutable.fromJS(titlesRes.body)
                });

                return;
            }

            this.getParentTitle(titlesRes.body.parentTitleId);
        });
    }

    /* istanbul ignore next */
    getPresentationTitles(eventTitles, allAssets) {
        if (!eventTitles.size) {
            return;
        }

        setTimeout(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.TITLES.GET_PRESENTATION.START,
            });
        }, 0);

        // Note: we need to call the title endpoint with single requests for each title to get the thumbnail urls (and more fields) back. When called
        // with the title-id array param, the api does not return the thumbnail urls!

        const sortByDisplayOrder = (a, b) => {
            const aDisplayOrder = a.get('displayOrder', 999);
            const bDisplayOrder = b.get('displayOrder', 999);

            if (aDisplayOrder > bDisplayOrder) {
                return 1;
            }

            if (aDisplayOrder < bDisplayOrder) {
                return -1;
            }

            return 0;
        };

        const titleRequests = eventTitles.sort(sortByDisplayOrder).map((title) => {
            const titleId = (title.get('titleId')) || title.get('id');
            return Request.get(`title/${titleId}`).exec();
        });

        const titleAssetsRequests = eventTitles.map((title) => {
            const titleId = (title.get('titleId')) || title.get('id');
            return Request.get(`title/${titleId}/asset-summary`).exec()
                .then(response => ({titleId, assets: response.body}));
        });

        const eventTitleNominationRequests = eventTitles.sort(sortByDisplayOrder).map((title) => {
            return Request.get(`event/title/${title.get('eventTitleId')}/nomination`).exec()
                .then(response => ({eventTitleId: title.get('eventTitleId'), nominationInfo: response.body}))
                .catch((err) => {
                    if (err?.response?.statusCode === 404) {
                        // Ignore 404 when nominations have not been saved/defined for this title
                        return {};
                    } else {
                        throw err;
                    }
                });
        });

        const eventTitleStyleRequests = eventTitles.sort(sortByDisplayOrder).map((title) => {
            return Request.get(`event/title/${title.get('eventTitleId')}/style`).exec()
                .then(response => ({eventTitleId: title.get('eventTitleId'), style: response.body}))
                .catch((err) => {
                    if (err?.response?.statusCode === 404) {
                        // Ignore 404 when styles have not been saved/defined for this title
                        return {};
                    } else {
                        throw err;
                    }
                });
        });

        let batchTitles, topLevelTitles;

        Promise.props({
            titleRequests: Promise.all(titleRequests),
            titleAssetsRequests: Promise.all(titleAssetsRequests),
            eventTitleNominationRequests: Promise.all(eventTitleNominationRequests),
            eventTitleStyleRequests: Promise.all(eventTitleStyleRequests)
        }).then(responses => {
            const {
                titleRequests: titleResponses,
                titleAssetsRequests: titleAssetsResponses,
                eventTitleNominationRequests: eventTitleNominationResponses,
                eventTitleStyleRequests: eventTitleStyleResponses,
            } = responses;

            batchTitles = titleResponses.map(tr => {
                const fullTitle = tr.body;
                const eventTitle = eventTitles.find(t => t.get('titleId') === fullTitle.id).toJS();
                const titleAssetsSummary = titleAssetsResponses.find(t => t.titleId === fullTitle.id)?.assets || [];
                const titleVideos = titleAssetsSummary.filter(asset => asset.assetType === AssetConstants.ASSET_MAIN_TYPES.VIDEO.toUpperCase());

                const eventTitleId = eventTitles.find(et => et.get('titleId') === fullTitle.id).get('eventTitleId');
                eventTitle.nominationInfo = eventTitleNominationResponses.find(t => t.eventTitleId === eventTitleId)?.nominationInfo || [];

                const eventTitleStyle = eventTitleStyleResponses.find(t => t.eventTitleId === eventTitleId)?.style || {};

                let assetsAdded = 0;
                if (titleVideos.length) {
                    assetsAdded = titleVideos.reduce((counter, video) => {
                        if (allAssets.findIndex(eventAsset => eventAsset.get('assetId') === video.assetId) !== -1) {
                            counter++;
                        }
                        return counter;
                    }, 0);
                }
                // count assets added to event for each title
                eventTitle.totalVideoAssets = assetsAdded;

                // Extend the event title object with fields from full title object
                [
                    'appBackgroundImageUrl',
                    'category',
                    'defaultImageHorizontalThumbnailUrl',
                    'defaultImagePortraitThumbnailUrl',
                    'name',
                ].forEach(f => {
                    eventTitle[f] = fullTitle[f];
                });

                // We need to set the overrides here because they are originally loaded by the event.titles response array
                // We can't reload the event request because we may have unsaved changes. So we manually override to match API logic:
                eventTitle.synopsisOverride = !!eventTitleStyle.synopsis;
                eventTitle.backgroundOverride = !!eventTitleStyle.appBackgroundColor || !!eventTitleStyle.appTextColor || !!eventTitleStyle.appBackgroundImageUrl || !!eventTitleStyle.appBackgroundVideoUrl || !!eventTitleStyle.appBackgroundTitleTreatmentUrl;
                eventTitle.thumbnailOverride = !!eventTitleStyle.thumbnailImageUrl;

                // Override the title's images with values from eventTitleSyle
                if (eventTitle.backgroundOverride && eventTitleStyle.appBackgroundImageUrl) {
                    eventTitle.appBackgroundImageUrl = eventTitleStyle.appBackgroundImageUrl;
                }

                if (eventTitle.thumbnailOverride && eventTitleStyle.thumbnailImageUrl) {
                    eventTitle.defaultImagePortraitThumbnailUrl = eventTitleStyle.thumbnailImageUrl;
                    eventTitle.defaultImageHorizontalThumbnailUrl = eventTitleStyle.thumbnailImageUrl;
                }

                // Extend the event title object with flag if it's a "top level" title for filtering the presentation titles table
                const titleCategoryGroup = TitleActions.getCategoryGroup(fullTitle.category);

                eventTitle.isTopLevel = [
                    TitleConstants.TITLE_CATEGORY_GROUPS.SERIES,
                    TitleConstants.TITLE_CATEGORY_GROUPS.MINI_SERIES,
                    TitleConstants.TITLE_CATEGORY_GROUPS.SINGLE_RELEASE].includes(titleCategoryGroup);

                return eventTitle;
            });

            topLevelTitles = batchTitles.filter(t => t.isTopLevel);

            // top level titles except titles with type SINGLE RELEASE
            const titlesWithNavigation = topLevelTitles.filter(t =>
                TitleActions.getCategoryGroup(t.category) !== TitleConstants.TITLE_CATEGORY_GROUPS.SINGLE_RELEASE
            );

            if (titlesWithNavigation.length) {
                return Promise.all(titlesWithNavigation.map(titleWithNavigation => {
                    const titleId = titleWithNavigation.titleId;
                    return Request.get(`title/${titleId}/season-episode-list`)
                        .query({active: false}).exec()
                        .then(responseSEL => ({titleId, response: responseSEL.body})).catch(err => {
                            // Title with parent inactive returns 404, do not count them
                            if (err?.response?.statusCode === 404) {
                                return {};
                            } else {
                                throw err;
                            }
                        });
                }));
            }
            return;

        }).then((titlesSeasonEpisodesResponse) => {
            if (titlesSeasonEpisodesResponse) {
                // count all assets from children
                topLevelTitles = topLevelTitles.map(title => {
                    let titlesSeasonEpisodesItem = titlesSeasonEpisodesResponse.find(t => t.titleId === title.titleId);
                    if (titlesSeasonEpisodesItem) {
                        titlesSeasonEpisodesItem = titlesSeasonEpisodesItem.response;

                        // add assets from seasons
                        const totalAssets = titlesSeasonEpisodesItem.seasons.reduce((totalSeasonAssets, season) => {
                            const currentEventTitleSeason = batchTitles.find(bt => bt.titleId === season.titleId);
                            if (currentEventTitleSeason) {
                                totalSeasonAssets += currentEventTitleSeason.totalVideoAssets;
                                // add assets from episodes
                                const episodesAssets = season.episodes.reduce((totalEpisodeAssets, episode) => {
                                    const currentEventTitleEpisode = batchTitles.find(bt => bt.titleId === episode.titleId);
                                    if (currentEventTitleEpisode) {
                                        totalEpisodeAssets += currentEventTitleEpisode.totalVideoAssets;
                                    }
                                    return totalEpisodeAssets;
                                }, 0);
                                totalSeasonAssets += episodesAssets;
                            }
                            return totalSeasonAssets;
                        }, 0);

                        title.totalVideoAssets = title.totalVideoAssets + totalAssets;
                    }
                    return title;
                });
            }

            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.TITLES.GET_PRESENTATION.SUCCESS,
                titles: topLevelTitles
            });
        }).catch((err) => {
            NotificationActions.showAlertDanger('events.presentation.get-presentation-titles.error');

            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.TITLES.GET_PRESENTATION.ERROR,
            });
            throw err;
        });
    }

    getTitlesBatch(pageNumber, titles) {
        if (titles.size) {
            let titlesToRequest = [];
            const pageSize = CONSTANTS.EVENT.TITLES.PAGE_SIZE;
            setTimeout(() => {//double dispatch, sorry :(
                Dispatcher.dispatch({
                    actionType: CONSTANTS.EVENT.TITLES.GET_BATCH.START
                });
            }, 10);

            //Slicing array to show the required titles
            titles.slice(pageNumber * pageSize, (pageNumber * pageSize) + pageSize).forEach(title => {
                titlesToRequest.push((title.get('titleId')) || title.get('id'));
            });


            Request.get('title').query({'title-id': titlesToRequest}).exec().then(titlesRes => {
                titlesRes.body.results.forEach(item1 => {
                    const elementWithSynopsisUsage = titles.toJS().find(item2 => item1.id === item2.titleId);
                    if (elementWithSynopsisUsage) {
                        item1.synopsisUsageType = elementWithSynopsisUsage.synopsisUsageType;
                    }
                });
                Dispatcher.dispatch({
                    actionType: CONSTANTS.EVENT.TITLES.GET_BATCH.SUCCESS,
                    titles: Immutable.fromJS(titlesRes.body.results)
                });
            });
        } else {
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.TITLES.GET_BATCH.SUCCESS,
                titles: Immutable.fromJS([])
            });
        }
    }

    /**
     * Get event users with filters applied
     * @param {*} eventId
     * @param {*} queryParams
     */
    getFilteredEventUsers(eventId, queryParams) {
        queryParams = queryParams.toJS();
        queryParams.offset = queryParams['event-offset'] || queryParams.offset || 0;
        queryParams.size = queryParams.size || 20;
        queryParams['sort-field'] = queryParams['event-sort-field'] || queryParams['sort-field'] || 'NAME';
        queryParams['sort-order'] = queryParams['event-sort-order'] || queryParams['sort-order'] || 'ASC';

        setTimeout(() => {//double dispatch
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.USERS.GET.START
            });
        }, 10);

        Request.get(`event/${eventId}/user`).query(queryParams).exec().then(response => {
            const {offset, results, size, totalCount} = response.body;
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.USERS.GET.SUCCESS,
                usersToDisplay: Immutable.fromJS({
                    offset,
                    size,
                    users: Immutable.fromJS(results),
                    total: totalCount,
                })
            });
        }).catch(err => {
            NotificationActions.showAlertDanger('events.users.get-error');
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.USERS.GET.ERROR
            });
            throw err;
        });

        return;
    }

    importLoginTokens(eventId, csvFile) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.USERS.IMPORT_TOKENS.START,
        });

        return UploadFile(
            'POST',
            `event/${eventId}/anonymous-token-csv`,
            csvFile,
            new XMLHttpRequest()
        ).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.USERS.IMPORT_TOKENS.SUCCESS,
            });

            NotificationActions.showAlertSuccess('events.users.import_tokens.add.success');
            this.getFilteredEventUsers(eventId, Immutable.fromJS({}));
        }).catch((error) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.USERS.IMPORT_TOKENS.ERROR,
            });
            NotificationActions.showAlertDanger('events.users.import_tokens.add.error');
            throw error;
        });
    }

    prepareDuplicateEvent(parentEventjs) {
        let duplicateEvent = Object.assign({}, parentEventjs);
        if (duplicateEvent.partners) {
            duplicateEvent.partners = duplicateEvent.partners.map(p => ({partnerId: p.partnerId}));
        }

        if (duplicateEvent.copyAssetsTitles) {
            if (duplicateEvent.titles.length) {
                duplicateEvent.titles = duplicateEvent.titles.map(t => ({titleId: t.titleId}));
            }

            if (duplicateEvent.assets.length) {
                duplicateEvent.assets = duplicateEvent.assets.map(a => ({assetId: a.assetId}));
            }
        } else {
            duplicateEvent.assets = [];
            duplicateEvent.titles = [];
        }

        if (!duplicateEvent.copyNotificationSettings) {
            duplicateEvent.eventUserNotificationType = CONSTANTS.EVENT_USER_NOTIFICATION_TYPES.NO_NOTIFICATION.id;
            duplicateEvent.customNotificationMessage = null;
            duplicateEvent.customNotificationSubject = null;
            duplicateEvent.customNotification = false;
        }
        return duplicateEvent;
    }

    removeItems(type, ids) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.REMOVE_ITEMS,
            type: type,
            ids: ids
        });
    }

    removeNomination(columnNumber, displayOrder) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE_PRESENTATION.NOMINATION_INFO.REMOVE,
            columnNumber,
            displayOrder
        });
    }

    removeTitleFromList(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.TITLES.REMOVE,
            id
        });
    }

    removeUsersFromList(id) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.USERS.REMOVE_FROM_LIST,
            id
        });
    }

    /**
     * Remove EventUsers from event.
     * @param {*} eventId
     * @param {*} usersIds
     */
    removeUsersFromEvent(eventId, usersIds) {
        const removeUsers = usersIds.map(userId =>
            Request.del(`event/${eventId}/user/${userId}`).exec(),
        );

        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.USERS.REMOVE.START,
        });

        Promise.all(removeUsers).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.USERS.REMOVE.SUCCESS,
            });

            this.getFilteredEventUsers(eventId, Immutable.fromJS({}));
            NotificationActions.showAlertSuccess('events.users.removing-success');
        }).catch((error) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.USERS.REMOVE.ERROR,
            });
            NotificationActions.showAlertDanger('events.users.removing-error');
            throw error;
        });
    }

    reorderNominationRow(columnNumber, from, to) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE_PRESENTATION.NOMINATION_INFO.REORDER,
            columnNumber,
            from,
            to
        });
    }

    reorderTitle(from, to) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.TITLES.REORDER,
            from,
            to
        });
    }

    restartEventNow(event) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.RESTART_NOW.START,
        });
        Request.post(`event/${event.get('eventId')}/revive`).send(event.toJS()).exec().then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.RESTART_NOW.SUCCESS,
                event
            });
            NotificationActions.showAlertSuccess('events.summary.restart-now.success');
        }).then(() => {
            return this.findById(event.get('eventId'));
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.RESTART_NOW.ERROR
            });
            NotificationActions.showAlertDanger('events.summary.restart-now.error');
            throw err;
        });
    }

    save(event, isDuplicateMode) {
        let data = this.stripAllPropsFromTitlesAndAssets(event).toJS();

        if (data.eventUserNotificationType === CONSTANTS.EVENT_USER_NOTIFICATION_TYPES.CUSTOM_NOTIFICATION.id) {
            data.customNotificationMessage = GetEncodedHTML(data.customNotificationMessage);
        } else {
            // API team requests this value be null unless notification type is custom
            data.customNotificationMessage = null;
            data.customNotificationSubject = null;
            data.showLogo = false;
        }

        if (data.selectedRole
            && [CONSTANTS.EVENT_TYPES.PRESS_SCREENING.id, CONSTANTS.EVENT_TYPES.FYC_SCREENING.id, CONSTANTS.EVENT_TYPES.BAFTA_EVENT.id].includes(data.eventType)
            && data.accountType === CONSTANTS.ACCOUNT_TYPES.TOKENIZED_ANONYMOUS.id) {
            data.roleId = data.selectedRole.id;
        } else {
            data.roleId = null;
        }
        delete data.selectedRole;

        let method = 'post';
        let url = 'event';
        let update = data.eventId !== undefined;

        if (update) {
            method = 'put';
            url = `event/${data.eventId}`;
        }

        if (isDuplicateMode) {
            data = this.prepareDuplicateEvent(data);
        }

        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.SAVE.START,
        });

        let promisesArray = [
            Request[method](url).send(data).exec(),
            Request.get('security/role').query({offset: 0, size: 999999, 'count-users': false}).exec()];

        if (update && !data.customNotificationImageS3) {
            promisesArray.push(Request.del(`event/${data.eventId}/notification-image`).exec());
        }

        let savedEvent;
        Promise.all(promisesArray).spread((res, rolesRes) => {
            const roles = Immutable.fromJS(rolesRes.body.results);

            savedEvent = res.body;
            savedEvent.linkToFYCAdminEvent = !!savedEvent.rootEventId;

            if (roles.find(r => r.get('id') === savedEvent.roleId)) {
                savedEvent.selectedRole = roles.find(r => r.get('id') === savedEvent.roleId);
            }

            if (update) {
                // update presentation tab after save event
                this.getPresentationTitles(Immutable.fromJS(savedEvent.titles), Immutable.fromJS(savedEvent.assets));
            }

            if (data.customNotificationFile) {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.EVENT.NOTIFICATION.IMAGE.START,
                });
                Request.del(`event/${data.eventId}/notification-image`).exec().then(() => {
                    UploadFile('PUT', `event/${data.eventId}/notification-image`, data.customNotificationFile, new XMLHttpRequest()).then((imgRes) => {
                        Dispatcher.dispatch({
                            actionType: CONSTANTS.EVENT.NOTIFICATION.IMAGE.SUCCESS,
                            customNotificationImageS3: JSON.parse(imgRes).customNotificationImageS3,
                        });
                    }).catch(err => {
                        Dispatcher.dispatch({
                            actionType: CONSTANTS.EVENT.NOTIFICATION.IMAGE.ADD_ERROR
                        });
                        NotificationActions.showAlertDanger('events.notifications.error.adding-image');
                        throw err;
                    });
                }).catch(err => {
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.EVENT.NOTIFICATION.IMAGE.DELETE_ERROR
                    });
                    NotificationActions.showAlertDanger('events.notifications.error.deleting-image');
                    throw err;
                });
            }

            if (isDuplicateMode) {
                if (data.copyUsers) {
                    this.duplicateEventUsers(data.parentEventId, savedEvent.eventId);
                }
                return this.duplicateEventPresentationTitlesData(event.toJS(), savedEvent);
            }

            return;

        }).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.SAVE.SUCCESS,
                event: Immutable.fromJS(savedEvent),
            });

            let message = 'events.create.success';
            if (update) {
                message = 'events.save.success';
            }
            if (isDuplicateMode) {
                message = 'events.duplicate.success';
            }

            if (!update) {
                RouterActions.redirect(`/events/${savedEvent.eventId}`, true);
            }

            NotificationActions.showAlertSuccess(message);
        }).catch((error) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.SAVE.ERROR,
                error,
            });

            let message = 'events.create.error';
            if (update) {
                message = 'events.save.error';
            }
            if (isDuplicateMode) {
                message = 'events.duplicate.error';
            }
            NotificationActions.showAlertDanger(message);
            throw error;
        });
    }

    saveTitleStyle(eventTitleId, titleStyle, titleStyleFiles, originalTitleStyleFiles, selectedTitleStyleOption, selectedTitleThumbnailOption, selectedSynopsisOption) {
        // If Synopsis is inherit then clean synopsis
        if (selectedSynopsisOption === CONSTANTS.TITLE_PRESENTATION.MODIFY_OPTIONS.INHERIT) {
            titleStyle = titleStyle.set('synopsis', null);
        }

        // If Title thumbnail is inherit then clean Title thumbnail
        if (selectedTitleThumbnailOption === CONSTANTS.TITLE_PRESENTATION.MODIFY_OPTIONS.INHERIT) {
            titleStyleFiles = titleStyleFiles.set('thumbnailImage', {});
            if (titleStyle.get('thumbnailImageUrl') || titleStyle.get('thumbnailImageS3Path')) {
                titleStyleFiles = titleStyleFiles.set('remove', Immutable.fromJS({
                    thumbnailImage: true
                }));
            }
        }

        // If Title Style is inherit then clean Title Style
        if (selectedTitleStyleOption === CONSTANTS.TITLE_PRESENTATION.MODIFY_OPTIONS.INHERIT) {
            titleStyle = titleStyle.set('appBackgroundColor', null);
            titleStyle = titleStyle.set('appTextColor', null);
            titleStyleFiles = titleStyleFiles.set('appBackgroundImage', {});
            titleStyleFiles = titleStyleFiles.set('appBackgroundVideo', {});
            titleStyleFiles = titleStyleFiles.set('appBackgroundTitleTreatment', {});
            let remove = titleStyleFiles.get('remove') || Immutable.Map();
            if (titleStyle.get('appBackgroundImageUrl') || titleStyle.get('appBackgroundImageS3Path')) {
                remove = remove.set('appBackgroundImage', true);
            }
            if (titleStyle.get('appBackgroundVideoUrl') || titleStyle.get('appBackgroundVideoS3Path')) {
                remove = remove.set('appBackgroundVideo', true);
            }
            if (titleStyle.get('appBackgroundTitleTreatmentUrl') || titleStyle.get('appBackgroundTitleTreatmentS3Path')) {
                remove = remove.set('appBackgroundTitleTreatment', true);
            }
            titleStyleFiles = titleStyleFiles.set('remove', remove);
        }

        return this.saveTitleStyleData(eventTitleId, titleStyle, titleStyleFiles, originalTitleStyleFiles);
    }

    saveTitlePresentationOptions(
        eventTitleId, titleStyle, titleStyleFiles, originalTitleStyleFiles, selectedTitleStyleOption,
        selectedTitleThumbnailOption, selectedSynopsisOption, nominationInfo, originalNominationInfo
    ) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE_PRESENTATION.SAVE.START,
        });

        const saveTitleStyle = this.saveTitleStyle(eventTitleId, titleStyle, titleStyleFiles, originalTitleStyleFiles, selectedTitleStyleOption, selectedTitleThumbnailOption, selectedSynopsisOption);
        const saveNominationInfo = this.saveNominationInfo(eventTitleId, nominationInfo, originalNominationInfo);

        return Promise.all([saveTitleStyle, saveNominationInfo]).then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE_PRESENTATION.SAVE.SUCCESS,
            });
            this.clearTitlePresentationOptions();
        }).catch((err) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.TITLE_PRESENTATION.SAVE.ERROR,
            });
            NotificationActions.showAlertDanger('events.presentation.save-presentation-title.error');
            throw err;
        });
    }

    saveTitleStyleData(eventTitleId, titleStyle, titleStyleFiles, originalTitleStyleFiles) {
        return Request.put(`event/title/${eventTitleId}/style`).send({
            appBackgroundColor : titleStyle.get('appBackgroundColor'),
            appTextColor : titleStyle.get('appTextColor'),
            synopsis : titleStyle.get('synopsis')
        }).exec().then(() => {
            if (!originalTitleStyleFiles.equals(titleStyleFiles)) {
                return Promise.all(this.saveTitleStyleFiles(
                    eventTitleId,
                    titleStyle,
                    titleStyleFiles
                ));
            }
            return;
        }).catch((err) => {
            NotificationActions.showAlertDanger('events.presentation.title-style.save.error');
            throw err;
        });
    }

    saveTitleStyleFiles(eventTitleId, titleStyle, titleStyleFiles) {
        const endpoints = {
            appBackgroundImage: `event/title/${eventTitleId}/app-background-image`,
            appBackgroundVideo: `event/title/${eventTitleId}/app-background-video`,
            appBackgroundTitleTreatment: `event/title/${eventTitleId}/app-title-treatment`,
            thumbnailImage: `event/title/${eventTitleId}/thumbnail`
        };
        const errorMessages = {
            appBackgroundImage: 'titles.create.style.background-image.error',
            appBackgroundVideo: 'titles.create.style.background-video.error',
            appBackgroundTitleTreatment: 'titles.create.style.title-treatment.error',
            thumbnailImage: 'events.presentation.title-thumbnail.error'
        };
        return Object.entries(endpoints).map(entries => {
            const [attr, endpoint] = entries;
            // Look carefully! size is the size of the File to upload in bytes
            // not an Immutable object property.
            // Not having a size means there's no file selected.
            if (!titleStyleFiles.get(attr).size) {
                // However, we still need to check if the user only removed
                // the existing image without uploading a new one.
                if (titleStyleFiles.getIn(['remove', attr]) === true) {
                    return Request.del(endpoint).exec().catch(e => {
                        NotificationActions.showAlertDanger(`${errorMessages[attr]}.delete-old-image`);
                        throw e;
                    });
                }

                // If not, then just return null because the user
                // hasn't changed anything.
                return null;
            }

            let del = Promise.resolve();
            const uploadMethod = 'PUT';
            if (titleStyle.get(`${attr}S3Path`)) {
                del = Request.del(endpoint).exec().catch(e => {
                    // Notify the user and keep throwing the value.
                    NotificationActions.showAlertDanger(`${errorMessages[attr]}.delete-old-image`);
                    throw e;
                });
            }
            return del.then(
                () => UploadFile(
                    uploadMethod,
                    endpoint,
                    titleStyleFiles.get(attr),
                    new XMLHttpRequest()
                ).catch(e => {
                    // Notify the user and keep throwing the value.
                    NotificationActions.showAlertDanger(`${errorMessages[attr]}.upload`);
                    throw e;
                }).then(titleRes => {
                    const response = JSON.parse(titleRes);
                    const s3Label = `${attr}S3Path`;
                    const urlLabel = `${attr}Url`;
                    this.updateTitle(s3Label, response[s3Label]);
                    this.updateTitle(urlLabel, response[urlLabel]);
                    return;
                })
            );
        }).filter(p => p !== null);
    }

    saveNominationInfo(eventTitleId, nominationInfo, originalNominationInfo) {
        if (nominationInfo.equals(originalNominationInfo)) {
            return;
        }
        nominationInfo = nominationInfo.toJS();
        nominationInfo = [
            ...nominationInfo[1].filter(nominationItem => nominationItem.displayValue || nominationItem.displayType).map((nominationItem, index) => {
                delete nominationItem.editing;
                nominationItem.displayOrder = index;
                return nominationItem;
            }),
            ...nominationInfo[2].filter(nominationItem => nominationItem.displayValue || nominationItem.displayType).map((nominationItem, index) => {
                delete nominationItem.editing;
                nominationItem.displayOrder = index;
                return nominationItem;
            }),
        ];
        return Request.put(`event/title/${eventTitleId}/nomination`).send(nominationInfo).exec().catch((err) => {
            NotificationActions.showAlertDanger('events.presentation.nomination-info.save.error');
            throw err;
        });
    }

    sendManualEmailNotification(event) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.NOTIFICATION.EMAIL.START
        });

        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.NOTIFICATION.EMAIL.LAST_EVENT_EMAIL_SENT,
            lastEventEmailSent: new Date().toISOString()
        });

        Request.post(`event/${event.get('eventId')}/send-email-notifications`).exec().then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.NOTIFICATION.EMAIL.SUCCESS
            });
            NotificationActions.showAlertSuccess('events.notifications.send-email-notification.success');
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.NOTIFICATION.EMAIL.ERROR
            });
            if (err.status === 400) {
                NotificationActions.showAlertDanger('events.notifications.send-email-notification.debounce-error');
                throw err;
            }
            NotificationActions.showAlertDanger('events.notifications.send-email-notification.error');
            throw err;
        });
    }

    sendPreviewEmails(eventId, authUserEmail) {
        // Sends preview of all event emails to the current authorized user for review purposes
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.SEND_PREVIEW_EMAILS.START,
        });

        Request.post(`event/${eventId}/preview-email`).exec().then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.SEND_PREVIEW_EMAILS.SUCCESS,
            });

            NotificationActions.showAlertSuccess('events.edit.preview-emails.success', authUserEmail);
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.SEND_PREVIEW_EMAILS.ERROR
            });

            NotificationActions.showAlertDanger('events.edit.preview-emails.error');
            throw err;
        });

    }

    selectUsersFromSpreadsheet(spreadsheet, eventId) {
        if (spreadsheet) {
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.USERS.SET_FROM_SPREADSHEET.START
            });
            return UploadFile(
                'POST',
                `event/${eventId}/mass-add-user`,
                spreadsheet,
                new XMLHttpRequest()
            ).then((res) => {
                const response = JSON.parse(res);
                const hits = response.eventUsers.length;
                const misses = response.userIngestHeader?.numberRecords || 0;

                Dispatcher.dispatch({
                    actionType: CONSTANTS.EVENT.USERS.SET_FROM_SPREADSHEET.SUCCESS,
                    hits,
                    misses,
                    total: hits+misses,
                    userBatchId: response.userIngestHeader?.id || 0,
                });

                this.getFilteredEventUsers(eventId, Immutable.fromJS({}));

                if (hits > 0 && misses === 0) {
                    NotificationActions.showAlertSuccess('events.users.adding-success', hits, hits+misses);
                } else {
                    NotificationActions.showAlertWarning('events.users.adding-success', hits, hits+misses);

                    // Show modal with link to batch
                    throw new Error('batch created');
                }
            }).catch((e) => {
                switch (e.status) {
                case 409:
                    // Notify the user that the event is locked
                    Dispatcher.dispatch({
                        actionType: CONSTANTS.EVENT.USERS.SET_FROM_SPREADSHEET.ERROR
                    });
                    NotificationActions.showAlertDanger('events.event-locked-error');
                    break;
                default:
                    if (e.message !== 'batch created') {
                        NotificationActions.showAlertDanger('events.users.adding-error');

                        Dispatcher.dispatch({
                            actionType: CONSTANTS.EVENT.USERS.SET_FROM_SPREADSHEET.ERROR
                        });
                    }
                }
                throw e;
            });
        }
    }

    setFilter(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FILTER.SET,
            attr: attr,
            value: value
        });
    }

    setPartners(partners) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.SET.PARTNERS,
            selected: partners
        });
    }

    setPermissionPackages(packages) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.SET.PERMISSION_PACKAGES,
            selected: packages
        });
    }

    startEventNow(event) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.START_NOW.START,
        });
        Request.post(`schedule-batch-update/${event.get('startScheduleBatchId')}/execute-now`).exec().then(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.START_NOW.SUCCESS,
                event
            });
            NotificationActions.showAlertSuccess('events.summary.start-now.success');
        }).then(() => {
            return this.findById(event.get('eventId'));
        }).catch(err => {
            switch (err.status) {
            case 409:
                // Notify the user that the event is locked
                Dispatcher.dispatch({
                    actionType: CONSTANTS.EVENT.START_NOW.ERROR
                });
                NotificationActions.showAlertDanger('events.event-locked-error');
                break;
            default:
                Dispatcher.dispatch({
                    actionType: CONSTANTS.EVENT.START_NOW.ERROR
                });
                NotificationActions.showAlertDanger('events.summary.start-now.error');
            }

            throw err;
        });
    }

    stripAllPropsFromTitlesAndAssets(event) {
        // Strips titles on initial save only to remove fields which may contain html/json that trigger cloudflare 403
        if (event.get('titles').size) {
            event = event.update('titles', titles => {
                return titles.map(title => {
                    if (!title.get('eventTitleId')) {
                        return Immutable.fromJS({
                            titleId: title.get('titleId'),
                            displayOrder: title.get('displayOrder'),
                        });
                    }

                    return title;
                });
            });
        }

        // Strips assets on initial save only to remove fields which may contain html/json that trigger cloudflare 403
        if (event.get('assets').size) {
            event = event.update('assets', assets => {
                return assets.map(asset => {
                    if (!asset.get('eventAssetId')) {
                        return Immutable.fromJS({assetId: asset.get('assetId')});
                    }

                    return asset;
                });
            });
        }

        return event;
    }

    titleSynopsisChange(titleId, synopsisType) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.TITLES.SET_SYNOPSIS_TYPE,
            titleId,
            synopsisType
        });
    }

    updateNomination(columnNumber, displayOrder, columnId, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE_PRESENTATION.NOMINATION_INFO.UPDATE,
            columnNumber,
            displayOrder,
            columnId,
            value
        });
    }

    updateEvent(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.UPDATE,
            attr: attr,
            value: value
        });
    }

    updateEventNotification(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.UPDATE_NOTIFICATION,
            value
        });
    }

    updateTitle(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE_PRESENTATION.TITLE_STYLE.UPDATE,
            attr,
            value
        });

        return;
    }

    // Update the TitleStyle object. Mainly used
    // to store references to the files to upload.
    updateTitleStyle(attr, value) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE_PRESENTATION.TITLE_STYLE.UPDATE_FILES,
            attr,
            value
        });

        return;
    }

    userIngestByEmails(eventId, emails) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.EVENT.USERS.ACCOUNT_WIZARD.START
        });
        Request.post(`user/ingest/event/${eventId}`).send(emails.toJS()).exec().then((res) => {
            const {id: batchId, batchErrorMessage: statusMessage} = res.body;

            if (statusMessage !== null) {
                let errorMsg;
                switch (statusMessage) {
                case AccountWizardConstants.USERS.ERRORS.EXCEEDED.id:
                    errorMsg = 'exceeded';
                    break;
                case AccountWizardConstants.USERS.ERRORS.PROCESSING.id:
                default:
                    errorMsg = 'processing';
                    break;
                }
                RouterActions.redirect(`/accounts/account-wizard/${batchId}`);
                NotificationActions.showAlertDanger(`accounts.account-wizard.error.${errorMsg}`);
            } else {
                RouterActions.redirect(`/accounts/account-wizard/${batchId}`);
            }
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.USERS.ACCOUNT_WIZARD.SUCCESS
            });
        }).catch(err => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.EVENT.USERS.ACCOUNT_WIZARD.ERROR
            });
            throw err;
        });
    }

    setModifyOption(attr, selectedOption) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.TITLE_PRESENTATION.SET_MODIFY_OPTION,
            attr,
            selectedOption
        });
    }
}

const actions = new EventActions();

export {
    actions as EventActions,
    CONSTANTS as EventConstants
};
