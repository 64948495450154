/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Checkbox, ControlLabel, FormGroup} from 'react-bootstrap';

import ClientRepGroups from './client-rep-groups';
import Info from './info';
import {FormRow, FormSection} from '../../../common/form/form';
import {GroupConstants} from '../../../security/group/group-actions';
import GroupSelect from '../../../security/group/group-select';
import {PermissionPackageActions} from '../../../security/permission-package/permission-package-actions';
import PermissionPackageStore from '../../../security/permission-package/permission-package-store';
import RoleSelect from '../../../security/role/role-select';

import Partners from '~/src/partner/partners';

/**
 * Summary Tab and its content.
 */
class Summary extends Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            mode: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static calculateState() {
        return {
            permissionPackage: PermissionPackageStore.getState().get('permissionPackage')
        };
    }

    static getStores() {
        return [PermissionPackageStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            showInactiveGroups: false,
            showInactiveRoles: false,
            showInactiveStations: false,
            showInactiveBrainiacGroups: false,
        }, this.constructor.calculateState());

        this.handleShowInactive = this.handleShowInactive.bind(this);
        this.handleSelectPartners = this.handleSelectPartners.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.permissionPackage !== this.state.permissionPackage ||
            nextState.showInactiveGroups !== this.state.showInactiveGroups ||
            nextState.showInactiveRoles !== this.state.showInactiveRoles ||
            nextState.showInactiveStations !== this.state.showInactiveStations ||
            nextState.showInactiveBrainiacGroups !== this.state.showInactiveBrainiacGroups) {
            return true;
        }
        return false;
    }

    handleSelectBrainiacGroup(groups) {
        if (!groups) {
            // Reset the value.
            PermissionPackageActions.setBrainiacGroups(Immutable.OrderedSet());
            return;
        }

        // Call the select action but with immutable objects.
        PermissionPackageActions.setBrainiacGroups(Immutable.fromJS(groups).toSet().sortBy(g => g.get('name')));
        return;
    }

    handleSelectGroup(groups) {
        if (!groups) {
            // Reset the value.
            PermissionPackageActions.setGroups(Immutable.OrderedSet());
            return;
        }

        // Call the select action but with immutable objects.
        PermissionPackageActions.setGroups(Immutable.fromJS(groups).toSet().sortBy(g => g.get('name')));
        return;
    }

    handleSelectPrimaryPartner(value) {
        PermissionPackageActions.updatePreset('defaultPartnerId', value.id);
    }

    handleSelectPartners(partners) {
        const partnerIds = partners.map(r => r.id);
        const isPartnerPresent = partnerIds.includes(this.state.permissionPackage.get('defaultPartnerId'));
        PermissionPackageActions.updatePreset('webPartnerIds', partnerIds);

        if (!isPartnerPresent) {
            PermissionPackageActions.updatePreset('defaultPartnerId', undefined);
        }

    }

    handleSelectRole(roles) {
        if (!roles) {
            // Reset the value.
            PermissionPackageActions.setRoles(Immutable.OrderedSet());
            return;
        }
        // Call the select action but with immutable objects.
        PermissionPackageActions.setRoles(Immutable.fromJS(roles).toSet().sortBy(g => g.get('name')));
        return;
    }

    handleSelectStation(stations) {
        if (!stations) {
            // Reset the value.
            PermissionPackageActions.setStations(Immutable.OrderedSet());
            return;
        }

        // Call the select action but with immutable objects.
        PermissionPackageActions.setStations(Immutable.fromJS(stations).toSet().sortBy(g => g.get('name')));
        return;
    }


    handleShowInactive(value, event) {
        this.setState({
            [`showInactive${value}`]: event.target.checked
        });
        return;
    }

    render() {
        let partners = Immutable.List();

        if (this.state.permissionPackage.get('webPartnerIds')) {
            partners = Immutable.List(this.state.permissionPackage.get('webPartnerIds').map( partnerId => Immutable.fromJS({id: partnerId})));
        }

        const wbdGroupsSection = <FormSection title={this.context.intl.messages['accounts.create.permissions.groups']} iconClass="fas fa-users">
            <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['presets.create.groups.chose']}</ControlLabel>
                    <Checkbox
                        className="pull-right show-inactive"
                        onChange={this.handleShowInactive.bind(this, 'Groups')}>
                        {this.context.intl.messages['common.show-inactive']}
                    </Checkbox>
                    <GroupSelect
                        disabled={this.props.disabled}
                        onChange={this.handleSelectGroup}
                        filterOption={(g) => {
                            return ((g.label.toLowerCase().startsWith('event')) || !g.label.toLowerCase().startsWith('event') && !g.data.isAdmin);
                        }}
                        selected={this.state.permissionPackage.get('groups')}
                        showInactive={this.state.showInactiveGroups}
                        type={GroupConstants.USER} />
                </FormGroup>
            </FormRow>
        </FormSection>;

        let brainiacGroupsSection = <FormSection title={this.context.intl.messages['accounts.create.permissions.brainiac-groups']} iconClass="fas fa-users">
            <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['presets.create.brainiac-groups.chose']}</ControlLabel>
                    <Checkbox
                        className="pull-right show-inactive"
                        onChange={this.handleShowInactive.bind(this, 'BrainiacGroups')}>
                        {this.context.intl.messages['common.show-inactive']}
                    </Checkbox>
                    <GroupSelect
                        disabled={this.props.disabled}
                        onChange={this.handleSelectBrainiacGroup}
                        filterOption={(g) => g.data.isAdmin}
                        selected={this.state.permissionPackage.get('brainiacGroups')}
                        showInactive={this.state.showInactiveBrainiacGroups}
                        type={GroupConstants.USER}
                        subtype="brainiacGroups" />
                </FormGroup>
            </FormRow>
        </FormSection>;

        const rolesSection = <FormSection title={this.context.intl.messages['presets.create.roles.title']} iconClass="fas fa-male">
            <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['presets.create.roles.chose']}</ControlLabel>
                    <Checkbox
                        className="pull-right show-inactive"
                        onChange={this.handleShowInactive.bind(this, 'Roles')}>
                        {this.context.intl.messages['common.show-inactive']}
                    </Checkbox>
                    <RoleSelect
                        disabled={this.props.disabled}
                        onChange={this.handleSelectRole}
                        selected={this.state.permissionPackage.get('roles')}
                        showInactive={this.state.showInactiveRoles} />
                </FormGroup>
            </FormRow>
        </FormSection>;

        const partnersSection = <Partners
            defaultPartner={Immutable.fromJS({id: this.state.permissionPackage.get('defaultPartnerId')})}
            disabled={false}
            onSelectPrimaryPartner={this.handleSelectPrimaryPartner}
            onSelectPartners={this.handleSelectPartners}
            partners={partners}
            required={false} />;

        const clientRepGroupsSection = <ClientRepGroups
            disabled={this.props.disabled}
            selected={this.state.permissionPackage.get('clientRepGroups')} />;

        const stationsSection = <FormSection title={this.context.intl.messages['presets.create.stations.title']} iconClass="fas fa-tv-retro">
            <FormRow>
                <FormGroup>
                    <ControlLabel>{this.context.intl.messages['presets.create.stations.choose']}</ControlLabel>
                    <Checkbox
                        className="pull-right show-inactive"
                        onChange={this.handleShowInactive.bind(this, 'Stations')}>
                        {this.context.intl.messages['common.show-inactive']}
                    </Checkbox>
                    <GroupSelect
                        disabled={this.props.disabled}
                        onChange={this.handleSelectStation}
                        selected={this.state.permissionPackage.get('stations')}
                        showInactive={this.state.showInactiveStations}
                        type={GroupConstants.STATION} />
                </FormGroup>
            </FormRow>
        </FormSection>;

        return (
            <div className="tab-pane padding-x-20 padding-bottom-20">
                <Info mode={this.props.mode} preset={this.state.permissionPackage} disabled={this.props.disabled}/>
                <hr />
                {clientRepGroupsSection}
                <hr />
                {partnersSection}
                <hr />
                {wbdGroupsSection}
                <hr />
                {rolesSection}
                <hr />
                {brainiacGroupsSection}
                <hr />
                {stationsSection}
                <hr />

            </div>
        );
    }
}

export {Summary as Summary_BASE};
export default Container.create(Summary);
