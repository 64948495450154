/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {DOCUMENT_CT_TYPES} from '@wbdt-sie/brainiac-web-common';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Tab, Tabs} from 'react-bootstrap';

import AddNewAssets from '../add-new-assets';

import {AudioActions} from '~/src/assets/audio/audio-actions';
import AudioStore from '~/src/assets/audio/audio-store';
import AudioSummary from '~/src/assets/audio/create/summary/summary';
import CatalogsTab from '~/src/assets/catalogs/catalogs';
import DocumentSummary from '~/src/assets/document/create/summary/summary';
import {DocumentActions} from '~/src/assets/document/document-actions';
import DocumentStore from '~/src/assets/document/document-store';
import ImageSummary from '~/src/assets/image/create/summary/summary';
import {ImageActions} from '~/src/assets/image/image-actions';
import ImageStore from '~/src/assets/image/image-store';
import MerchandiseSummary from '~/src/assets/merchandise/create/summary/summary';
import {MerchandiseActions} from '~/src/assets/merchandise/merchandise-actions';
import MerchandiseStore from '~/src/assets/merchandise/merchandise-store';
import ScriptSummary from '~/src/assets/script/create/summary/summary';
import {ScriptActions} from '~/src/assets/script/script-actions';
import ScriptStore from '~/src/assets/script/script-store';
import VideoSummary from '~/src/assets/video/create/summary/summary';
import {VideoActions} from '~/src/assets/video/video-actions';
import VideoStore from '~/src/assets/video/video-store';
import FileInput from '~/src/common/file-input';
import Panel from '~/src/common/panel/panel';
import SlidingPanel from '~/src/common/sliding-panel/sliding-panel';


class AddNewPanel extends Component {
    static get propTypes() {
        return {
            addConstants: PropTypes.string.isRequired,
            assetType: PropTypes.string.isRequired,
            caller: PropTypes.string,
            headerTitle: PropTypes.string.isRequired,
            title: PropTypes.node.isRequired,
            showCatalogsTab: PropTypes.bool,
        };
    }

    static get defaultProps() {
        return {
            caller: '',
            showCatalogsTab: false,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            audioAsset: AudioStore.getState().get('asset'),
            documentAsset: DocumentStore.getState().get('asset'),
            imageAsset: ImageStore.getState().get('asset'),
            merchandiseAsset: MerchandiseStore.getState().get('asset'),
            scriptAsset: ScriptStore.getState().get('asset'),
            videoAsset: VideoStore.getState().get('asset'),
        };
    }

    static getStores() {
        return [AudioStore, DocumentStore, ImageStore, MerchandiseStore, ScriptStore, VideoStore];
    }

    constructor(props) {
        super(props);
        this.state = this.constructor.calculateState();
    }

    handleGetFiles(store) {
        return store.getState().getIn(['asset', 'files'], Immutable.List()).map(f => f.name);
    }

    handleSourceFilesChange(actions, e) {
        actions.update('files', [...e.target.files]);
    }

    render() {
        let addNewAsset;
        let filePanel;
        let slidePanelTitle = this.props.headerTitle;

        switch (this.props.assetType) {
        case 'audio':
            filePanel = <Panel iconClass="fas fa-upload" title={this.context.intl.messages['titles.create.assets.upload-file']} additionalStyle="padding-x-20" collapsible defaultExpanded>
                <FileInput
                    accept="audio/*"
                    files={this.handleGetFiles(AudioStore)}
                    id="audio-file"
                    onChange={this.handleSourceFilesChange.bind(this, AudioActions)}
                />
            </Panel>;
            addNewAsset = (
                <AddNewAssets
                    addConstants={this.props.addConstants}
                    actions={AudioActions}
                    fileId="audio-file"
                    filePanel={filePanel}
                    caller={this.props.caller}
                    store={AudioStore}
                >
                    <div className="box">
                        <div className="box-body">
                            <AudioSummary
                                asset={this.state.audioAsset}
                                disabled={false}
                                mode="create"
                            />
                        </div>
                    </div>
                </AddNewAssets>
            );
            break;
        case 'document':
            filePanel = <Panel iconClass="fas fa-upload" title={this.context.intl.messages['titles.create.assets.upload-file']} additionalStyle="padding-x-20" collapsible defaultExpanded>
                <FileInput
                    accept="document/*"
                    files={this.handleGetFiles(DocumentStore)}
                    id="document-file"
                    onChange={this.handleSourceFilesChange.bind(this, DocumentActions)}
                />
            </Panel>;

            /* istanbul ignore next */
            const filterContentType = (options) => options.filter((option) =>
                [
                    DOCUMENT_CT_TYPES.CONTENT_TYPES.INTERNAL_FILE.id,
                    DOCUMENT_CT_TYPES.CONTENT_TYPES.MARKETING_SAMPLE.id,
                    DOCUMENT_CT_TYPES.CONTENT_TYPES.MARKETING_TOOL_KIT.id,
                    DOCUMENT_CT_TYPES.CONTENT_TYPES.OTHER.id,
                    DOCUMENT_CT_TYPES.CONTENT_TYPES.PRESS_FILE.id,
                    DOCUMENT_CT_TYPES.CONTENT_TYPES.RESEARCH_FILE.id,
                ].includes(option.get('id')));

            let addNewDocumentContent;
            if (this.props.showCatalogsTab) {
                addNewDocumentContent = (
                    <Tabs defaultActiveKey={1} className="nav-tabs-custom">
                        <Tab eventKey={1} className="tab-content" animation={false} title={this.context.intl.messages['common.tab-title.summary']}>
                            <DocumentSummary
                                asset={this.state.documentAsset}
                                disabled={false}
                                filterContentType={filterContentType}
                                mode="create"
                            />
                        </Tab>
                        <Tab eventKey={2} className="tab-content" title={this.context.intl.messages['asset.edit.catalogs-tab-title']}>
                            <CatalogsTab displayLinks={true} />
                        </Tab>
                    </Tabs>
                );
            } else {
                addNewDocumentContent = (
                    <div className="box">
                        <div className="box-body">
                            <DocumentSummary
                                asset={this.state.documentAsset}
                                disabled={false}
                                filterContentType={filterContentType}
                                mode="create"
                            />
                        </div>
                    </div>
                );
            }

            addNewAsset = (
                <AddNewAssets
                    addConstants={this.props.addConstants}
                    actions={DocumentActions}
                    fileId="document-file"
                    filePanel={filePanel}
                    caller={this.props.caller}
                    store={DocumentStore}
                >
                    {addNewDocumentContent}
                </AddNewAssets>
            );
            break;
        case 'station':
            filePanel = <Panel iconClass="fas fa-upload" title={this.context.intl.messages['titles.create.assets.upload-file']} additionalStyle="padding-x-20" collapsible defaultExpanded>
                <FileInput
                    accept="document/*"
                    files={this.handleGetFiles(DocumentStore)}
                    id="document-file"
                    onChange={this.handleSourceFilesChange.bind(this, DocumentActions)}
                />
            </Panel>;

            const filterContentTypeStations = (options) => options.filter((option) => [DOCUMENT_CT_TYPES.CONTENT_TYPES.STATION_DOCUMENT.id].includes(option.get('id')));

            let addNewStationContent;
            if (this.props.showCatalogsTab) {
                addNewStationContent = (
                    <Tabs defaultActiveKey={1} className="nav-tabs-custom">
                        <Tab eventKey={1} className="tab-content" animation={false} title={this.context.intl.messages['common.tab-title.summary']}>
                            <DocumentSummary
                                asset={this.state.documentAsset}
                                disabled={false}
                                filterContentType={filterContentTypeStations}
                                mode="create"
                                title={this.props.title}
                            />
                        </Tab>
                        <Tab eventKey={2} className="tab-content" title={this.context.intl.messages['asset.edit.catalogs-tab-title']}>
                            <CatalogsTab displayLinks={true} />
                        </Tab>
                    </Tabs>
                );
            } else {
                addNewStationContent = (
                    <div className="box">
                        <div className="box-body">
                            <DocumentSummary
                                asset={this.state.documentAsset}
                                disabled={false}
                                filterContentType={filterContentTypeStations}
                                mode="create"
                                title={this.props.title}
                            />
                        </div>
                    </div>
                );
            }

            addNewAsset = (
                <AddNewAssets
                    addConstants={this.props.addConstants}
                    actions={DocumentActions}
                    fileId="document-file"
                    filePanel={filePanel}
                    caller={this.props.caller}
                    store={DocumentStore}
                >
                    {addNewStationContent}
                </AddNewAssets>
            );
            break;
        case 'image':
            slidePanelTitle = this.context.intl.messages['titles.create.assets.add-new.images'];
            filePanel = (
                <Panel iconClass="fas fa-upload" title={this.context.intl.messages['titles.create.assets.upload-file']} additionalStyle="padding-x-20" collapsible defaultExpanded>
                    <label>{this.context.intl.messages['common.sourceFiles']}</label>
                    <FileInput
                        accept="application/*, image/*, video/*"
                        files={this.handleGetFiles(ImageStore)}
                        id="image-file"
                        multiple={true}
                        onChange={this.handleSourceFilesChange.bind(this, ImageActions)}
                    />
                    <hr/>
                    <small>
                        <p>
                            <strong>{`${this.context.intl.messages['common.note']}-`}</strong>&nbsp;
                            {this.context.intl.messages['titles.create.assets.add-new.images.note.when-condition']}
                        </p>
                        <ul>
                            <li>{this.context.intl.messages['titles.create.assets.add-new.images.note.serialized-names']}</li>
                            <li>{this.context.intl.messages['titles.create.assets.add-new.images.note.example-name']}</li>
                            <li>{this.context.intl.messages['titles.create.assets.add-new.images.note.metadata-fields']}</li>
                        </ul>
                    </small>
                </Panel>
            );

            let addNewImageContent;
            if (this.props.showCatalogsTab) {
                addNewImageContent = (
                    <Tabs defaultActiveKey={1} className="nav-tabs-custom">
                        <Tab eventKey={1} className="tab-content" animation={false} title={this.context.intl.messages['asset.image.summary.title']}>
                            <ImageSummary
                                asset={this.state.imageAsset}
                                disabled={false}
                                mode="create"
                            />
                        </Tab>
                        <Tab eventKey={2} className="tab-content" title={this.context.intl.messages['asset.edit.catalogs-tab-title']}>
                            <CatalogsTab displayLinks={true} />
                        </Tab>
                    </Tabs>
                );
            } else {
                addNewImageContent = (
                    <div className="box">
                        <div className="box-body">
                            <ImageSummary
                                asset={this.state.imageAsset}
                                disabled={false}
                                mode="create"
                            />
                        </div>
                    </div>
                );
            }
            addNewAsset = (
                <AddNewAssets
                    addConstants={this.props.addConstants}
                    actions={ImageActions}
                    fileId="image-file"
                    filePanel={filePanel}
                    caller={this.props.caller}
                    store={ImageStore}
                >
                    {addNewImageContent}
                </AddNewAssets>
            );
            break;
        case 'merchandise':
            filePanel = <Panel iconClass="fas fa-upload" title={this.context.intl.messages['asset.merchandise.create.upload']} additionalStyle="padding-x-20" collapsible defaultExpanded>
                <FileInput
                    accept="image/*"
                    files={this.handleGetFiles(MerchandiseStore)}
                    id="merchandise-file"
                    onChange={this.handleSourceFilesChange.bind(this, MerchandiseActions)}
                />
            </Panel>;
            addNewAsset = (
                <AddNewAssets
                    addConstants={this.props.addConstants}
                    actions={MerchandiseActions}
                    fileId="merchandise-file"
                    filePanel={filePanel}
                    caller={this.props.caller}
                    store={MerchandiseStore}
                >
                    <div className="box">
                        <div className="box-body">
                            <MerchandiseSummary
                                asset={this.state.merchandiseAsset}
                                disabled={false}
                                mode="create"
                            />
                        </div>
                    </div>
                </AddNewAssets>
            );
            break;
        case 'script':
            filePanel = <Panel iconClass="fas fa-upload" title={this.context.intl.messages['titles.create.assets.upload-file']} additionalStyle="padding-x-20" collapsible defaultExpanded>
                <FileInput
                    accept="application/pdf"
                    files={this.handleGetFiles(ScriptStore)}
                    id="script-file"
                    onChange={this.handleSourceFilesChange.bind(this, ScriptActions)}
                />
            </Panel>;
            addNewAsset = (
                <AddNewAssets
                    addConstants={this.props.addConstants}
                    actions={ScriptActions}
                    fileId="script-file"
                    filePanel={filePanel}
                    caller={this.props.caller}
                    store={ScriptStore}
                >
                    <div className="box">
                        <div className="box-body">
                            <ScriptSummary
                                asset={this.state.scriptAsset}
                                disabled={false}
                                mode="create"
                            />
                        </div>
                    </div>
                </AddNewAssets>
            );
            break;
        case 'video':
            filePanel = <Panel iconClass="fas fa-upload" title={this.context.intl.messages['asset.video.create.upload']} additionalStyle="padding-x-20" collapsible defaultExpanded>
                <FileInput
                    accept="video/*"
                    files={this.handleGetFiles(VideoStore)}
                    id="video-file"
                    onChange={this.handleSourceFilesChange.bind(this, VideoActions)}
                />
            </Panel>;
            addNewAsset = (
                <AddNewAssets
                    addConstants={this.props.addConstants}
                    actions={VideoActions}
                    fileId="video-file"
                    filePanel={filePanel}
                    caller={this.props.caller}
                    store={VideoStore}
                >
                    <div className="box">
                        <div className="box-body">
                            <VideoSummary
                                asset={this.state.videoAsset}
                                disabled={false}
                                mode="create"
                            />
                        </div>
                    </div>
                </AddNewAssets>
            );
            break;
        }

        slidePanelTitle = <span>{slidePanelTitle} <small>{this.props.title}</small></span>;
        return (
            <SlidingPanel id="addNew" title={slidePanelTitle} icon="fas fa-plus-circle">
                {addNewAsset}
            </SlidingPanel>
        );
    }
}

export {AddNewPanel};
export default Container.create(AddNewPanel);
