/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';

import ListAssets from './list-assets';
import DocumentTitle from '../../common/document-title';
import SearchBox from '../../common/search-box/search-box';
import {WithStoreOnRoute} from '../../common/store-on-route';
import {Debounce} from '../../common/utils/utils';
import {LayoutActions} from '../../layout/layout-actions';
import LayoutStore from '../../layout/layout-store';
import Preloader from '../../preloader';
import PreloaderStore from '../../preloader/preloader-store';
import SessionStore from '../../user/session/session-store';
import {AssetActions, AssetConstants} from '../asset-actions';
import AssetStore from '../asset-store';
import FilterOptions from '../filter-options/filter-options';

class Browse extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static getStores() {
        return [AssetStore, LayoutStore, PreloaderStore];
    }

    static calculateState() {
        return {
            assets: AssetStore.getState().get('assets'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible'),
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            total: AssetStore.getState().get('total')
        };
    }

    static getPermissions() {
        return {
            canReadAudioAssets: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.AUDIO.READ),
            canReadDocumentAssets: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.ASSET_DOCUMENT.READ),
            canReadImageAssets: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.ASSET_IMAGE.READ),
            canReadMerchandiseAssets: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.MERCHANDISE.READ),
            canReadProxyAssets: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.PROXY.READ),
            canReadScriptAssets: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.SCRIPT.READ),
            canReadVideoAssets: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.VIDEO.READ),
            canReadVideoTimelineAssets: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.TIMELINE.READ)
        };
    }

    constructor(props) {
        super(props);

        let assetType;

        if (this.props.location.pathname.includes('images')) {
            assetType = AssetConstants.ASSET_TYPES.IMAGE.id;
        }
        if (this.props.location.pathname.includes('videos')) {
            assetType = AssetConstants.ASSET_TYPES.VIDEO.id;
        }
        if (this.props.location.pathname.includes('documents')) {
            assetType = AssetConstants.ASSET_TYPES.DOCUMENT.id;
        }

        this.state = Object.assign({
            assetType: assetType
        }, this.constructor.calculateState());


        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);

        return;
    }

    componentDidMount() {
        AssetActions.clear();
        if (this.props.location.query['active-type']) {
            AssetActions.get(this.getRouteState());
            return;
        }
        // Browse only image/video assets
        if (this.state.assetType) {
            this.setRouteState('asset-type', this.state.assetType)
                .setRouteState('operator', 'AND')
                .apply();
        }
        this.setRouteState('active-type', 'ACTIVE').apply();
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.location.query !== this.props.location.query ||
            nextState.showFiltersPanel !== this.state.showFiltersPanel ||
            nextState.total !== this.state.total ||
            nextState.assets !== this.state.assets ||
            nextState.preloaderVisible !== this.state.preloaderVisible) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            AssetActions.get(this.getRouteState(this.props));
        }
        return;
    }

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */
    handleLoadPage(pageNumber) {
        // pageNumber x this.state.size = offset.
        this.setRouteState('offset', pageNumber * 20)
            .apply();
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setRouteState('sort-field-name', sortFieldName)
            .setRouteState('sort-direction', sortDirection)
            .apply();
        return;
    }

    handleSearchTerm(term) {
        this.setRouteState('asset-name', term)
            .setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
        return;
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
        return;
    }

    render() {
        let assetType = 'all';
        let className = 'far fa-file-image';
        let columns;
        let columnDefs;
        let showAssetType = true;
        let showDeliveryType = false;
        if (this.state.assetType) {
            showAssetType = false;
            switch (this.state.assetType) {
            case AssetConstants.ASSET_TYPES.IMAGE.id:
                assetType = AssetConstants.ASSET_MAIN_TYPES.IMAGE;
                columns = ['assetThumbnail', 'assetName', 'contentType', 'createdDate', 'deliveryType', 'active',
                    'fileType', 'catalogs'];
                columnDefs = [{
                    className: 'control',
                    targets: -1,
                    width: 20
                }, {
                    className: 'actions',
                    targets: 'actions'
                }, {
                    targets: 'no-sort',
                    orderable: false
                }, {
                    targets: [0], //assetThumbnail
                    className: 'text-center',
                    width: 60
                }, {
                    targets: [1] //assetName
                }, {
                    targets: [2], //contentType
                    width: '12%'
                }, {
                    targets: [3], //createdDate
                    width: '10%'
                }, {
                    targets: [4], //deliveryType
                    width: '10%',
                }, {
                    targets: [5], //active
                    width: 120
                }, {
                    targets: [6], //fileType
                    width: 120
                }, {
                    targets: [7], //catalogs
                    width: '10%'
                }];
                className = AssetConstants.ASSET_TYPES.IMAGE.icon;
                break;
            case AssetConstants.ASSET_TYPES.VIDEO.id:
                assetType = AssetConstants.ASSET_MAIN_TYPES.VIDEO;
                showDeliveryType = true;
                columns = ['assetThumbnail', 'assetName', 'contentType', 'createdDate', 'deliveryType',
                    'active', 'runtime', 'catalogs'];
                columnDefs = [{
                    className: 'control',
                    targets: -1,
                    width: 20
                }, {
                    className: 'actions',
                    targets: 'actions'
                }, {
                    targets: 'no-sort',
                    orderable: false
                }, {
                    targets: [0], //assetThumbnail
                    className: 'text-center',
                    width: 60
                }, {
                    targets: [1] //assetName
                }, {
                    targets: [2], //contentType
                    width: '12%'
                }, {
                    targets: [3], //createdDate
                    width: '10%'
                }, {
                    targets: [4], //deliveryType
                    width: '10%'
                }, {
                    targets: [5], //active
                    width: 120
                }, {
                    targets: [6], //runtime
                    width: 120
                }, {
                    targets: [7], //catalogs
                    width: '10%'
                }];
                className = AssetConstants.ASSET_TYPES.VIDEO.icon;
                break;
            case AssetConstants.ASSET_TYPES.DOCUMENT.id:
                assetType = AssetConstants.ASSET_MAIN_TYPES.DOCUMENT;
                columns = ['assetThumbnail', 'assetName', 'contentType', 'createdDate', 'deliveryType', 'active',
                    'catalogs'];
                columnDefs = [{
                    className: 'control',
                    targets: -1,
                    width: 20
                }, {
                    className: 'actions',
                    targets: 'actions'
                }, {
                    targets: 'no-sort',
                    orderable: false
                }, {
                    targets: [0], //assetThumbnail
                    className: 'text-center',
                    width: 60
                }, {
                    targets: [1] //assetName
                }, {
                    targets: [2], //contentType
                    width: '12%'
                }, {
                    targets: [3], //createdDate
                    width: '10%'
                }, {
                    targets: [4], //deliveryType
                    width: '10%',
                }, {
                    targets: [5], //active
                    width: 120
                }, {
                    targets: [7], //catalogs
                    width: '10%'
                }];
                className = AssetConstants.ASSET_TYPES.DOCUMENT.icon;
                break;
            }
        }
        return (
            <DocumentTitle
                message={`document-titles.assets.browse.${assetType}`}
            >
                <div className={ClassNames({'control-sidebar-open': this.state.showFiltersPanel})}>
                    <div className={ClassNames({'sidebar-opened': this.state.showFiltersPanel})}>
                        <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                            <section className="content-header">
                                <h1>
                                    <i className={className}></i>&nbsp;{this.context.intl.messages[`assets.browse.asset-type.${assetType}.title`]}
                                    &nbsp;<small>{this.state.total}</small>
                                </h1>
                            </section>
                            <section className="content">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-6 show-grid">
                                                    <SearchBox onChange={this.handleSearchTerm} value={this.getRouteState().get('asset-name')}/>
                                                </div>
                                                <div className="col-md-6 show-grid">
                                                    <Button onClick={this.handleToggleFiltersPanel} bsStyle="primary" bsSize="large" className="pull-right">
                                                        <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid no-x-padding">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="box">
                                                        <div className="box-body">
                                                            <ListAssets
                                                                assets={this.state.assets}
                                                                assetType={this.state.assetType}
                                                                activePage={Math.ceil((this.getRouteState().get('offset') || 0) / 20 ) || 0}
                                                                columns={columns}
                                                                columnDefs={columnDefs}
                                                                displayAudioLinks={this.props.permissions.canReadAudioAssets}
                                                                displayDocumentLinks={this.props.permissions.canReadDocumentAssets}
                                                                displayImageLinks={this.props.permissions.canReadImageAssets}
                                                                onSortChange={this.handleSortChange}
                                                                sortDirection={this.getRouteState().get('sort-direction') || 'desc'}
                                                                sortFieldName={this.getRouteState().get('sort-field-name') || 'updatedDate'}
                                                                displayMerchandiseLinks={this.props.permissions.canReadMerchandiseAssets}
                                                                displayProxyLinks={this.props.permissions.canReadProxyAssets}
                                                                displayScriptLinks={this.props.permissions.canReadScriptAssets}
                                                                displayVideoLinks={this.props.permissions.canReadVideoAssets}
                                                                displayVideoTimelineLinks={this.props.permissions.canReadVideoTimelineAssets}
                                                                onPageChange={this.handleLoadPage}
                                                                totalPages={Math.ceil(this.state.total / 20) || 0}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <FilterOptions
                                assetType={this.state.assetType}
                                location={this.props.location}
                                showAssetType={showAssetType}
                                showDeliveryType={showDeliveryType}
                            />
                        </Preloader>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithStoreOnRoute(
    Container.create(Browse)
);

export {
    Browse as Browse_BASE
};
