/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactTable from 'react-table';

import {AssetCatalogActions} from './asset-catalog-actions';

import 'react-table/react-table.css';

class UnassignedTable extends Component {
    static get propTypes() {
        return {
            assignedCatalogs: PropTypes.instanceOf(Immutable.List).isRequired,
            catalogs: PropTypes.instanceOf(Immutable.List).isRequired,
            filters: PropTypes.string.isRequired,
            showEventCatalogs: PropTypes.bool
        };
    }

    static get defaultProps() {
        return {
            showEventCatalogs: false
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pageSize: 10
        };
        this.handleAddCatalog = this.handleAddCatalog.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.filters !== this.props.filters) {
            // Reset page to 0 when filters change to prevent no results bug while searching on second+ page
            this.setState(() => ({page: 0}));
        }
    }

    handleAddCatalog(event) {
        let catalog, id;
        switch (true) {
        case !!~event.target.className.indexOf('add-catalog'):
            id = parseInt(event.target.getAttribute('data-catalog-id'), 10);
            catalog = this.props.catalogs.find(c => c.get('id') === id);
            AssetCatalogActions.add(catalog);
            break;
        case !!~event.target.className.indexOf('fa-plus'):
            id = parseInt(event.target.parentNode.getAttribute('data-catalog-id'), 10);
            catalog = this.props.catalogs.find(c => c.get('id') === id);
            AssetCatalogActions.add(catalog);
            break;
        }
        return;
    }

    handlePageChange(page) {
        this.setState(() => ({page: page}));
    }

    handlePageSizeChange(pageSize) {
        this.setState(() => ({page: 0, pageSize: pageSize}));
    }

    render() {
        const filterRegExp = new RegExp(this.props.filters, 'i');
        const unassignedCatalogs = this.props.catalogs.filter(/*istanbul ignore next*/c => {
            let keep = !this.props.assignedCatalogs.find(assignedCatalog => c.get('id') === assignedCatalog.get('id'));

            if (keep && this.props.filters) {
                keep = c.get('name').match(filterRegExp);
            }

            if (keep) {
                if (this.props.showEventCatalogs) {
                    keep = c.get('name').toLowerCase().startsWith('event:');
                } else {
                    keep = !c.get('name').toLowerCase().startsWith('event:');
                }
            }

            return keep;
        });

        /*istanbul ignore next*/
        const columns = [{
            Header: <strong className="sorting_desc">{this.context.intl.messages['common.name-and-description']}</strong>,
            Cell: c => {
                const catalogDescription = c.original.get('description') || '';
                const catalogName = c.original.get('name');
                const style = {fontSize: '80%'};
                return (
                    <div>
                        {catalogName}
                        <small className="d-block" style={style}>
                            {this.context.intl.messages['common.description']}: {catalogDescription}
                        </small>
                    </div>
                );
            }
        }, {
            Header: <strong>{this.context.intl.messages['asset.edit.catalogs-tab.type']}</strong>,
            maxWidth: 95,
            Cell: c => {
                let displayAssetType = c.original.get('displayAssetType');
                return <div>{displayAssetType}</div>;
            }
        }, {
            Header: <strong>{this.context.intl.messages['asset.edit.catalogs-tab.status']}</strong>,
            maxWidth: 75,
            Cell: c => {
                let catalogStatus = 'Inactive';
                if (c.original.get('isActive') === 1) {catalogStatus = 'Active';}
                return (<div>{catalogStatus}</div>);
            }
        }, {
            Header: <strong>{this.context.intl.messages['asset.edit.catalogs-tab.actions']}</strong>,
            maxWidth: 75,
            Cell: c => {
                return (
                    <button className="btn btn-small bg-wb-blue add-catalog" data-catalog-id={c.original.get('id')} onClick={this.handleAddCatalog}>
                        <i className="fas fa-plus"></i>&nbsp;{this.context.intl.messages['common.add']}
                    </button>
                );
            }
        }];

        const pages = Math.ceil(unassignedCatalogs.size/this.state.pageSize);
        let page = this.state.page;
        if (page + 1 > pages) {
            // If the current page is greater than the number of pages, reset to the actual last page
            // Note: there is a visual bug in ReactTable where the page number text field will not be updated. The prev/next page buttons must be used to update the shown value.
            page = pages - 1;
        }
        return (
            <ReactTable
                className="-striped table table-bordered table-striped responsive"
                columns={columns}
                data={unassignedCatalogs}
                getNoDataProps= {() => {
                    if (unassignedCatalogs.size) {
                        return {style: {display: 'none'}};
                    }
                    return {};
                }}
                id="unassigned-catalogs-table"
                loading={false}
                page={page}
                pageSize={this.state.pageSize}
                onPageChange={this.handlePageChange}
                onPageSizeChange={this.handlePageSizeChange}
                pages={pages}
                showPagination={pages > 1}
                sortable={false}
                resizable={false}
            />
        );
    }
}

export default UnassignedTable;
